﻿module VideoCallGallery

open System
open Feliz.prop
open Fetch.Types
open Shared.Case
open Shared.Case.Queries
open Shared.Errors
open Shared.VideoCall
open FsToolkit.ErrorHandling
open Extensions
open Feliz.UseDeferred

let t = Localization.ns("caseDetail")

type HiddenModeProps={
    HiddenMode: unit -> unit
    CaseId: Guid
}

module GalleryShow =
    open Elmish

    type ModalInfo = {
        media: Media list
        current: int
        visible: bool
        onClose: unit -> unit
    }
    type DeletingModal = {
        File: Media option
        Confirm: bool
    }
    type State = {
        CaseId: Guid
        LoadedCases : Deferred<Result<Queries.Case list, string>>
        CaseFiles: Deferred<Result<Media list, string>>
        ModalInfo: ModalInfo option
        EditImage: MediaFile option
        DeletingModal: DeletingModal
    }

    type Msg =
        | Close
        | LoadCaseFiles of AsyncOperationStatus<Result<Media list, string>>
        | ToggleModal of ModalInfo option
        | ToggleEditImage of MediaFile option
        | ToggleDeletingState of DeletingModal
        | GoToMaps of Coordinates
        | MediaFileDeleted of Media

    module Cmd =

        let created = function
            | Video x-> x.Created
            | VideoProcessing x -> x.Created
            | Image x -> x.Created

        let loadCaseFiles id =
            async {
                try
                    let! files = Communication.caseApi().files id  |> Remoting.handleNonAuth
                    let files = files |> Result.mapError Components.Common.TranslatedErrors.ServerError.explainTranslation |> Result.map (List.sortBy created)
                    return LoadCaseFiles (Finished files)
                with
                    ex -> return LoadCaseFiles (Finished (Error ex.Message))
            }

        let notifyDeviceIdChanged (deviceId: string) =
            Events.AddressBook.notifyDeviceIdSelected deviceId

    module State =
        let init(caseId: Guid) =
            {
                CaseId = caseId
                CaseFiles = Deferred.HasNotStartedYet
                LoadedCases = Deferred.HasNotStartedYet
                ModalInfo = None
                EditImage = None
                DeletingModal = { File = None; Confirm = false }
            }, Cmd.ofMsg (LoadCaseFiles Started)

        let update (hiddenMode: unit -> unit)(msg: Msg) (state: State) =
            match msg with
            | Close ->
                hiddenMode()
                state, Cmd.none
            | LoadCaseFiles Started ->
                { state with CaseFiles = Deferred.InProgress}, Cmd.fromAsync (Cmd.loadCaseFiles state.CaseId)
            | LoadCaseFiles (Finished groups) -> {state with CaseFiles = Deferred.Resolved groups}, Cmd.none
            | MediaFileDeleted mediaFile ->
                match state.CaseFiles with
                | Deferred.Resolved (Ok files) ->
                    let newState = files |> List.filter(fun x -> x <> mediaFile)
                    {state with CaseFiles = Deferred.Resolved (Ok newState)}, Cmd.none
                | _ -> state, Cmd.none

            | ToggleModal info ->
                {state with ModalInfo = info }, Cmd.none
            | ToggleEditImage info ->
                {state with EditImage = info }, Cmd.none
            | ToggleDeletingState info ->
                {state with DeletingModal = info }, Cmd.none
            | GoToMaps coordinates ->
                let openGoogleMaps coordinates =
                    Browser.Dom.document.``open``(Components.Common.toGoogleMaps coordinates.Latitude coordinates.Longitude,"", "", true)
                state, Cmd.ofSub (fun _ -> openGoogleMaps coordinates |> ignore)


    module View =
        open Feliz
        open Feliz.ElmishComponents
        open Feliz.Bulma
        open Feliz.Bulma.QuickView
        open State

        open Fable.Core

        let renderMediaFiles = React.functionComponent(
            fun (props: {|
                    modalInfo: ModalInfo option
                    dispatch: Msg -> unit
                    media: Media list
                    loadCaseFiles: unit -> unit
                    mediaFileDeleted: Media -> unit
                    goToMaps: Coordinates -> unit |}) ->

            Bulma.content [
                Bulma.columns [
                    columns.isMultiline
                    prop.children [
                        for idx, file in props.media |> List.indexed do
                            Bulma.column [
                                column.isNarrow
                                prop.classes [BulmaCss.``is-paddingless``]
                                prop.children [
                                    CaseDetail.renderMediaItem idx file
                                        props.goToMaps
                                        {| showGallery =
                                                props.modalInfo
                                                |> Option.map(fun x -> {|visible = x.visible; position = x.current|})
                                                |> Option.defaultValue({|visible = false; position = 0|})
                                           setShowGallery = (fun x ->
                                               let info = {
                                                   media = props.media
                                                   current = x.position
                                                   visible = x.visible
                                                   onClose = fun () -> props.dispatch (ToggleModal None)
                                               }
                                               props.dispatch (ToggleModal (Some info)))
                                           setEditImage = fun x -> props.dispatch (ToggleEditImage x)
                                           setDeletingState = fun x -> props.dispatch (ToggleDeletingState { File = x.File; Confirm = x.Confirm })
                                           isVideoCall = true |}
                                ]
                            ]
                    ]
                ]

            ]
        )

        let renderMediaFilesWithUpdation = React.functionComponent (
            fun (caseId: Guid,
                 props: {|
                    modalInfo: ModalInfo option
                    dispatch: Msg -> unit
                    media: Media list
                    loadCaseFiles: unit -> unit
                    mediaFileDeleted: Media -> unit
                    goToMaps: Coordinates -> unit |}) ->
            let files, setFiles = React.useState(props.media)
            let getFiles() =
                let rec loop () = async {
                    do! Async.Sleep(5000)
                    // call server
                    let! response = Communication.caseApi().getUpdationInFiles { CaseId = caseId; Files = files |> List.map(fun x -> x.Id) }  |> Remoting.handleNonAuth

                    match response with
                    | Ok res ->
                        if res.NoChanges then
                            return! loop()
                        else
                            let updatedFiles =
                                res.Added
                                |> List.append(files)
                                |> List.filter(fun x -> res.Deleted |> List.contains(x.Id) |> not)
                            setFiles(updatedFiles)
                            return! loop ()
                    | Error _ ->
                        return! loop ()
                }
                loop ()

            React.useEffectOnce(getFiles >> Async.StartImmediate)

            renderMediaFiles {|
                props with media = files
            |}
        )
        let renderForm (state: State) (dispatch: Msg -> unit) =
            Bulma.section [
                prop.style [style.padding 0]
                prop.children [
                    match state.CaseFiles with
                    | Deferred.HasNotStartedYet
                    | Deferred.InProgress -> Components.Loader.fullScreenMedium (t "case.loadingFiles")
                    | Deferred.Resolved (Ok files) ->
                        renderMediaFilesWithUpdation (state.CaseId, {|
                            modalInfo = state.ModalInfo
                            dispatch = dispatch
                            media = files
                            loadCaseFiles = fun _ -> dispatch (LoadCaseFiles Started)
                            mediaFileDeleted = fun media -> dispatch (MediaFileDeleted media)
                            goToMaps = fun coordinates -> dispatch (GoToMaps coordinates)
                        |})
                    | Deferred.Failed ex ->
                        Bulma.notification [
                            color.isDanger
                            prop.text ex.Message
                        ]
                    | Deferred.Resolved (Error e) ->
                        Bulma.notification [
                            color.isDanger
                            prop.text e
                        ]
                ]
            ]

        let renderToolbarForDemo dispatch =
            Bulma.section [
                prop.style [style.paddingBottom 0; style.paddingTop 32]
                prop.children [
                    Bulma.container [
                        Bulma.level [
                            Bulma.levelLeft [
                                Bulma.levelItem [
                                    prop.classes [ AppCss.CloseQuickview ]
                                    prop.style [style.zIndex 40]
                                    prop.children [
                                        Bulma.button.button [
                                            color.isPrimary
                                            prop.style [
                                                style.position.fixedRelativeToWindow
                                                style.bottom 80
                                                style.left -20
                                            ]
                                            prop.className "button-shadow"
                                            prop.onClick( fun x-> x.preventDefault(); dispatch Close)
                                            prop.children [
                                                Bulma.icon [
                                                    Html.i [
                                                        prop.classes [MdiCss.Mdi; MdiCss.Mdi24Px; MdiCss.MdiArrowRight]
                                                    ]
                                                ]
                                            ]
                                        ]
                                    ]
                                ]
                            ]
                            Bulma.levelRight []
                        ]
                    ]
                ]
            ]

        let render (state: State) (dispatch: Msg -> unit) =
            React.fragment [
                match state.ModalInfo, state.EditImage, state.DeletingModal with
                | Some _, None, _ ->
                    CaseDetail.gallery
                        {|
                           media = state.ModalInfo.Value.media
                           current = state.ModalInfo.Value.current
                           visible = state.ModalInfo.Value.visible
                           onClose = state.ModalInfo.Value.onClose
                        |}
                | None, Some (file: MediaFile), _ ->
                    let saveEditedImage (x: Browser.Types.Blob) =
                        let uploadFileContent url (x: Browser.Types.Blob) =
                            promise {
                                let! response = Fetch.fetch url [
                                    RequestProperties.Method HttpMethod.PUT
                                    RequestProperties.Body (BodyInit.Case1 x)
                                ]
                                if response.Ok then return Ok ()
                                else return Error "Failed to upload file to AWS S3"
                            }

                        asyncResult {
                            let! content = Blob.readAsByteArray x
                            let! url =
                                Communication.caseApi().getUploadFileUrl()
                                |> AsyncResult.mapError ServerError.explain

                            do! uploadFileContent url.UploadUrl x |> Async.AwaitPromise

                            let args: ImageEditedArgs = {
                                 OriginalFileId = file.Id
                                 FileId = url.FileId
                                 File = content
                            }
                            do! args
                                |> Communication.caseApi().saveEditedImage
                                |> AsyncResult.mapError ServerError.explain

                            dispatch (ToggleEditImage None)
                            dispatch (LoadCaseFiles Started)
                        }
                        |> AsyncResult.mapError
                               (fun x ->
                                    Browser.Dom.console.error("Failed to save edited image", x)
                                    (t "edit.image.failed.save"))

                    Components.ImageEditor.imageEditor
                         {|
                            onSave = saveEditedImage
                            onCancel = (fun () -> dispatch (ToggleEditImage None))
                            imageUrl = file.DownloadUrl
                         |}
                | None, None, info when info.Confirm ->
                    let deleteMediaFile (media: Media option) =
                        async {
                            match media with
                            | Some media ->
                                let mediaFile =
                                    match media with
                                    | Image file -> file
                                    | Video file -> file
                                    | VideoProcessing file -> file
                                let! _ = Communication.caseApi().deleteFile mediaFile
                                dispatch(ToggleDeletingState { File = info.File; Confirm = false })
                                dispatch (MediaFileDeleted media)
                                return ()
                            | None -> failwith "Unexpected value of deleting media: None"
                        }
                    Components.Common.renderConfirmationWindow
                        {| title = (t "delete.media.confirmation.question")
                           confirmedButton = (t "delete.media.confirmation.button.label")
                           confirmed = (fun () -> deleteMediaFile info.File)
                           notConfirmed = (fun _ -> dispatch(ToggleDeletingState { File = info.File; Confirm = false })) |}

                | None, None, _
                | Some _, Some _, _ ->
                    Html.div [
                        prop.children [
                            Html.div [
                                prop.className "Dark-screen"
                            ]
                            QuickView.quickview [
                                quickview.isActive
                                prop.style [
                                    style.width (length.percent 14.3)
                                    style.zIndex 50
                                ]
                                prop.children [
                                    QuickView.body [
                                        prop.classes [AppCss.Scrollbar]
                                        prop.children [
                                            renderToolbarForDemo dispatch
                                            QuickView.block [ renderForm state dispatch ]
                                        ]
                                    ]
                                ]
                            ]
                        ]
                    ]

        ]

        let renderGallery (props: HiddenModeProps) =
            React.elmishComponent("VideoCallGallery", init(props.CaseId), update props.HiddenMode, render)