module Users

open System
open Fable.DateFunctions

let t = Localization.ns("users")

module Domain =
    type Location = string

    type CheckPoint =
        | Start of Location * DateTimeOffset
        | ArrivedOnPlace of Location * DateTimeOffset
        | LeftClientPlace of Location * DateTimeOffset
        | End of Location * DateTimeOffset

    type UserStatus =
        | NotWorking of CheckPoint
        | Working of CheckPoint

    type Feedback = {
        Rate: float
        NumberOfEvaluations: int
    }

    type User = {
        Id: string
        FullName: string
        Title: string
        Email: string
        Status: UserStatus
        AverageFeedback: Feedback
        Created: DateTimeOffset
    }

module Types =
    open Extensions

    type State = {
        Users: Deferred<Result<Shared.Users.Queries.User list, string>>
        ShowCreateInspectorDialog: bool
    }

    type Msg =
        | LoadUsers of AsyncOperationStatus<Result<Shared.Users.Queries.User list, string>>
        | GoToUserDetails of int

module State =
    open Types
    open Elmish
    open Communication

    let loadUsers (): Async<Msg> = async {
        try
            let! users = usersApi().users () |> Remoting.handleNonAuth
            return LoadUsers (Finished (Ok users))
        with
            ex -> return LoadUsers (Finished (Error ex.Message))
    }

    let init () =
        { Users = HasNotStartedYet; ShowCreateInspectorDialog = false }, Cmd.ofMsg (LoadUsers Started)

    let update (state: State) (msg: Msg) =
        match msg with
        | LoadUsers Started ->
            { state with Users = InProgress }, Cmd.fromAsync (loadUsers ())
        | LoadUsers (Finished users) ->
            { state with Users = (Resolved users) }, Cmd.none
        | GoToUserDetails id ->
            state, Router.navigateTo (Router.Route.UserDetails id)

module View =
    open Domain
    open Types
    open Feliz
    open Feliz.Bulma

    let renderToolbar (state: State) (dispatch: Msg -> unit) =
        Bulma.level [
            Bulma.levelLeft [
                Bulma.levelItem [
                    Bulma.field.div [
                        Bulma.control.p [
                            Bulma.control.hasIconsRight
                            prop.children [
                                Bulma.input.search [
                                    prop.placeholder (t "users.placeholder")
                                    prop.autoFocus true
                                ]
                                Bulma.icon [
                                    Bulma.icon.isRight
                                    prop.children [
                                        Html.i [
                                            prop.classes [  MdiCss.Mdi; MdiCss.Mdi24Px; MdiCss.MdiMagnify ]
                                        ]
                                    ]
                                ]
                            ]
                        ]
                    ]
                ]
            ]
            Bulma.levelRight [
                Bulma.levelItem [
                    Bulma.button.a [
                        Bulma.color.isPrimary
                        prop.className "button-shadow"
                        prop.href (Router.toPath Router.RegisterUser)
                        prop.text (t "button.addInspector")
                    ]
                ]
            ]
        ]

    let renderStatus (status: UserStatus) =
        match status with
        | NotWorking _ ->
            Bulma.text.span [
                prop.style[style.color "#D64D22" ]
                prop.text (t "status.offline")
            ]
        | Working _ ->
            Bulma.text.span [
                prop.style[style.color "#22D65F"]
                prop.text (t "status.online")
            ]

    let renderUsers (users: Shared.Users.Queries.User list) dispatch =
        let random = Random()
        Html.div[
            Bulma.columns [
                prop.classes [AppCss.Headers]
                prop.children[
                    Bulma.column (t "title.inspector")
                    Bulma.column (t "title.role")
                    // Bulma.column[
                    //     prop.style [ style.textAlign.center ]
                    //     prop.text (t "title.status")
                    // ]
                    Bulma.column[
                        prop.style [ style.textAlign.right ]
                        prop.text (t "title.created")
                    ]
                ]
            ]
            for user in users do
            Bulma.box[
                prop.classes [AppCss.BoxStyle]
                prop.children[
                    Bulma.columns[
                        prop.key user.Id
                        prop.onClick (fun x -> x.preventDefault(); dispatch (GoToUserDetails user.Id))
                        prop.children [
                            Bulma.column[
                                prop.key (sprintf "user-name-%d" user.Id)
                                prop.text (sprintf "%s" user.FullName)
                            ]
                            Bulma.column[
                                prop.style[
                                    style.opacity 0.5
                                ]
                                prop.key (sprintf "user-title-%d" user.Id)
                                prop.text (sprintf "%s" user.Title)
                            ]
                            // Bulma.column[
                            //     prop.style[
                            //         style.opacity 0.5
                            //         style.textAlign.center
                            //     ]
                            //     prop.key (sprintf "status-%d" user.Id)
                            //     prop.text user.Status
                            // ]
                            Bulma.column[
                                prop.style [
                                    style.opacity 0.5
                                    style.textAlign.right
                                ]
                                prop.key (sprintf "date-%d" user.Id)
                                prop.text (user.Created.ToLocalTime().DateTime.Format("MMM dd yyyy, HH:mm"))
                            ]
                        ]
                    ]
                ]
            ]
        ]

    let render (state: State) (dispatch: Msg -> unit) =
        match state.Users with
        | HasNotStartedYet
        | InProgress ->
            Components.Loader.fullScreenMedium (t "users.loading")
        | Resolved (Error error) ->
            Bulma.notification [
                Bulma.color.isDanger
                prop.text error
            ]
        | Resolved (Ok users) ->
            Html.div [
                Html.div[
                    prop.classes [ AppCss.Toolbar ]
                    prop.children [
                        renderToolbar state dispatch
                    ]
                ]
                renderUsers users dispatch
            ]
