module SignIn

open Shared.Auth
open Elmish
open Feliz
open Feliz.Bulma
open Shared.Errors

let t = Localization.ns("signin")

module Types =
    type State = {
        Login: string
        Password: string
        LoginResult: Deferred<Result<SignedInUser, string>>
    }

    type Msg =
        | LoginChanged of string
        | PasswordChanged of string
        | Login of AsyncOperationStatus<Result<SignedInUser, string>>
        | UserSaved

module Commands =
    open Types
    open Communication

    let login (state: State) = async {
        try
            let loginInfo : Shared.Auth.LoginInfo = { Login = state.Login; Password = state.Password }
            let! result = authApi().signIn loginInfo
            return Login (Finished (result |> Result.mapError ServerError.explain))
        with ex ->
            return Login (Finished (Error ex.Message))
    }

    let saveUser (user: Shared.Auth.SignedInUser) = LocalStorage.saveUser user
module State =
    open Types
    open Extensions.View

    let init () : State * Cmd<Msg> =
        { Login = ""; Password = ""; LoginResult = HasNotStartedYet }, Cmd.none

    let update signedIn (msg: Msg) (state: State)  =
        match msg with
        | LoginChanged x -> { state with Login = x }, Cmd.none
        | PasswordChanged x -> { state with Password = x }, Cmd.none
        | Login Started -> { state with LoginResult = InProgress }, Cmd.fromAsync (Commands.login state)
        | Login (Finished (Ok user)) ->
            { state with LoginResult = Resolved (Ok user) },
            Cmd.batch [
                Cmd.OfFunc.perform Commands.saveUser user (fun _ -> UserSaved)
                Cmd.ofSub (fun _ -> signedIn user)
            ]
        | Login (Finished (Error e)) ->
            { state with LoginResult = Resolved (Error e) }, Cmd.toastServerError (t "toast.title") e
        | UserSaved -> state, Cmd.none

module View =
    open Types
    open Fable.Core.JsInterop

    let logo: string = importAll "./public/img/logo.svg"

    let private column (model : State) (dispatch : Msg -> unit) =
        Bulma.column [
            prop.classes [BulmaCss.``is-4-desktop``; BulmaCss.``is-6-tablet``]
            prop.style [ style.margin.auto ]
            prop.children [
                Bulma.field.div [
                    Bulma.image [
                        prop.style [
                            style.width 250
                            style.margin.auto
                            style.fontSize 12
                        ]
                        prop.children [
                            Html.img [ prop.src logo ]
                            Html.p "Skadebesiktningshjälpen"
                            Html.p "Smarta besiktningar i mobilen"
                        ]
                    ]
                ]

                Html.form [
                    Bulma.field.div [
                        Bulma.control.div [
                            Bulma.input.email [
                                prop.placeholder (t "email.placeholder")
                                // Added according to recommendations
                                // https://www.chromium.org/developers/design-documents/create-amazing-password-forms
                                // https://www.chromium.org/developers/design-documents/form-styles-that-chromium-understands
                                prop.autoComplete "username"
                                prop.autoFocus true
                                prop.valueOrDefault model.Login
                                prop.onChange (LoginChanged >> dispatch)
                                prop.style[
                                    style.marginTop 40
                                ]
                            ]
                        ]
                    ]
                    Bulma.field.div [
                        Bulma.control.div [
                            Bulma.input.password [
                                prop.placeholder (t "password.placeholder")
                                // Added according to recommendations
                                // https://www.chromium.org/developers/design-documents/create-amazing-password-forms
                                // https://www.chromium.org/developers/design-documents/form-styles-that-chromium-understands
                                prop.autoComplete "current-password"
                                prop.onChange (PasswordChanged >> dispatch)
                            ]
                        ]
                    ]
                    Html.a[
                        prop.style[
                            style.fontSize 16
                            style.fontWeight 400
                            style.color "#3572BE"
                            style.display.flex
                            style.justifyContent.center
                        ]
                        prop.text (t "title.forgotpassword")
                        prop.href (Router.toPath Router.Route.ForgotPassword)
                    ]
                    Bulma.field.div [
                        Bulma.control.div [
                            Bulma.button.button [
                                Bulma.button.isFullWidth
                                color.isBlack
                                prop.text (t "button.login")
                                prop.style[
                                    style.marginTop 40;
                                ]
                                prop.onClick (fun e -> e.preventDefault(); dispatch (Login Started))
                            ]
                        ]
                    ]
                ]
            ]
        ]

    let view (state: State) (dispatch: Msg -> unit) =
        Bulma.hero [
            Bulma.hero.isFullHeight
            prop.classes [ AppCss.LoginScreen ]
            prop.children [
                Bulma.heroBody [
                    Bulma.container [
                        column state dispatch
                    ]
                ]
            ]
        ]

module Component =
    open State
    open View
    open Feliz.ElmishComponents

    type LoginProps = { signedIn: SignedInUser -> unit }

    let login (props: LoginProps) =
        React.elmishComponent("Login", init(), update props.signedIn, view)