module Feedback
open Elmish
let t = Localization.ns ("feedback")

type FeedbackWindowState=
    | Hidden
    | Shown

type State={
    FeedbackWindow: FeedbackWindowState
}

type Msg=
    | ShowFeedbackWindow
    | HiddenFeedbackWindow

module State =

    let init () =
        {FeedbackWindow = Hidden}, Cmd.none

    let update (msg: Msg) (state: State): State * Cmd<Msg>=
        match msg with
        | ShowFeedbackWindow -> {state with FeedbackWindow =Shown}, Cmd.none
        | HiddenFeedbackWindow -> {state with FeedbackWindow = Hidden}, Cmd.none

module View =
    open Feliz
    open Feliz.ElmishComponents
    open Feliz.Bulma
    open State

    let showFeedback ()=
        Bulma.card[
            prop.classes [AppCss.ButtonShadow]
            prop.style[
                style.right 330
                style.top 10
                (*style.bottom 30*)
                style.position.relative
                style.zIndex 35
                style.borderRadius 10
            ]
            prop.children[
                Bulma.cardContent[
                    Html.p(t "text")
                    Html.p("support@skadebesiktningshjalpen.se")
                    //Html.p("Fredrik Ramström | 073 - 511 81 76")

                ]
            ]
        ]

    let renderFeedbackButton (state:State) (dispatch: Msg -> unit) =
        Bulma.button.a[
            prop.className "button-shadow"
            prop.style [style.zIndex 35]
            color.isBlack
            prop.children[
                Bulma.icon[
                    Html.i[
                        if state.FeedbackWindow = Shown then
                            prop.classes[MdiCss.Mdi; MdiCss.Mdi24Px; MdiCss.MdiClose]
                        else
                            prop.classes[MdiCss.Mdi; MdiCss.Mdi18Px; MdiCss.MdiCommentQuestionOutline]
                    ]
                ]
            ]
            if state.FeedbackWindow = Shown then prop.onClick (fun x-> x.preventDefault(); dispatch HiddenFeedbackWindow)
            else prop.onClick (fun x-> x.preventDefault(); dispatch ShowFeedbackWindow)
        ]

    let renderFeedbackWindow (state: State) (dispatch: Msg -> unit) =
        match state.FeedbackWindow with
        | Hidden -> Html.none
        | Shown ->
                Html.div [
                    prop.style[style.position.inheritFromParent]
                    prop.children [
                        showFeedback ()
                    ]
                ]
    let render (state: State) (dispatch: Msg -> unit)=
        React.fragment [
            renderFeedbackButton state dispatch
            renderFeedbackWindow state dispatch
        ]

    let showFeedbackButton() =
        React.elmishComponent("ShowFeedbackButton", init(), update, render)