module CaseDetail

open System
open Components
open Fable.Core
open Fetch
open Router
open Shared.Case
open Shared.Case.Queries
open Extensions.View
open Shared.VideoCall
open Shared.Errors
open Feliz

let t = Localization.ns("caseDetail")

type VideoCall = string

type Tab = Information | Gallery | HistoryAndComments

type EditComment = {
    Text: string
    SaveCommentResult: Deferred<Result<unit, string>>
}

type State = {
    Case : Deferred<Result<CaseDetail, string>>
    CaseFiles : Deferred<Result<Media list, string>>
    ActiveTab: Tab
    EditComment: EditComment
    DeleteResult: ConfirmedOperation<Result<unit, string>>
    SelectedStatus: Deferred<CaseStatus>
    CreatePdfFileInProgress: bool
    CreateMediaZipFileInProgress: bool
}
type DownloadMediaFilesErrors =
    | NoMedia
    | ServerError of ServerError
    | Exception of exn

type Msg =
    | AddComment of AsyncOperationStatus<Result<unit, string>>
    | OnCommentChange of string
    | LoadCase of Guid * AsyncOperationStatus<Result<CaseDetail, string>>
    | LoadCaseFiles of AsyncOperationStatus<Result<Media list, string>>
    | GoToCases
    | DeleteCase of AsyncConfirmOperationStatus<Result<unit, string>>
    | ChangeStatus of CaseStatus * AsyncOperationStatus<Result<unit, string>>
    | SwitchTabTo of Tab
    | GoToMaps of Coordinates
    | CreatePdfFile of AsyncOperationStatus<Result<unit, string>>
    | CreateMediaZipFile of AsyncOperationStatus<Result<unit, DownloadMediaFilesErrors>>
    | MediaFileDeleted of Media

open Elmish
module Cmd =
    open Communication
    open Fable.Remoting.Client

    let loadCase id : Cmd<Msg> =
        async {
            try
                let! case = caseApi().details id |> Remoting.handleNonAuth
                let case = case |> Result.mapError Common.TranslatedErrors.ServerError.explainTranslation
                return LoadCase (id, Finished case)
            with
                ex -> return LoadCase (id, Finished (Error ex.Message))
        }
        |> Cmd.fromAsync

    let created = function
        | Video x-> x.Created
        | VideoProcessing x -> x.Created
        | Image x -> x.Created

    let loadCaseFiles id : Cmd<Msg> =
        async {
            try
                let! files = caseApi().files id  |> Remoting.handleNonAuth
                let files = files |> Result.mapError Common.TranslatedErrors.ServerError.explainTranslation |> Result.map (List.sortBy created)
                return LoadCaseFiles (Finished files)
            with
                ex -> return LoadCaseFiles (Finished (Error ex.Message))
        }
        |> Cmd.fromAsync

    let saveComment caseId comment : Cmd<Msg> =
        async {
            try
                let! result = caseApi().saveComment { CaseId = caseId; Comment = comment }  |> Remoting.handleNonAuth
                return
                    match result with
                    | Ok _ -> AddComment (Finished (Ok ()))
                    | Error e -> AddComment (Finished (Error (Common.TranslatedErrors.ServerError.explainTranslation e)))
            with
                ex -> return AddComment (Finished (Error ex.Message))
        }
        |> Cmd.fromAsync

    let delete (case: CaseDetail) : Cmd<Msg> =
        async {
            try
                let! result = caseApi().delete case.Id |> Remoting.handleNonAuth
                let result = result |> Result.mapError Common.TranslatedErrors.ServerError.explainTranslation
                return DeleteCase (ConfirmationFinished result)
            with
                ex -> return DeleteCase (ConfirmationFinished (Error ex.Message))
        }
        |> Cmd.fromAsync

    let changeStatus status (case: CaseDetail) : Cmd<Msg> =
        async {
            try
                let! result = caseApi().changeStatus { CaseId = case.Id; Status = status } |> Remoting.handleNonAuth
                let result = result |> Result.mapError Common.TranslatedErrors.ServerError.explainTranslation
                return ChangeStatus (status, Finished result)
            with
                ex -> return ChangeStatus (status, Finished (Error ex.Message))
        }
        |> Cmd.fromAsync
    let createPdfFile (case: CaseDetail) =
        async {
            try
                let! result = caseApi().downloadPdfReport case.Id
                result.SaveFileAs(sprintf "SBH_%s.pdf" case.Number, "application/pdf")
                return CreatePdfFile (Finished (Ok ()))
            with
               _ex -> return CreatePdfFile (Finished (Error "Pdf File can not be created, some errors occured"))
        }
        |> Cmd.fromAsync
    let createMediaZipFile (case: CaseDetail) =
        async {
            try
                let! file = caseApi().downloadMedia case.Id
                match file with
                | Ok file ->
                    file.SaveFileAs(sprintf "SBH_Media_%A.zip" case.Number, "application/zip")
                    return CreateMediaZipFile (Finished (Ok ()))
                | Error e when e = (Shared.Errors.ServerError.DatabaseItemNotFound (case.Id.ToString())) ->
                    return CreateMediaZipFile (Finished (Error DownloadMediaFilesErrors.NoMedia))
                | Error e -> return CreateMediaZipFile (Finished (Error (DownloadMediaFilesErrors.ServerError e)))
            with
                ex -> return CreateMediaZipFile (Finished (Error (DownloadMediaFilesErrors.Exception ex)))
        }
        |> Cmd.fromAsync

let init id =
    {
        Case = HasNotStartedYet
        CaseFiles = HasNotStartedYet
        ActiveTab = Information
        EditComment = {
            Text = String.Empty
            SaveCommentResult = HasNotStartedYet
        }
        DeleteResult = NotStarted
        SelectedStatus = HasNotStartedYet
        CreatePdfFileInProgress = false
        CreateMediaZipFileInProgress = false
    }, Cmd.ofMsg (LoadCase (id, Started))

let withNewComment comment (case: CaseDetail)  =
    let comment = Comment { Text = comment; Timestamp = DateTimeOffset.Now }
    { case with Activities = case.Activities @ [ comment] }

let withCase (f: CaseDetail -> CaseDetail) (state: State) =
    { state with Case = state.Case |> DeferredResult.map f }
let withComment comment (state: State) =
    { state with EditComment = { state.EditComment with Text = comment  }  }
let withSaveCommentResult r (state: State) =
    { state with EditComment = { state.EditComment with SaveCommentResult = r  }}

let update (goToCases: unit -> unit) (msg: Msg) (state: State) =
    match msg, state.Case with
    | LoadCase (id, Started), HasNotStartedYet ->
        { state with Case = InProgress }, Cmd.loadCase id
    | LoadCase (_, Finished (Ok case)), InProgress ->
        { state with Case = Resolved (Ok case); SelectedStatus = Resolved case.Status }, Cmd.none
    | LoadCase (_, Finished (Error e)), InProgress ->
        { state with Case = Resolved (Error e) }, Cmd.none
    | LoadCaseFiles Started, Resolved (Ok case) ->
        { state with CaseFiles = InProgress }, Cmd.loadCaseFiles case.Id
    | LoadCaseFiles (Finished x), Resolved (Ok _) ->
        { state with CaseFiles = Resolved x }, Cmd.none
    | OnCommentChange comment, Resolved (Ok _) ->
        state |> withComment comment, Cmd.none
    | AddComment Started, Resolved (Ok case) ->
        state |> withSaveCommentResult InProgress, Cmd.saveComment case.Id state.EditComment.Text
    | AddComment (Finished (Ok _)), Resolved (Ok _) ->
        state
        |> withCase (withNewComment state.EditComment.Text)
        |> withSaveCommentResult (Resolved (Ok ()))
        |> withComment String.Empty, Cmd.none
    | AddComment (Finished (Error e)), Resolved (Ok _) ->
        state |> withSaveCommentResult (Resolved (Error e)), Cmd.none
    | GoToCases, Resolved (Ok _) ->
        state, Cmd.ofSub (fun _ -> goToCases ())
    | DeleteCase ConfirmationStarted, Resolved (Ok _) ->
        { state with DeleteResult = Requested}, Cmd.none
    | DeleteCase Canceled, Resolved (Ok _) ->
        {state with DeleteResult = NotStarted}, Cmd.none
    | DeleteCase Confirmed, Resolved (Ok case) ->
        {state with DeleteResult = ConfirmationInProgress}, Cmd.delete case
    | DeleteCase (ConfirmationFinished (Ok _)), Resolved (Ok _) ->
        { state with DeleteResult = Done (Ok ()) }, navigateTo Route.Cases
    | DeleteCase (ConfirmationFinished (Error ex)), Resolved (Ok _) ->
        { state with DeleteResult = Done (Error ex) }, navigateTo Route.Cases
    | ChangeStatus (status, Started), Resolved (Ok case) ->
        { state with SelectedStatus = InProgress}, Cmd.changeStatus status case
    | ChangeStatus (status, Finished (Ok _)), Resolved (Ok case) ->
        { state with Case = Resolved (Ok { case with Status = status }); SelectedStatus = Resolved status }, Cmd.none
    | ChangeStatus (_, Finished (Error e)), Resolved (Ok case) ->
        { state with SelectedStatus = Resolved case.Status }, Cmd.toastServerError "Change status" e
    | MediaFileDeleted mediaFile, Resolved (Ok _) ->
        match state.CaseFiles with
        | Deferred.Resolved (Ok files) ->
            let newState = files |> List.filter(fun x -> x <> mediaFile)
            {state with CaseFiles = Deferred.Resolved (Ok newState)}, Cmd.none
        | _ -> state, Cmd.none
    | SwitchTabTo tab, Resolved (Ok _) ->
        let cmd =
            match tab, state.CaseFiles with
            | Gallery, HasNotStartedYet -> Cmd.ofMsg (LoadCaseFiles Started)
            | _, _ -> Cmd.none

        { state with ActiveTab = tab }, cmd
    | GoToMaps coordinates, _ ->
        let openGoogleMaps coordinates =
            Browser.Dom.document.``open``(Common.toGoogleMaps coordinates.Latitude coordinates.Longitude,"", "", true)
        state, Cmd.ofSub (fun _ -> openGoogleMaps coordinates |> ignore)
    | CreatePdfFile Started, Resolved (Ok case) ->
        { state with CreatePdfFileInProgress = true }, Cmd.createPdfFile case
    | CreatePdfFile (Finished (Ok _)), _ ->
        { state with CreatePdfFileInProgress = false }, Cmd.none
    | CreatePdfFile (Finished (Error e)), _ ->
        { state with CreatePdfFileInProgress = false }, Cmd.toastServerError "Create PDF" e
    | CreateMediaZipFile Started, Resolved (Ok case) ->
        { state with CreateMediaZipFileInProgress = true }, Cmd.createMediaZipFile case
    | CreateMediaZipFile (Finished (Ok _)), _ ->
        { state with CreateMediaZipFileInProgress = false }, Cmd.none
    | CreateMediaZipFile (Finished (Error er)), _ ->
        let cmd =
            match er with
            | DownloadMediaFilesErrors.NoMedia -> Cmd.toastWarning (t "warning.no.media")
            | DownloadMediaFilesErrors.ServerError _ -> Cmd.toastError (t "error.downloading.zipFile")
            | DownloadMediaFilesErrors.Exception _ -> Cmd.toastError (t "error.downloading.zipFile")
        { state with CreateMediaZipFileInProgress = false }, cmd
    | _, _ ->
        state, Cmd.ofSub (fun _ -> Browser.Dom.console.warn ("The combination of state and message is not expected", state, msg))

open Feliz.Bulma
open Elmish.React
let isDeleting (state: State) =
    match state.DeleteResult with
    | ConfirmationInProgress -> true
    | _ -> false

let renderToolbar (case: CaseDetail) (state: State) (dispatch: Msg -> unit) =
    let renderSelectOption (selected: bool) (id:string) (text: string)  =
        Html.option [
            prop.value id
            prop.text text
            prop.selected selected
        ]

    let caption = function
        | CaseStatus.New -> t "caseStatus.new"
        | CaseStatus.Emergency -> t "caseStatus.emergency"
        | CaseStatus.Ongoing -> t "caseStatus.inProgress"
        | CaseStatus.Closed -> t "caseStatus.Closed"

    let fromValue = function
        | "New" -> CaseStatus.New
        | "Emergency" -> CaseStatus.Emergency
        | "Ongoing" -> CaseStatus.Ongoing
        | "Closed" -> CaseStatus.Closed
        | x -> failwithf "Can't define case status from value %s" x

    let statusColor = function
        | CaseStatus.New -> BulmaCss.``is-primary``
        | CaseStatus.Emergency -> BulmaCss.``is-danger``
        | CaseStatus.Ongoing -> BulmaCss.``is-success``
        | CaseStatus.Closed -> BulmaCss.``is-black``

    let statuses =
        [ CaseStatus.New; CaseStatus.Emergency; CaseStatus.Ongoing; CaseStatus.Closed ]
        |> List.map (fun x ->
            match x with
            | CaseStatus.Emergency when case.Status = x -> renderSelectOption (case.Status = x) (string x) (caption x)
            | CaseStatus.Emergency when case.Status <> x -> Html.none
            | x -> renderSelectOption (case.Status = x) (string x) (caption x)
        )

    let changeStatus x =
        let status = fromValue x
        ChangeStatus (status, Started)

    let isDeleting = isDeleting state

    Bulma.level [
        prop.children [
            Bulma.levelLeft [
                Bulma.levelItem [
                    Bulma.title.h3 [
                        text.isUppercase
                        prop.text (t "case.report")
                    ]
                ]
                Bulma.levelItem [
                    prop.style [
                        style.marginLeft (length.rem 2)
                    ]
                    prop.children [
                        Bulma.select [
                            select.isFullWidth
                            prop.classes [
                                statusColor case.Status
                                if (Deferred.resolved state.SelectedStatus |> not) then BulmaCss.``is-loading``
                            ]
                            prop.children statuses
                            prop.onChange (changeStatus >> dispatch)
                        ]
                    ]
                ]
                Bulma.levelItem [
                    Bulma.button.a [
                        prop.classes [BulmaCss.``has-text-primary``]
                        prop.children [
                            Bulma.icon [
                                Html.i [
                                    prop.classes [ MdiCss.Mdi; MdiCss.MdiPencilOutline; MdiCss.Mdi24Px ]
                                ]
                            ]
                        ]
                        if isDeleting then button.isLoading
                        prop.href (Route.CaseEdit (string case.Id) |> toPath)
                    ]
                ]
                Bulma.levelItem [
                    Bulma.button.button [
                        prop.classes [BulmaCss.``has-text-primary``]
                        prop.children [
                            Bulma.icon [
                                Html.i [
                                    prop.classes [ MdiCss.Mdi; MdiCss.MdiFilePdfOutline; MdiCss.Mdi24Px ]
                                ]
                            ]
                        ]
                        prop.onClick (fun x -> x.preventDefault(); dispatch (CreatePdfFile Started))
                        if state.CreatePdfFileInProgress then button.isLoading
                        prop.href (Route.CaseEdit (string case.Id) |> toPath)
                    ]
                ]
                Bulma.levelItem [
                    Bulma.button.button [
                        prop.classes [BulmaCss.``has-text-primary``]
                        prop.children [
                            Bulma.icon [
                                Html.i [
                                    prop.classes [ MdiCss.Mdi; MdiCss.MdiDownload; MdiCss.Mdi24Px ]
                                ]
                            ]
                        ]
                        prop.onClick (fun x -> x.preventDefault(); dispatch (CreateMediaZipFile Started))
                        if state.CreateMediaZipFileInProgress then button.isLoading
                        prop.href (Route.CaseEdit (string case.Id) |> toPath)
                    ]
                ]
                Bulma.levelItem [
                    Bulma.button.button [
                        prop.classes [BulmaCss.``has-text-danger``]
                        prop.children [
                            Bulma.icon [
                                Html.i [
                                    prop.classes [ MdiCss.Mdi; MdiCss.MdiDeleteOutline; MdiCss.Mdi24Px ]
                                ]
                            ]
                        ]
                        prop.onClick (fun x-> x.preventDefault(); dispatch (DeleteCase ConfirmationStarted))
                        if isDeleting then button.isLoading
                    ]
                ]
                Bulma.levelItem [
                    Bulma.button.a [
                        prop.className "button-shadow"
                        Bulma.color.isPrimary
                        prop.target "_blank"
                        match case.DeviceId with
                        | Some deviceId -> prop.href (toPath (Route.VideoCall4Version (string case.Id, Some deviceId)))
                        | None -> prop.href (toPath (Route.VideoCall4Version (string case.Id, None)))
                        if isDeleting then button.isLoading
                        prop.children [
                            Bulma.icon [
                                Html.i [
                                    prop.classes [  MdiCss.Mdi; MdiCss.MdiPhoneOutline; MdiCss.Mdi18Px  ]
                                ]
                            ]
                        ]
                    ]
                ]
            ]
            Bulma.levelRight []
        ]
    ]
let renderHeader (case: CaseDetail) (state: State) (dispatch: Msg -> unit) =
    Html.div [
        prop.classes [ AppCss.Toolbar]
        prop.children [
            renderToolbar case state dispatch
        ]
    ]

let row (caption: string) value =
    Bulma.columns [
        prop.children [
            Bulma.column [
                column.is2
                Bulma.color.hasTextGrey
                prop.text caption
            ]
            Bulma.column [
                text.hasTextWeightBold
                prop.children [value]
            ]
        ]
    ]

let renderAbout (case: CaseDetail) (dispatch: Msg -> unit) =
    let renderClientType = function
        | Person -> t "case.policyholder"
        | Workshop -> t "case.partner"

    let renderDamageType = function
        | Construction -> t "case.damageConstruction"
        | Motor _ -> t "case.damageMotor"
        | Prevention -> t "case.damagePrevention"
        | Other -> t "case.damageOther"

    let renderRegNumber = function
        | Motor regNumber ->
            row (t "case.regnumber") (Html.text regNumber)
        | _ -> Html.none

    Bulma.content [
        prop.children [
            row (t "case.number") (Html.text (string case.Number))
            row (t"case.damageType") (Html.text (case.DamageType |> renderDamageType))
            //renderRegNumber case.DamageType
            //row (t "case.address") (Html.text case.Address)
            row (t "case.booking") (Html.text (case.ClientType |> Option.map renderClientType |> Option.defaultValue ""))
            row (t "case.inspector") (Html.text case.Inspector)
            row (t "case.date") (Common.renderDateOption case.VideoCallDate)
            //row (t "case.inspectionDate") (Common.renderDateOption case.InspectionDate)
            row (t "case.deviceId") (Html.span (case.DeviceId |> Option.defaultValue ""))
        ]
    ]

open Feliz.UseListener

let gallery = React.functionComponent( fun (props: {| media: Media list; current: int; visible: bool; onClose: unit -> unit |}) ->
    let currentPosition, setPosition = React.useState(props.current)
    let currentMedia = props.media |> List.indexed |> List.find (fun (idx, _) -> idx = currentPosition) |> snd

    let goToPrevious () = (currentPosition + props.media.Length - 1) % props.media.Length |> setPosition
    let goToNext () = (currentPosition + props.media.Length + 1) % props.media.Length |> setPosition

    React.useListener.onKeyDown (fun ev ->
            match ev.key with
            | "Escape" -> props.onClose()
            | "ArrowRight" -> goToNext()
            | "ArrowLeft" -> goToPrevious()
            | _ -> ()
        )

    let renderMedia media =
        match media with
        | Image file ->
            Bulma.image [
                Html.img [
                    prop.src file.Url
                ]
            ]
        | Video file ->
            Bulma.image [
                image.is16by9
                prop.children [
                    Html.div [
                        prop.classes [BulmaCss.``has-ratio``]
                        prop.style [
                            style.display.flex
                            style.alignItems.center
                            style.justifyContent.center
                        ]
                        prop.children [
                            Video.player [
                                Video.Url file.Url
                                Video.Controls true
                            ]
                        ]
                    ]

                ]
            ]
        | VideoProcessing file ->
            Bulma.image [
                Html.img [
                    prop.src file.Url
                ]
            ]

    Bulma.modal [
        if props.visible then modal.isActive
        prop.children [
            Bulma.modalBackground []
            Bulma.modalContent [
                prop.classes ["modal-gallery"; AppCss.Scrollbar]
                prop.style [ style.width (length.percent 50)]
                prop.children [
                    renderMedia currentMedia
                ]
            ]
            Bulma.button.button [
                prop.style [
                    style.position.absolute
                    style.right (length.rem 1)
                ]
                prop.text ">"
                prop.onClick (fun x -> x.preventDefault(); goToNext())
            ]
            Bulma.button.button [
                prop.style [
                    style.position.absolute
                    style.left (length.rem 1)
                ]
                prop.text "<"
                prop.onClick (fun x -> x.preventDefault(); goToPrevious())
            ]
            Bulma.modalClose [
                prop.onClick (fun x -> x.preventDefault(); props.onClose())
            ]
        ]
    ]
)

open Fable.Core.JsInterop
open FsToolkit.ErrorHandling
let private videoPlaceholder : string = importAll "./public/video placeholder.png"
let private imagePlaceholder : string = importAll "./public/photo placeholder.png"

let renderMediaItem idx media goToMaps =
    React.functionComponent(fun (
        props: {| showGallery: {| position: int; visible: bool |}
                  setShowGallery: {| position: int; visible: bool |} -> unit
                  setEditImage: MediaFile option -> unit
                  setDeletingState: {|File: Media option; Confirm: bool|} -> unit
                  isVideoCall: bool |}) ->
    let hovered, setHovered = React.useState(false)
    let setShowGallery = props.setShowGallery
    let setDeletingState = props.setDeletingState
    let setEditImage = props.setEditImage

    let onErrorLoadImage fallbackImage (e: Browser.Types.Event) =
        let elem = e.target :?> Browser.Types.HTMLImageElement
        elem.onerror <- ignore
        elem.src <- fallbackImage

    let toAddressName (address: Shared.VideoCall.Address) =
        match address with
        | Address.Address a -> a
        | UnknownAddress -> t "case.mediaAddress.Unknown"
        | NoCoordinates -> t "case.mediaAddress.NoLocation"
        | InProcess -> t "case.mediaAddress.processing"

    Html.div [
        match media with
        | Image file ->
            Bulma.image [
                prop.className [AppCss.BackgroundGradient]
                prop.onMouseEnter (fun _ -> setHovered(true))
                prop.onMouseLeave (fun _ -> setHovered(false))
                prop.style [
                    style.width (if props.isVideoCall then 200 else 240)
                    style.height (if props.isVideoCall then 120 else 160)
                    style.marginBottom (if props.isVideoCall then 50 else 100)
                ]
                prop.children [
                    Html.img [
                        prop.src file.ThumbnailUrl
                        prop.classes [AppCss.MediaInGallery]
                        prop.style [
                            style.width (if props.isVideoCall then 200 else 240)
                            style.height (if props.isVideoCall then 120 else 160)
                            style.cursor.pointer
                        ]
                        prop.onClick (fun x -> x.preventDefault(); setShowGallery {| visible = true; position = idx|} )
                        prop.onError (onErrorLoadImage imagePlaceholder)
                    ]
                    if hovered then
                        Bulma.field.div [
                            field.hasAddons
                            prop.classes [ AppCss.ThumbnailToolbar ]
                            prop.children [
                                Bulma.buttons [
                                    prop.children [
                                        Bulma.button.button [
                                            button.isSmall
                                            color.hasTextPrimary
                                            prop.style [style.backgroundColor.transparent; style.borderRadius 10]
                                            prop.children [
                                                Bulma.icon [
                                                    icon.isSmall
                                                    prop.children [
                                                        Html.i [
                                                            prop.classes [
                                                                MdiCss.Mdi
                                                                MdiCss.Mdi24Px
                                                                MdiCss.MdiPencil
                                                            ]
                                                        ]
                                                    ]
                                                ]
                                            ]
                                            prop.onClick (fun x -> x.preventDefault(); setEditImage(Some file) )
                                            prop.onError (onErrorLoadImage imagePlaceholder)
                                        ]
                                        Bulma.button.button [
                                            button.isSmall
                                            color.hasTextDanger
                                            prop.style [style.backgroundColor.transparent; style.borderRadius 10]
                                            prop.children [
                                                Bulma.icon [
                                                    icon.isSmall
                                                    prop.children [
                                                        Html.i [
                                                            prop.classes [
                                                                MdiCss.Mdi
                                                                MdiCss.Mdi24Px
                                                                MdiCss.MdiDelete
                                                            ]
                                                        ]
                                                    ]
                                                ]
                                            ]
                                            prop.onClick (fun x -> x.preventDefault(); setDeletingState {|File = Some media; Confirm = true|} )
                                            prop.onError (onErrorLoadImage imagePlaceholder)
                                        ]
                                    ]
                                ]
                            ]
                        ]

                    Html.i [
                        prop.classes [
                            MdiCss.Mdi
                            MdiCss.Mdi24Px
                            MdiCss.MdiCameraOutline
                            AppCss.ThumbnailIconImage
                            AppCss.IsClickable
                        ]
                        prop.onClick (fun x -> x.preventDefault(); setShowGallery {| visible = true; position = idx|} )
                        prop.onError (onErrorLoadImage imagePlaceholder)
                    ]

                    //date
                    Bulma.columns [
                        prop.classes [AppCss.ColumnInMediaGallery]
                        prop.children [
                            Bulma.column [
                                text.hasTextLeft
                                prop.children [
                                    Bulma.text.span [
                                        if props.isVideoCall then prop.style [style.fontSize 12]
                                        color.hasTextGrey
                                        prop.children [
                                            Common.renderDate file.Created
                                        ]
                                    ]
                                ]
                            ]
                            Bulma.column [
                                column.isNarrow
                                prop.classes [ AppCss.IsClickable ]
                                prop.children [
                                    Html.a [
                                        if props.isVideoCall then prop.style [style.fontSize 12]
                                        prop.onClick (fun _ -> Files.downloadFile(file.DownloadUrl, file.Filename) )
                                        prop.text (t "case.media.download")
                                    ]
                                ]
                            ]
                        ]
                    ]
                    if props.isVideoCall |> not then
                        //address
                        Bulma.columns [
                            columns.isVCentered
                            prop.classes [AppCss.ColumnInMediaGallery]
                            prop.children [
                                Bulma.column [
                                    text.hasTextLeft
                                    prop.style [style.paddingBottom 0]
                                    prop.classes ["line-clamp"]
                                    prop.children [
                                        Bulma.text.span [
                                            color.hasTextGrey
                                            if file.Address = Address.InProcess then
                                                prop.style [style.cursor.text]
                                                prop.text (sprintf "%s" (toAddressName Address.InProcess))
                                            else
                                                match file.Coordinates with
                                                | None ->
                                                    prop.style [style.cursor.text]
                                                | Some coor ->
                                                    prop.style [style.cursor.pointer]
                                                    prop.onClick (fun x -> x.preventDefault(); (goToMaps coor))
                                                prop.text (sprintf "%s" (toAddressName file.Address))
                                        ]
                                    ]
                                ]
                                Bulma.column [
                                    column.isNarrow
                                    prop.children [
                                        Html.a [
                                            match file.Coordinates with
                                            | None ->
                                                prop.classes [MdiCss.Mdi; MdiCss.Mdi24Px; MdiCss.MdiMapMarkerOutline]
                                                prop.style [style.color "hsl(0, 0%, 75%)"; style.cursor.text]
                                            | Some coor ->
                                                prop.classes [MdiCss.Mdi; MdiCss.Mdi24Px; MdiCss.MdiMapMarkerOutline]
                                                prop.style [style.color "#3572BE"]
                                                prop.onClick (fun x -> x.preventDefault(); (goToMaps coor))
                                            prop.onError (onErrorLoadImage imagePlaceholder)
                                        ]
                                    ]
                                ]
                            ]
                        ]
                ]
            ]

        | Video file ->
            Bulma.image [
                prop.className [AppCss.BackgroundGradient]
                prop.style [
                    style.width (if props.isVideoCall then 200 else 240)
                    style.height (if props.isVideoCall then 120 else 160)
                    style.marginBottom (if props.isVideoCall then 50 else 100)
                ]
                prop.onMouseEnter (fun _ -> setHovered(true))
                prop.onMouseLeave (fun _ -> setHovered(false))
                prop.children [
                    Html.img [
                        prop.classes [AppCss.MediaInGallery]
                        prop.style [
                            style.width (if props.isVideoCall then 200 else 240)
                            style.height (if props.isVideoCall then 120 else 160)
                            style.cursor.pointer
                        ]
                        prop.src file.ThumbnailUrl
                        prop.onClick (fun x -> x.preventDefault(); setShowGallery {| visible = true; position = idx|} )
                        prop.onError (onErrorLoadImage videoPlaceholder)
                    ]
                    Html.i [
                        prop.classes [
                            MdiCss.Mdi
                            MdiCss.Mdi24Px
                            MdiCss.MdiVideoOutline
                            AppCss.ThumbnailIconVideo
                            AppCss.IsClickable
                        ]
                        prop.onClick (fun x -> x.preventDefault(); setShowGallery {| visible = true; position = idx|} )
                    ]
                    if hovered then
                        Bulma.field.div [
                            field.hasAddons
                            prop.classes [ AppCss.ThumbnailToolbar ]
                            prop.children [
                                Bulma.control.div [
                                    Bulma.button.button [
                                        button.isSmall
                                        color.hasTextDanger
                                        prop.style [style.backgroundColor.transparent; style.borderRadius 10]
                                        prop.children [
                                            Bulma.icon [
                                                icon.isSmall
                                                prop.children [
                                                    Html.i [
                                                        prop.classes [
                                                            MdiCss.Mdi
                                                            MdiCss.Mdi24Px
                                                            MdiCss.MdiDelete
                                                        ]
                                                    ]
                                                ]
                                            ]
                                        ]
                                        prop.onClick (fun x -> x.preventDefault(); setDeletingState {|File = Some media; Confirm = true|} )
                                        prop.onError (onErrorLoadImage imagePlaceholder)
                                    ]
                                ]
                            ]
                        ]

                    //date
                    Bulma.columns [
                        prop.classes [AppCss.ColumnInMediaGallery]
                        prop.children [
                            Bulma.column [
                                text.hasTextLeft
                                prop.children [
                                    Bulma.text.span [
                                        if props.isVideoCall then prop.style [style.fontSize 12]
                                        color.hasTextGrey
                                        prop.children [
                                            Common.renderDate file.Created
                                        ]
                                    ]
                                ]
                            ]
                            Bulma.column [
                                column.isNarrow
                                prop.classes [ AppCss.IsClickable ]
                                prop.children [
                                    Html.a [
                                        if props.isVideoCall then prop.style [style.fontSize 12]
                                        prop.onClick (fun _ -> Files.downloadFile(file.DownloadUrl, file.Filename) )
                                        prop.text (t "case.media.download")
                                    ]
                                ]
                            ]
                        ]
                    ]
                    if props.isVideoCall |> not then
                        //address
                        Bulma.columns [
                            columns.isVCentered
                            prop.classes [AppCss.ColumnInMediaGallery]
                            prop.children [
                                Bulma.column [
                                    text.hasTextLeft
                                    prop.classes ["line-clamp"]
                                    prop.style [style.paddingBottom 0]
                                    prop.children [
                                        Bulma.text.span [
                                            color.hasTextGrey
                                            match file.Coordinates with
                                            | None ->
                                                prop.style [style.cursor.text]
                                            | Some coor ->
                                                prop.style [style.cursor.pointer]
                                                prop.onClick (fun x -> x.preventDefault(); (goToMaps coor))
                                            prop.text (sprintf "%s" (toAddressName file.Address))
                                        ]
                                    ]
                                ]
                                Bulma.column [
                                    column.isNarrow
                                    prop.children [
                                        Html.a [
                                            match file.Coordinates with
                                            | None ->
                                                prop.classes [MdiCss.Mdi; MdiCss.Mdi24Px; MdiCss.MdiMapMarkerOutline]
                                                prop.style [style.color "hsl(0, 0%, 75%)"; style.cursor.text]
                                            | Some coor ->
                                                prop.classes [ MdiCss.Mdi; MdiCss.Mdi24Px; MdiCss.MdiMapMarkerOutline]
                                                prop.style [style.color "#3572BE"]
                                                prop.onClick (fun x -> x.preventDefault(); (goToMaps coor))
                                            prop.onError (onErrorLoadImage imagePlaceholder)
                                        ]
                                    ]
                                ]
                            ]
                        ]
                ]
            ]

        | VideoProcessing file ->
            Bulma.image [
                prop.style [
                    style.width (if props.isVideoCall then 200 else 240)
                    style.height (if props.isVideoCall then 120 else 160)
                    style.marginBottom (if props.isVideoCall then 50 else 100)
                    style.cursor.pointer
                ]
                prop.children [
                    Html.img [
                        prop.className [AppCss.BackgroundGradient; AppCss.MediaInGallery]
                        prop.style [
                            style.width (if props.isVideoCall then 200 else 240)
                            style.height (if props.isVideoCall then 120 else 160)
                        ]
                        prop.src file.ThumbnailUrl
                        prop.onClick (fun x -> x.preventDefault(); setShowGallery {| visible = true; position = idx|} )
                        prop.onError (onErrorLoadImage videoPlaceholder)
                    ]
                    Html.i [
                        prop.classes [
                            MdiCss.Mdi
                            MdiCss.Mdi24Px
                            MdiCss.MdiDeleteOutline
                            AppCss.ThumbnailIconDelete
                            AppCss.IsClickable
                        ]
                        prop.onClick (fun x -> x.preventDefault(); setDeletingState {|File = Some media; Confirm = true|} )
                        prop.onError (onErrorLoadImage imagePlaceholder)
                    ]
                    //date
                    Bulma.columns [
                        prop.classes [AppCss.ColumnInMediaGallery]
                        prop.children [
                            Bulma.column [
                                text.hasTextLeft
                                prop.children [
                                    Bulma.text.span [
                                        if props.isVideoCall then prop.style [style.fontSize 12]
                                        color.hasTextGrey
                                        prop.children [
                                            Common.renderDate file.Created
                                        ]
                                    ]
                                ]
                            ]
                            Bulma.column [
                                column.isNarrow
                            ]
                        ]
                    ]
                    if props.isVideoCall |> not then
                        //address
                        Bulma.columns [
                            columns.isVCentered
                            prop.classes [AppCss.ColumnInMediaGallery]
                            prop.children [
                                Bulma.column [
                                    text.hasTextLeft
                                    prop.classes ["line-clamp"]
                                    prop.style [style.paddingBottom 0]
                                    prop.children [
                                        Bulma.text.span [
                                            color.hasTextGrey
                                            prop.style [style.cursor.text]
                                            prop.text (sprintf "%s" (toAddressName Address.InProcess))
                                        ]
                                    ]
                                ]
                                Bulma.column [
                                    column.isNarrow
                                    prop.children [
                                        Html.a [
                                            match file.Coordinates with
                                            | None ->
                                                prop.classes [MdiCss.Mdi; MdiCss.Mdi24Px; MdiCss.MdiMapMarkerOutline]
                                                prop.style [style.color "hsl(0, 0%, 75%)"; style.cursor.text]
                                            | Some coordinates ->
                                                prop.classes [ MdiCss.Mdi; MdiCss.Mdi24Px; MdiCss.MdiMapMarkerOutline]
                                                prop.style [style.color "#3572BE"]
                                                prop.onClick (fun x -> x.preventDefault(); (goToMaps coordinates))
                                            prop.onError (onErrorLoadImage imagePlaceholder)
                                        ]
                                    ]
                                ]
                            ]
                        ]
                ]
            ]
        ]
    )

let renderMediaFiles = React.functionComponent(
    fun (props: {|
            media: Media list
            loadCaseFiles: unit -> unit
            mediaFileDeleted: Media -> unit
            goToMaps: Coordinates -> unit
            isVideoCall: bool|}) ->
    let showGallery, setShowGallery = React.useState({|visible = false; position = 0|})
    let editImage, setEditImage = React.useState(None)
    let deletingState, setDeletingState = React.useState({|File = None; Confirm = false|})

    Bulma.content [
        Bulma.columns [
            columns.isMultiline
            prop.children [
                for idx, file in props.media |> List.indexed  do
                    Bulma.column [
                        column.isNarrow
                        prop.children [
                            renderMediaItem idx file
                                props.goToMaps
                                {| showGallery = showGallery
                                   setShowGallery = setShowGallery
                                   setEditImage = setEditImage
                                   setDeletingState = setDeletingState
                                   isVideoCall = props.isVideoCall |}
                        ]
                    ]
            ]
        ]

        let deleteMediaFile (media: Media option) =
            async {
                match media with
                | Some media ->
                    let mediaFile =
                        match media with
                        | Image file -> file
                        | Video file -> file
                        | VideoProcessing file -> file
                    let! _ = Communication.caseApi().deleteFile mediaFile
                    setDeletingState({|File = deletingState.File; Confirm = false|})
                    return (props.mediaFileDeleted media)
                | None -> failwith "Unexpected value of deleting media: None"
            }

        if deletingState.Confirm then
            Components.Common.renderConfirmationWindow
                {| title= (t "delete.media.confirmation.question")
                   confirmedButton = (t "delete.media.confirmation.button.label")
                   confirmed= (fun () -> deleteMediaFile deletingState.File)
                   notConfirmed= (fun _ -> setDeletingState {|File = deletingState.File; Confirm = false|}) |}

        if showGallery.visible then
            gallery {|
                       media = props.media
                       current = showGallery.position
                       visible = showGallery.visible
                       onClose = fun () -> setShowGallery {| visible = false; position = showGallery.position |}
                    |}

        match editImage with
        | Some (file:MediaFile) ->
            let saveEditedImage (x: Browser.Types.Blob) =
                let uploadFileContent url (x: Browser.Types.Blob) =
                    promise {
                        let! response = fetch url [
                            RequestProperties.Method HttpMethod.PUT
                            RequestProperties.Body (BodyInit.Case1 x)
                        ]
                        if response.Ok then return Ok ()
                        else return Error "Failed to upload file to AWS S3"
                    }

                asyncResult {
                    let! content = Blob.readAsByteArray x
                    let! url =
                        Communication.caseApi().getUploadFileUrl()
                        |> AsyncResult.mapError ServerError.explain

                    do! uploadFileContent url.UploadUrl x |> Async.AwaitPromise

                    let args: ImageEditedArgs = {
                         OriginalFileId = file.Id
                         FileId = url.FileId
                         File = content
                    }
                    do! args
                        |> Communication.caseApi().saveEditedImage
                        |> AsyncResult.mapError ServerError.explain

                    setEditImage(None)
                    props.loadCaseFiles()
                }
                |> AsyncResult.mapError
                       (fun x ->
                            Browser.Dom.console.error("Failed to save edited image", x)
                            (t "edit.image.failed.save"))

            ImageEditor.imageEditor
                 {|
                    onSave = saveEditedImage
                    onCancel = (fun () -> setEditImage(None))
                    imageUrl = file.DownloadUrl
                 |}
            Html.none
         | None -> Html.none
    ]
)

let renderCall (call: Queries.Call) dispatch =
    Html.div [
        prop.classes [ BulmaTimeline.``timeline-item`` ]
        prop.children [
            Html.div [
                prop.classes [ BulmaTimeline.``timeline-marker``; BulmaTimeline.``is-icon``; "is-32x32" ]
                prop.children [
                    Html.i [
                        prop.classes [  MdiCss.Mdi; MdiCss.MdiPhoneOutline; MdiCss.Mdi18Px  ]
                    ]
                ]
            ]
            Html.div [
                prop.classes [ BulmaTimeline.``timeline-content`` ]
                prop.children [
                    Html.p [
                        prop.classes [ BulmaCss.heading; BulmaCss.``has-text-black``; BulmaCss.``is-size-6``; BulmaCss.``is-marginless`` ]
                        prop.children [
                            Common.renderDate call.Timestamp
                        ]
                    ]
                    Html.p [
                        prop.classes [ BulmaCss.``has-text-grey`` ]
                        prop.children [
                            Html.text (t "case.duration")
                            Common.renderDuration call.Duration
                        ]
                    ]
                ]
            ]
        ]
    ]

let renderComment (comment: Comment) dispatch =
    Html.div [
        prop.classes [ BulmaTimeline.``timeline-item`` ]
        prop.children [
            Html.div [
                prop.classes [ BulmaTimeline.``timeline-marker``; BulmaTimeline.``is-icon``; "is-32x32" ]
                prop.children [
                    Html.i [
                        prop.classes [ MdiCss.Mdi; MdiCss.MdiCommentOutline; MdiCss.Mdi18Px ]
                    ]
                ]
            ]
            Html.div [
                prop.classes [ BulmaTimeline.``timeline-content``]
                prop.children [
                    Html.p [
                        prop.classes [ BulmaCss.heading; BulmaCss.``has-text-black``; BulmaCss.``is-size-6``; BulmaCss.``is-marginless`` ]
                        prop.children [
                            Common.renderDate comment.Timestamp
                        ]
                    ]
                    Html.p [
                        prop.classes [ BulmaCss.``has-text-grey`` ]
                        prop.text comment.Text
                    ]
                ]
            ]
        ]
    ]

let renderActivity (activity: Activity) dispatch =
    match activity with
    | Call call -> renderCall call dispatch
    | Comment comment -> renderComment comment dispatch

let renderAddComment (state: State) dispatch =
    let isSaving = state.EditComment.SaveCommentResult |> Deferred.inProgress
    Html.form [
        Html.fieldSet [
            prop.disabled isSaving
            prop.children [
                Bulma.field.div [
                    field.isGrouped
                    prop.children [
                        Bulma.control.div [
                            control.isExpanded
                            prop.children [
                                Bulma.textarea [
                                    prop.valueOrDefault state.EditComment.Text
                                    prop.onChange (OnCommentChange >> dispatch)
                                    prop.rows 2
                                ]
                            ]
                        ]
                        Bulma.control.div [
                            Bulma.button.button [
                                prop.classes [ AppCss.ButtonShadow ]
                                Bulma.color.isPrimary
                                if isSaving then button.isLoading
                                prop.text (t "case.comment")
                                prop.onClick (fun x -> x.preventDefault(); dispatch (AddComment Started))
                            ]
                        ]
                    ]
                ]
            ]
        ]
    ]

let renderActivities (case: CaseDetail) (state: State) dispatch =
    Bulma.content [
        prop.children [
            Html.div [
                prop.classes [ BulmaTimeline.timeline ]
                prop.children [
                    Html.div [
                        prop.classes [BulmaTimeline.``timeline-item``; AppCss.FirstTimelineItem]
                        prop.children [
                            Html.div [
                                prop.classes [ BulmaTimeline.``timeline-marker``]
                            ]
                            Html.div [
                                prop.classes [BulmaTimeline.``timeline-content``]
                                prop.children [
                                    Html.p [
                                        prop.classes [BulmaTimeline.heading; BulmaCss.``has-text-black``; BulmaCss.``is-size-6``; BulmaCss.``is-marginless``]
                                        prop.children [
                                            Common.renderDate case.Created
                                        ]
                                    ]
                                    Html.p [
                                        prop.text (t "case.created")
                                    ]
                                ]
                            ]
                        ]
                    ]
                    for activity in case.Activities do
                        renderActivity activity dispatch
                    Html.div [
                        prop.classes [BulmaTimeline.``timeline-item``; AppCss.LastTimelineItem]
                        prop.children [
                            Html.div [
                                prop.classes [ BulmaTimeline.``timeline-marker``]
                            ]
                        ]
                    ]
                ]
            ]
            Bulma.column [
                column.is6
                prop.children [
                    renderAddComment state dispatch
                ]
            ]
        ]
    ]

let renderMedia (state: State) dispatch =
    match state.CaseFiles with
    | HasNotStartedYet
    | InProgress -> Loader.fullScreenMedium (t "case.loadingFiles")
    | Resolved (Ok files) -> renderMediaFiles {|
            media = files
            loadCaseFiles = fun _ -> dispatch (LoadCaseFiles Started)
            mediaFileDeleted = fun media -> dispatch (MediaFileDeleted media)
            goToMaps = fun coordinates -> dispatch (GoToMaps coordinates)
            isVideoCall = false
        |}
    | Resolved (Error e) ->
        Bulma.notification [
            color.isDanger
            prop.text e
        ]

let renderCase (case: CaseDetail) (state: State) dispatch =
    Html.div [
        match state.ActiveTab with
        | Information -> lazyView2 renderAbout case dispatch
        | Gallery -> renderMedia state dispatch
        | HistoryAndComments -> renderActivities case state dispatch
    ]

let renderTab (activeTab: Tab) dispatch =
    Bulma.container [
        Bulma.tabs [
            prop.classes [ BulmaCss.``is-toggle``; AppCss.Toolbar]
            prop.children [
                Html.ul [
                    Bulma.tab [
                        if activeTab = Information then tab.isActive
                        prop.children [
                            Html.a [
                                prop.id "toggle-leftradius"
                                prop.text (t "case.information")
                                prop.onClick (fun x -> x.preventDefault(); dispatch (SwitchTabTo Information))
                            ]
                        ]
                    ]
                    Bulma.tab [
                        if activeTab = Gallery then tab.isActive
                        prop.children [
                            Html.a [
                                prop.text (t "case.gallery")
                                prop.onClick (fun x -> x.preventDefault(); dispatch (SwitchTabTo Gallery))
                            ]
                        ]
                    ]
                    Bulma.tab [
                        if activeTab = HistoryAndComments then tab.isActive
                        prop.children [
                            Html.a [
                                prop.id "toggle-rightradius"
                                prop.text (t "case.historyAndComments")
                                prop.onClick (fun x -> x.preventDefault(); dispatch (SwitchTabTo HistoryAndComments))
                            ]
                        ]
                    ]
                ]
            ]
        ]
    ]


let render (state: State) dispatch =
    match state.Case with
    | HasNotStartedYet
    | InProgress -> Loader.fullScreenMedium (t "case.loading")
    | Resolved (Ok case) ->
        Html.div [
            renderHeader case state dispatch
            renderTab state.ActiveTab dispatch
            renderCase case state dispatch
            if state.DeleteResult=Requested then
                let confirmedTrue () = dispatch (DeleteCase Confirmed)
                let confirmedFalse () = dispatch (DeleteCase Canceled)
                Components.Common.renderDeleteWindow
                    {| message = (t "delete.case.confirmation.question")
                       confirmed = confirmedTrue
                       notConfirmed = confirmedFalse |}
        ]
    | Resolved (Error error) ->
        Bulma.notification [
            Bulma.color.isDanger
            prop.text error
        ]

module Component =
    open Feliz.ElmishComponents

    type CaseDetailsProps = {
        caseId: Guid
        goToCases: unit -> unit
    }

    let caseDetails (props: CaseDetailsProps) =
        React.elmishComponent("CaseDetails", init props.caseId, update props.goToCases, render)