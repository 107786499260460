namespace Components

module Timer =
    open System
    open Elmish
    open Feliz
    open Feliz.UseElmish

    type State = {
        StartTime: DateTimeOffset
        Duration: TimeSpan
    }
    type Msg = | Tick

    let init start = { StartTime = start; Duration = start - DateTimeOffset.Now }, Cmd.ofMsg Tick
    let update (msg: Msg) (state: State) =
        match msg with
        | Tick ->
            let nextState = { state with Duration = DateTimeOffset.Now - state.StartTime  }
            let step = async {
              do! Async.Sleep 1000
              return Tick
            }

            nextState, Cmd.fromAsync step

    let render (state: State) =
        Common.renderDuration state.Duration

    type TimerProps = { Start: DateTimeOffset }
    let reactComponent = React.functionComponent(fun (props: TimerProps) ->
        let state, _ = React.useElmish((fun() -> init props.Start), update, [||])

        render state
    )
