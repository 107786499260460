namespace Shared

module PrimitiveTypes =
    open System

    type String512 = private String512 of string
    type String50 = private String50 of string
    type NonEmptyString = private NonEmptyString of string
    type UserId = private UserId of int
    type CompanyId = private CompanyId of Guid
    type PositionId = private PositionId of int
    type GroupId = private GroupId of Guid
    type ContactId = private ContactId of Guid

    type ValidationErrorType =
        | IsEmpty
        | IsTooLong of int
        | IsTooSmall of int
        | IsNotUnique
        | IsRequired
        | InvalidFormat

    type ValidationError = {
        Field : string
        Type : ValidationErrorType
    }

    [<RequireQualifiedAccess>]
    module ValidationError =
        let create fieldName error = {
            Field = fieldName
            Type = error
        }

        let explainType = function
            | IsEmpty -> "should be not empty"
            | IsTooLong x -> sprintf "should be less than %d" x
            | IsTooSmall x -> sprintf "should be greater than %d" x
            | IsNotUnique -> "should be unique"
            | IsRequired -> "is required"
            | InvalidFormat -> "has invalid format"

        let explain error =
            sprintf "Field '%s' %s" error.Field (explainType error.Type)

    // ===============================
    // Reusable constructors and getters for constrained types
    // ===============================

    /// Useful functions for constrained types
    module ConstrainedType =

        /// Create a constrained string using the constructor provided
        /// Return Error if input is null, empty, or length > maxLen
        let createString fieldName ctor maxLen str =
            if String.IsNullOrEmpty(str) then
                IsEmpty |> ValidationError.create fieldName |> Error
            elif str.Length > maxLen then
                IsTooLong maxLen |> ValidationError.create fieldName |> Error
            else
                Ok (ctor str)

         /// Create a constrained string using the constructor provided
        /// Return Error if input is null, empty
        let createNonEmptyString fieldName ctor str =
            if String.IsNullOrEmpty(str) then
                IsEmpty |> ValidationError.create fieldName |> Error
            else
                Ok (ctor str)

        /// Create a optional constrained string using the constructor provided
        /// Return None if input is null, empty.
        /// Return error if length > maxLen
        /// Return Some if the input is valid
        let createStringOption fieldName ctor maxLen str =
            if String.IsNullOrEmpty(str) then
                Ok None
            elif str.Length > maxLen then
                IsTooLong maxLen |> ValidationError.create fieldName |> Error
            else
                Ok (ctor str |> Some)

        /// Create a constrained string using the constructor provided
        /// Return Error if input is null. empty, or does not match the regex pattern
        let createLike fieldName ctor pattern str =
            if String.IsNullOrEmpty(str) then
                let msg = sprintf "%s: Must not be null or empty" fieldName
                Error msg
            elif System.Text.RegularExpressions.Regex.IsMatch(str,pattern) then
                Ok (ctor str)
            else
                let msg = sprintf "%s: '%s' must match the pattern '%s'" fieldName str pattern
                Error msg

    module String512 =
        let value (String512 str) = str
        let create fieldName = ConstrainedType.createString fieldName String512 512
        let createOption fieldName = ConstrainedType.createStringOption fieldName String512 512

    module String50 =
        let value (String50 str) = str
        let create fieldName = ConstrainedType.createString fieldName String50 50
        let createOption fieldName = ConstrainedType.createStringOption fieldName String50 50

    module NonEmptyString =
        let value (NonEmptyString str) = str
        let create fieldName = ConstrainedType.createNonEmptyString fieldName NonEmptyString
        let createOption fieldName = ConstrainedType.createNonEmptyString fieldName NonEmptyString

    module UserId =
        let value (UserId userId) = userId
        let create userId =
            if userId < 1 then
                userId |> IsTooSmall |> ValidationError.create "UserId" |> Error
            else
                Ok (UserId userId)

    module CompanyId =
        let value (CompanyId companyId) = companyId
        let create companyId = Ok (CompanyId companyId)

    module PositionId =
        let value (PositionId positionId) = positionId
        let create positionId = Ok (PositionId positionId)

    module GroupId =
        let value (GroupId groupId) = groupId
        let create groupId = Ok (GroupId groupId)
        let createFromString (groupId: string) =
            match Guid.TryParse groupId with
            | true, groupId -> GroupId groupId |> Ok
            | false, _ -> ValidationError.create "group id" InvalidFormat |> Error

    module ContactId =
        let value (ContactId contactId) = contactId
        let create contactId = Ok (ContactId contactId)
        let createFromString (contactId: string) =
            match Guid.TryParse contactId with
            | true, groupId -> ContactId groupId |> Ok
            | false, _ -> ValidationError.create "contact id" InvalidFormat |> Error

module Errors =
    open PrimitiveTypes

    type DomainError =
    | CaseAlreadyRegistered of string
    | VideoProcessingHasNotStartedYet of int
    | VideoCaseFileShouldBeCreated of int
    | PasswordOnUserInvalid
    | OldPasswordNotMatchToDbPassword
    | AccountLocked
    | AccountEmailAlreadyRegistered of string
    | NoCaseFound

    type ServiceInfo = {
        Name : string
        Endpoint: string
    }

    type RemoteServiceError = {
        Service : ServiceInfo
        Errors : string list
    }
    module RemoteServiceError =
        let create serviceName endpoint errors =
            {
                Service = { Name = serviceName; Endpoint = endpoint }
                Errors = errors
            }

    type ServerError =
    | Exception of string
    | Validation of ValidationError list
    | DatabaseItemNotFound of string
    | Domain of DomainError
    | RemoteServiceError of RemoteServiceError
    | RemoteServiceErrorSms of string

    [<RequireQualifiedAccess>]
    module DomainError =
        let explain = function
            | CaseAlreadyRegistered caseNumber ->
                sprintf "Case with number '%s' already registered" caseNumber
            | VideoProcessingHasNotStartedYet recordingId ->
                sprintf "Video recording with Id=%d has not started yet." recordingId
            | VideoCaseFileShouldBeCreated recordingId ->
                sprintf "Case file for video recording with Id=%d should be created before." recordingId
            | PasswordOnUserInvalid -> "Invalid user or password"
            | OldPasswordNotMatchToDbPassword -> "Current password is invalid"
            | AccountLocked -> "Account locked"
            | AccountEmailAlreadyRegistered email -> sprintf "Account with email '%s' already registered" email

    [<RequireQualifiedAccess>]
    module ServerError =
        let explain = function
            | Exception s -> sprintf "Ops, something wrong happened. Details: %s" s
            | Validation errors -> errors |> List.map ValidationError.explain |> String.concat ", "
            | DatabaseItemNotFound id -> "Not found in database"
            | Domain error-> DomainError.explain error
            | RemoteServiceErrorSms er -> sprintf "%A" er
            | RemoteServiceError error -> "Problem with third party services"

        let required field = [ValidationError.create field IsRequired] |> ServerError.Validation

module Route =
    /// Defines how routes are generated on server and mapped from client
    let builder typeName methodName =
        sprintf "/api/%s/%s" typeName methodName

    [<Literal>]
    let AccountActivation = "account-activation"

    [<Literal>]
    let ResetPassword = "reset-password"

module Auth =
    open Errors
    type SignedInUser = {
        FullName: string
        Username: string
        AccessToken: string
    }
    type LoginInfo = {
        Login: string
        Password: string
    }
    type ActivateAccount = {
        Code: string
        Password: string
        PasswordRepeat: string
    }
    type ResetPassword =
        { Code: System.Guid
          Password: string }
    type VerifyActivationCodeResult =
        | Valid
        | Invalid
        | AlreadyActivated

    type SignInApi =
        {
            signIn : LoginInfo -> Async<Result<SignedInUser, ServerError>>
            signOut : unit -> Async<unit>
            verifyActivationCode: string -> Async<Result<VerifyActivationCodeResult, ServerError>>
            activateAccount: ActivateAccount -> Async<Result<unit, ServerError>>
            requestResetPassword: string -> Async<Result<unit, ServerError>>
            resetPassword: ResetPassword -> Async<Result<unit, ServerError>>
        }

module VideoCall =
    open System
    open Errors
    type Coordinates = {
        Latitude: decimal
        Longitude: decimal
    }
    type Address =
        | Address of string
        | UnknownAddress
        | NoCoordinates
        | InProcess

    type Context = { CaseId: Guid; ChannelId: string; AgentUID: string; GpsCoordinates: Coordinates option}
    type Resource = { Id: string; SID: string }
    type RecordingApi = {
        start: Context -> Async<Result<Context * Resource, ServerError>>
        stop: Context * Resource -> Async<Result<unit, ServerError>>
    }

module Case =
    open System
    open Errors

    type CaseStatus =
        | New
        | Emergency
        | Ongoing
        | Closed

    type GeoLocation = {
        Latitude: float32
        Longitude: float32
        PlaceId: string
    }

    type Address = {
        Description: string
        Location: GeoLocation option
    }
    with
        static member Default = { Description = ""; Location = None }

    type DamageType =
        | Construction
        | Motor of string
        | Prevention
        | Other

    type ClientType =
        | Workshop
        | Person

    type Inspector = {
        Id: int
        Name: string
    }

    type TimeSlot =
        {
            Id: int
            Time: string
            IsAvailable: bool
        }

    type CallBooking = {
        Date: DateTime
        TimeSlotId: int
    }

    type Case = {
        Id: Guid
        CaseNumber: string
        DamageType: DamageType
        Address: Address option
        Inspector: Inspector option
        //VideoCallDate: DateTimeOffset option
        CallBooking: CallBooking option
        InspectionDate: DateTimeOffset option
        DeviceId: string option
    }

    type FileId = FileId of string
        with
        member self.Value =
            let (FileId x) = self
            x

    module Queries =
        type Case = {
            Id: Guid
            CompanyId: Guid
            CaseNumber: string
            DamageType: DamageType
            ClientType: ClientType option
            Status: CaseStatus
            Address: string
            Inspector: Inspector option
            VideoCallDate: DateTimeOffset option
            CallBooking: CallBooking option
            InspectionDate: DateTimeOffset option
            CreatedDate: DateTimeOffset
            ModifiedDate: DateTimeOffset
            DeviceId: string option
        }

        [<CLIMutable>]
        type CaseStatistic = {
            CompanyId: Guid
            CaseNumber: string
            CreatedDate: DateTimeOffset
            LastModifiedDate: DateTimeOffset
            Assigned: string option
            Status: int
        }

        type CaseStatisticCsv = {
            CaseId: string
            CreatedDate: string
            Assigned: string
            Status: string
            LastModifiedDate: string
        }
        type MediaFile = {
            Id: Guid
            Filename: string
            Url: string
            DownloadUrl: string
            ThumbnailUrl: string
            Coordinates: VideoCall.Coordinates option
            Address: VideoCall.Address
            Created: DateTimeOffset
        }

        type Media =
            | Image of MediaFile
            | Video of MediaFile
            | VideoProcessing of MediaFile

            with member this.Id =
                    match this with
                    | Image mediaFile -> mediaFile.Id
                    | Video mediaFile -> mediaFile.Id
                    | VideoProcessing mediaFile -> mediaFile.Id

        type UploadFileUrl = {
            FileId: FileId
            UploadUrl: string
        }
        type Call = {
            Duration: TimeSpan
            Timestamp: DateTimeOffset
        }

        type HistoryCall = {
            CaseId : string // CaseNumber
            Agent: string // First + last name
            DateTime: int64 // DateTimeOffset.Now.ToUnixTimeSeconds()
            DamageType: string // construction, motor or other
            RegNumber: string
            Duration: string // 00:10
            ImageUrl: string // Company url
        }

        type PlannedCall = {
            CaseId : string // CaseNumber
            DateTime: int64 // DateTimeOffset.Now.ToUnixTimeSeconds()
            DamageType: string // construction, motor or other
            RegNumber: string
            ImageUrl: string // Company url
        }


        type Comment = {
            Text: string
            Timestamp: DateTimeOffset
        }

        type Activity =
            | Call of Call
            | Comment of Comment

        type CaseDetail = {
            Id: Guid
            Number: string
            DamageType: DamageType
            ClientType: ClientType option
            Inspector: string
            Status: CaseStatus
            Address: string
            VideoCallDate: DateTimeOffset option
            InspectionDate: DateTimeOffset option
            MediaFiles: Media list
            Activities: Activity list
            DeviceId: string option
            Created: DateTimeOffset
        }

        type UploadUrl = UploadUrl of string


    type CallClient = {
        CaseId: Guid
        ChannelId: string
        DeviceId: string
    }
    type ShareLinkInfo = {
        Link: string
        Email: string
        CaseId: Guid
    }

    // todo: use those ids
    type CaseId = CaseId of Guid
    type CallId = CallId of Guid

    type StartCall = {
        CallId: Guid
        CaseId: Guid
        ChannelId: string
        DeviceId: string
        Timestamp: DateTimeOffset
    }

    type ConnectContext = {
        ApplicationId: string
        UseProxy: bool
    }

    type FinishCall = {
        CallId: Guid
        Timestamp: DateTimeOffset
    }

    type SaveComment = {
        CaseId: Guid
        Comment: string
    }

    type ChangeStatus = {
        CaseId: Guid
        Status: CaseStatus
    }

    type SearchResult<'t> = {
        Total: int
        Items: 't list
    }
    type TakePhoto = {
        CaseId: Guid
        FileId: FileId
        Coordinates: VideoCall.Coordinates option
    }
    type UploadMedia = {
        CompanyId: Guid
        CaseNumber: string
        FileId: FileId
        Coordinates: VideoCall.Coordinates option
    }
    type TakeMediaArgs =
        | FromVideoCall of TakePhoto
        | FromMobileApp of UploadMedia

    type ImageEditedArgs =
        { OriginalFileId: Guid
          FileId: FileId
          File: byte [] }

    type MediaFilesChangesQuery = {
        CaseId: Guid
        Files: Guid list
    }

    type MediaFilesChanges = {
        Added: Queries.Media list
        Deleted: Guid list
    }
    with member this.NoChanges = this.Added = [] && this.Deleted = []

    type CaseApi = {
        register: Case -> Async<Result<unit, ServerError>>
        change: Case -> Async<Result<unit, ServerError>>
        delete: Guid -> Async<Result<unit, ServerError>>
        connectCallContext: unit -> Async<ConnectContext>
        callClient: CallClient -> Async<Result<unit, ServerError>>
        sendEmailWithLink: ShareLinkInfo -> Async<Result<unit, ServerError>>
        getBaseUrl: unit -> Async<string>
        getTimeSlots: DateTime -> Async<TimeSlot list>
        startCall: StartCall -> Async<Result<unit, ServerError>>
        finishCall: FinishCall -> Async<Result<unit, ServerError>>
        cases: DateTime*DateTime-> Async<Queries.Case list>
        currentCases: unit -> Async<Queries.Case list>
        searchCases: string -> Async<SearchResult<Queries.Case>>
        case: Guid -> Async<Result<Queries.Case, ServerError>>
        details: Guid -> Async<Result<Queries.CaseDetail,ServerError>>
        files: Guid -> Async<Result<Queries.Media list,ServerError>>
        getUpdationInFiles: MediaFilesChangesQuery -> Async<Result<MediaFilesChanges,ServerError>>
        deleteFile: Queries.MediaFile -> Async<Result<unit,ServerError>>
        getUploadFileUrl: unit -> Async<Result<Queries.UploadFileUrl, ServerError>>
        takePhoto: TakeMediaArgs -> Async<Result<unit, ServerError>>
        saveEditedImage: ImageEditedArgs -> Async<Result<unit, ServerError>>
        inspectors: unit -> Async<Inspector list>
        saveComment: SaveComment -> Async<Result<unit, ServerError>>
        changeStatus: ChangeStatus -> Async<Result<unit, ServerError>>
        downloadPdfReport: Guid -> Async<byte[]>
        downloadMedia: Guid -> Async<Result<byte[], ServerError>>
    }

module Users =
    open System
    open PrimitiveTypes
    open Errors

    module Queries =
        type User = {
            Id: int
            FullName: string
            Title: string
            Status: string
            Created: DateTimeOffset
        }
        type UserInfo = {
            FirstName: string
            LastName: string
            CompanyName: string
        }

        [<CLIMutable>]
        type UsersStatistic = {
            CompanyId: Guid
            FullName: string
            Email: string
            LastLoginDate: DateTimeOffset
            LastCallDate: DateTimeOffset
            NumberOfCases: int
        }
        type UsersStatisticCsv = {
            FullName: string
            Email: string
            LastLoginDate: string
            LastCallDate: string
            NumberOfCases: string
        }
        type Role = Agent | SuperVisor
        type Status = Free | Busy
        type Position = {
            Id: PositionId
            Place: string
            Address: string
        }
        type UserDetail = {
            Id: UserId
            FirstName: string
            LastName: string
            Role: Role
            Status: Status
            IsActive: bool
            Title: string
            Email: string
            Created: DateTimeOffset
            Positions: Position list
        }

    type RegisterUser = {
        FirstName: string
        LastName: string
        Title: string
        Email: string
    }

    type ChangePassword =
        { CurrentPassword: string
          NewPassword: string }

    type UserApi = {
        users : unit -> Async<Queries.User list>
        details: UserId -> Async<Queries.UserDetail>
        profileSettings: unit -> Async<Queries.UserDetail>
        register: RegisterUser -> Async<Result<UserId, ServerError>>
        changePassword: ChangePassword -> Async<Result<unit, ServerError>>
    }

module AddressBook =
    open System

    type Group = {
        Name: string
        Id: Guid
    }

    type Contact = {
        FirstName: string
        LastName: string
        DeviceId: string
        Group: Group option
        Id: Guid
    }

    type AddressBook = {
        Contacts: Contact list
        Groups: Group list
    }

    type SearchResult<'t> = {
        Total: int
        Items: 't list
    }

    type AddressBookApi = {
        addressBook: unit -> Async<AddressBook>
        deleteContact: Guid -> Async<Result<unit, Errors.ServerError>>
        deleteGroup: Guid -> Async<Result<unit, Errors.ServerError>>
        saveGroup: Group -> Async<Result<unit, Errors.ServerError>>
        saveContact: Contact -> Async<Result<unit, Errors.ServerError>>
        searchGroups: string -> Async<SearchResult<Group>>
    }
module SendingSms =
    type SmsLinkInfo = {
        Link: string
        Phone: string
    }
    type SmsResponseInfo = {
        Id: int
        NumberOfSMS: int
        TotalCost: decimal
    }
    type SendingApi = {
        sendSms: string -> Async<Result<SmsResponseInfo, Errors.ServerError>>
        sendSmsWithVideoCallLink: SmsLinkInfo -> Async<Result<SmsResponseInfo, Errors.ServerError>>
    }
    [<CLIMutable>]
    type SendingSmsConfiguration = {
        Sender: string
        MessageNotDirectly: string
        MessageWithSharedLink: string
        CampaignName: string
        AuthorizationHeader: string
        Url: string
    }

module RTM =

    [<RequireQualifiedAccess>]
    type Action =
        | JoinChannel of string
        | LeaveChannel of string
        | MessageToChannel of channelId: string * message: string

    [<RequireQualifiedAccess>]
    type Response =
        | Message of string
    module Endpoints =
        let [<Literal>] Root = "/SignalR"

[<AutoOpen>]
module Common =
    type Sorting = Ascending | Descending

    [<RequireQualifiedAccess>]
    module Sorting =
        let toggle = function Ascending -> Descending | Descending -> Ascending