module EditContact

open System
open Elmish
open Feliz.ElmishComponents
open Feliz
open Feliz.Bulma
open Shared.AddressBook
open Extensions.View

let localize = Localization.i18n.ns("editContact")

type State = {
    Contact: Contact
    SaveResult: Deferred<Result<unit, string>>
}

type Msg =
    | FirstNameChanged of string
    | LastNameChanged of string
    | DeviceIdChanged of string
    | GroupIdSelected of Guid option
    | CloseWindow
    | SaveContact of AsyncOperationStatus<Result<unit, string>>

module Cmd =
    let save (contact: Contact) : Cmd<Msg> =
        async {
            try
                let! result = Communication.addressBookApi().saveContact contact |> Remoting.handleNonAuth
                let result = result |> Result.mapError (Components.Common.TranslatedErrors.ServerError.explainTranslation)
                return SaveContact (Finished result)
            with
                ex -> return SaveContact (Finished (Error ex.Message))
        }
        |> Cmd.fromAsync

let init contact = {Contact = contact; SaveResult = HasNotStartedYet}, Cmd.none

let update (close: unit -> unit) msg state =
    match msg with
    | FirstNameChanged name -> {state with Contact = { state.Contact with FirstName =name}}, Cmd.none
    | LastNameChanged name -> {state with Contact = { state.Contact with LastName =name}}, Cmd.none
    | DeviceIdChanged newId -> {state with Contact = { state.Contact with DeviceId =newId}}, Cmd.none
    | GroupIdSelected groupId ->
        let group = groupId |> Option.map (fun x -> { Id = x; Name = "" })
        { state with Contact = { state.Contact with Group = group } }, Cmd.none
    | CloseWindow ->
        close()
        state, Cmd.none
    | SaveContact Started -> {state with SaveResult = InProgress}, Cmd.save state.Contact
    | SaveContact (Finished (Ok _)) ->
        close()
        {state with SaveResult = Resolved (Ok ())}, Cmd.none
    | SaveContact (Finished (Error x)) ->
        {state with SaveResult = Resolved (Error x)}, Cmd.toastServerError "Save group" x


module SearchGroup =
    open Components
    open Communication
    open Extensions

    let private toKeyValue (x: Group) = KeyValuePair.create (x.Id.ToString()) x.Name

    let cmd search : Async<Result<Autocomplete.FoundResult, string>> = async {
        try
            let! result = addressBookApi().searchGroups search
            return Ok { Total = result.Total
                        Items = result.Items |> List.map toKeyValue }
        with
            ex -> return Error ex.Message
    }

    let searchCase = React.functionComponent(fun (props: {| OnGroupSelected: Guid option -> unit; SearchText: string |}) ->
        let autocompleteProps: Autocomplete.Props =
            {
                Autocomplete.Props.Default
                with
                    SearchText = props.SearchText
                    Search = cmd
                    OnSelect =
                        fun x ->
                            let groupId =
                                match x with
                                | Some y ->
                                    let isValid, gid = Guid.TryParse y
                                    if isValid then Some gid else None
                                | None -> None
                            props.OnGroupSelected groupId
                    Localization = {
                        Placeholder = localize.translate "search.group.placeholder"
                        StartTypeToSearch = fun () -> localize.translate "search.group.startTypeToSearch"
                        NoItemsFound = fun () -> localize.translate "search.group.noItemsFound"
                        FoundItems = fun found total ->
                            localize.translate ("search.group.info", {| found = string found; total = string total |})
                    }
                }

        Autocomplete.autocomplete autocompleteProps
    )

let renderToolbar (title: string) state dispatch =
    Bulma.section[
        prop.style[
            style.paddingBottom 0
        ]
        prop.children[
            Bulma.container[
                Bulma.level[
                    Bulma.levelLeft[
                        Bulma.levelItem[
                            prop.classes [BulmaCss.``is-uppercase``]
                            prop.children [Bulma.title.h3 title]
                        ]
                    ]
                    Bulma.levelRight[
                        Bulma.levelItem[
                            Bulma.button.button[
                                prop.onClick(fun x-> x.preventDefault(); dispatch CloseWindow)
                                prop.children[
                                    Bulma.icon[
                                        Html.i[
                                            prop.classes [MdiCss.Mdi; MdiCss.Mdi24Px; MdiCss.MdiClose]
                                        ]
                                    ]
                                ]
                            ]
                        ]
                    ]
                ]
            ]
        ]
    ]

let renderForm state dispatch =
    Bulma.section[
        prop.style[style.paddingTop 90]
        prop.children[
            Bulma.container[
                prop.style [style.textAlign.center]
                prop.children[
                    Bulma.input.text[
                        prop.style [style.marginBottom 10]
                        prop.valueOrDefault state.Contact.FirstName
                        prop.placeholder (localize.translate "placeholder.firstName")
                        prop.onChange (FirstNameChanged >> dispatch)
                    ]
                    Bulma.input.text[
                        prop.style [style.marginBottom 10]
                        prop.valueOrDefault state.Contact.LastName
                        prop.placeholder (localize.translate "placeholder.lastName")
                        prop.onChange (LastNameChanged >> dispatch)
                    ]
                    Bulma.input.text[
                        prop.style [style.marginBottom 10]
                        prop.maxLength 10
                        prop.valueOrDefault state.Contact.DeviceId
                        prop.placeholder (localize.translate "placeholder.deviceId")
                        prop.onChange (DeviceIdChanged >> dispatch)
                    ]
                    Bulma.control.div [
                        prop.style [style.marginBottom 40]
                        prop.children [
                            SearchGroup.searchCase {|
                                                        SearchText = state.Contact.Group |> Option.map (fun x -> x.Name) |> Option.defaultValue ""
                                                        OnGroupSelected = GroupIdSelected >> dispatch |}
                        ]
                    ]
                    Bulma.button.button[
                        color.isPrimary
                        prop.style[style.width (length.percent 100)]
                        prop.text (localize.translate "button.save")
                        if state.Contact.DeviceId="" || state.Contact.FirstName="" then
                            prop.disabled true
                        prop.onClick (fun x -> x.preventDefault(); dispatch (SaveContact Started))
                    ]
                ]
            ]
        ]
    ]

let render (title: string) state dispatch =
    Bulma.section[
        renderToolbar title state dispatch
        renderForm state dispatch
    ]

let editContact contact onClose =
    React.elmishComponent("EditContact", init contact, update onClose, render  (localize.translate "title.editContact"))

let addContact onClose =
    let newContact = {
        Id = Guid.NewGuid()
        DeviceId = ""
        FirstName = ""
        LastName = ""
        Group = None
    }
    React.elmishComponent("AddContact", init newContact, update onClose, render (localize.translate "title.addContact"))