module Rtm

open System
open Shared.RTM
open Fable.SignalR

type ChatMessage =
    | SendMessage of string

type Chat(channelId) =
    let event = Event<string>()
    let hub =
        SignalR.connect<Action,_,_,Response,_>(fun hub ->
            hub.withUrl(Endpoints.Root)
                .withAutomaticReconnect()
                .configureLogging(LogLevel.Error)
                .onMessage (function | Response.Message x -> event.Trigger(x)))
    do
        hub.startNow()
        hub.sendNow (Action.JoinChannel channelId)

    member x.OnMessage = event.Publish

    member x.SendMessage(msg: string) =
        hub.sendNow (Action.MessageToChannel (channelId, msg))

    interface IDisposable with
        member x.Dispose() =
            hub.sendNow (Action.JoinChannel channelId)
            hub.stopNow()