module Router

open System
open Elmish.UrlParser
open Elmish.Navigation

let curry f x y = f (x,y)

type Route =
    | SignIn
    | ForgotPassword
    | ResetPassword of Guid
    | Home
    | Cases
    | RegisterCase
    | CaseDetails of string
    | CaseEdit of string
    | VideoCall4Version of string * string option //caseId * deviceId option
    | Users
    | RegisterUser
    | ProfileSettings
    | UserDetails of int
    | UserEdit of int
    | Positions
    | CallCalendar
    | Export
    | VerifyEmail of string
    | DeveloperPage

let deviceIdParam =
    let parse deviceId =
        match deviceId with
        | "no-deviceId" -> None
        | x -> Some x
    map parse

let resetPasswordParam state=
    custom "resetPasswordCode" (System.Guid.TryParse >> function true, value -> Ok value | _ -> Error "Can't parse Guid" ) state
let routeParser : Parser<(Route->Route),Route> =
    oneOf
        [
          map Route.SignIn (s "signin")
          map Route.ForgotPassword (s "forgotPassword")
          map Route.ResetPassword (s Shared.Route.ResetPassword </> resetPasswordParam)
          map Route.Home (s "home")
          map Route.Cases (s "cases")
          map Route.RegisterCase (s "case" </> s "register")
          map Route.CaseDetails (s "case" </> str)
          map Route.CaseEdit (s "case" </> str </> s "edit")
          map (curry Route.VideoCall4Version) (s "case" </> str </> s "call" </> deviceIdParam str)
          map Route.Users (s "users")
          map Route.UserEdit (s "user" </> i32 </> s "edit")
          map Route.UserDetails (s "user" </> i32)
          map Route.RegisterUser (s "user" </> s "register")
          map Route.ProfileSettings (s "profile" </> s "settings")
          map Route.Positions (s "positions")
          map Route.CallCalendar (s "calendar")
          map Route.Export (s "export")
          map Route.VerifyEmail (s Shared.Route.AccountActivation </> str)
          map Route.DeveloperPage (s "developer")
        ]

let toPath =
    function
    | Route.SignIn -> "/#signin"
    | Route.ForgotPassword -> "/#forgotPassword"
    | Route.ResetPassword code-> sprintf "/#%s/%A" Shared.Route.ResetPassword code
    | Route.Home -> "/#home"
    | Route.Cases -> "/#cases"
    | Route.RegisterCase -> "/#case/register"
    | Route.CaseDetails id -> sprintf "/#case/%s" id
    | Route.CaseEdit id -> sprintf "/#case/%s/edit" id
    | Route.VideoCall4Version (caseId, deviceId) ->
        let id = match deviceId with | Some id -> id | None -> "no-deviceId"
        sprintf "/#case/%s/call/%s" caseId id
    | Route.Users -> "/#users"
    | Route.RegisterUser -> "/#user/register"
    | Route.ProfileSettings -> "/#/profile/settings"
    | Route.UserDetails id -> sprintf "/#user/%d" id
    | Route.UserEdit id -> sprintf "/#user/%d/edit" id
    | Route.Positions -> "/#positions"
    | Route.CallCalendar -> "/#calendar"
    | Route.Export -> "/#export"
    | Route.VerifyEmail code -> sprintf "/#%s/%s" Shared.Route.AccountActivation code
    | Route.DeveloperPage -> "/#developer"

let navigateTo page = page |> toPath |> Navigation.newUrl

let modifyUrl page = page |> toPath |> Navigation.modifyUrl