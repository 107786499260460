module Calendar

open System
open Elmish
open Feliz.Bulma
open Fable.DateFunctions
open Shared.Case
open Feliz

let t = Localization.ns("calendar")

type State = {
    DayOnWeek: DateTime
    WeekNumber: int
    LoadedCalendar : Deferred<Result<Queries.Case list, string>>
}

type Msg =
    | LoadCalls of AsyncOperationStatus<Result<Queries.Case list, string>>
    | OpenCases
    | WeekChanged of DateTime
    | RegisterCase
    | GoToDetails of string

open Communication
open Extensions.View

let load (dateOnWeek: DateTime) : Async<Msg> = async {
    try
        let startOfPeriod = dateOnWeek.StartOfISOWeek()
        let endOfPeriod = dateOnWeek.StartOfISOWeek().AddDays(6)
        let! x = caseApi().cases (startOfPeriod, endOfPeriod)
        return LoadCalls (Finished (Ok x))
    with
        ex -> return LoadCalls (Finished (Error ex.Message))
}

let init () =
    { LoadedCalendar = HasNotStartedYet; WeekNumber = DateTime.Now.GetISOWeek(); DayOnWeek=DateTime.Now}, Cmd.ofMsg (LoadCalls Started)

let update (msg: Msg) (state: State) =
    match msg with
    | LoadCalls Started -> {state with LoadedCalendar = InProgress}, Cmd.fromAsync (load state.DayOnWeek)
    | LoadCalls (Finished (Ok (calls))) ->
        {state with LoadedCalendar = Resolved (Ok (calls))}, Cmd.none
    | LoadCalls (Finished (Error e)) ->
        {state with LoadedCalendar = Resolved (Error e)}, Cmd.toastServerError "Load calendar" e
    | WeekChanged date ->
        {state with WeekNumber= date.GetISOWeek(); DayOnWeek=date}, Cmd.ofMsg(LoadCalls Started)
    | OpenCases -> state, Router.navigateTo (Router.Cases)
    | RegisterCase -> state, Router.navigateTo (Router.RegisterCase)
    | GoToDetails id -> state, Router.navigateTo (Router.CaseDetails id)

open Feliz

let renderToolbar (state: State) dispatch=
    Bulma.level [
        Bulma.levelLeft []
        Bulma.levelRight [
            Bulma.levelItem [
                Bulma.control.p[
                    Bulma.control.hasIconsRight
                    prop.style[style.padding 20]
                    prop.children[
                        Flatpickr.flatpickr [
                            Flatpickr.DateFormat(t "flatpickr.week"+ " - "+"W")
                            Flatpickr.custom "value" state.DayOnWeek false
                            Flatpickr.custom "locale" {|firstDayOfWeek= 1|} true
                            Flatpickr.EnableWeekNumbers true
                            Flatpickr.ClassName "input"
                            Flatpickr.OnChange (WeekChanged >> dispatch)
                            if Localization.currentLanguage () = "se" then
                                Flatpickr.Locale FlatpickrExtensions.Locales.swedish
                        ]
                    ]
                ]
                Bulma.buttons[
                    Bulma.button.button[
                        prop.style[style.marginRight 20]
                        prop.children[
                            Bulma.icon[
                                Html.i[
                                    prop.style[style.color "#3572BE"]
                                    prop.classes [MdiCss.Mdi; MdiCss.Mdi24Px; MdiCss.MdiViewList]
                                ]
                            ]
                        ]
                        prop.onClick (fun x -> x.preventDefault(); dispatch OpenCases)
                    ]
                    Bulma.button.button [
                        prop.classes [AppCss.ButtonShadow]
                        Bulma.color.isPrimary
                        prop.text (t "button.addingCase")
                        prop.onClick (fun x -> x.preventDefault(); dispatch RegisterCase)
                    ]
                ]
            ]
        ]
    ]

let renderCalendar (cases: Shared.Case.Queries.Case list) (state: State) dispatch =
    let renderDamageType = function
        | Construction _-> t "case.damageConstruction"
        | Motor _ -> t "case.damageMotor"
        | Prevention -> t "case.damagePrevention"
        | Other -> t "case.damageOther"
    Bulma.columns[
                Bulma.column[
                    Html.div[
                        //Monday
                        prop.text(t "title.monday" + ", " + state.DayOnWeek.StartOfISOWeek().Format("d MMM yyyy"))
                        prop.classes[AppCss.BoxCalendarHeader]
                        if state.DayOnWeek.StartOfISOWeek().Date=DateTime.Today then
                            prop.style[
                                style.opacity 1.0
                                style.color "#3572BE"
                            ]
                    ]
                    for case in cases do
                        if ((case.VideoCallDate|> Option.map (fun d -> d.ToLocalTime().DateTime.Format("MMM dd yyyy")) |> Option.defaultValue "") = state.DayOnWeek.StartOfISOWeek().Format("MMM dd yyyy")) then
                            Bulma.box[
                                prop.classes [AppCss.BoxCalendar]
                                prop.children[
                                    Html.p(case.CaseNumber)
                                    Html.p[
                                        prop.style[style.opacity 0.5]
                                        prop.text(case.DamageType |> renderDamageType)
                                    ]
                                    Html.p[
                                        prop.style[
                                            style.paddingTop 10
                                            style.textAlign.right
                                            style.alignItems.center
                                            style.color "#3572BE"
                                        ]
                                        prop.text(case.VideoCallDate.Value.ToLocalTime().DateTime.Format("HH:mm"))
                                    ]
                                ]
                                prop.onClick (fun x -> x.preventDefault(); GoToDetails (case.Id.ToString()) |> dispatch)
                            ]
                ]
                Bulma.column[
                    Html.div[
                        //Tuesday
                        prop.classes[AppCss.BoxCalendarHeader]
                        prop.text(t "title.tuesday" + ", " + state.DayOnWeek.StartOfISOWeek().AddDays(1).Format("d MMM yyyy"))
                        if state.DayOnWeek.StartOfISOWeek().AddDays(1).Date=DateTime.Today then
                            prop.style[
                                style.opacity 1.0
                                style.color "#3572BE"
                            ]
                    ]
                    for case in cases do
                        if ((case.VideoCallDate|> Option.map (fun d -> d.ToLocalTime().DateTime.Format("MMM dd yyyy")) |> Option.defaultValue "") = state.DayOnWeek.StartOfISOWeek().AddDays(1).Format("MMM dd yyyy")) then
                            Bulma.box[
                                prop.classes [AppCss.BoxCalendar]
                                prop.children[
                                    Html.p(case.CaseNumber)
                                    Html.p[
                                        prop.style[style.opacity 0.5]
                                        prop.text(case.DamageType |> renderDamageType)
                                    ]
                                    Html.p[
                                        prop.style[
                                            style.paddingTop 10
                                            style.textAlign.right
                                            style.alignItems.center
                                            style.color "#3572BE"
                                        ]
                                        prop.text(case.VideoCallDate.Value.ToLocalTime().DateTime.Format("HH:mm"))
                                    ]
                                ]
                                prop.onClick (fun x -> x.preventDefault(); GoToDetails (case.Id.ToString()) |> dispatch)
                            ]
                ]
                Bulma.column[
                    //Wednesday
                    Html.div[
                        prop.text(t "title.wednesday" + ", " + state.DayOnWeek.StartOfISOWeek().AddDays(2).Format("d MMM yyyy"))
                        prop.classes[AppCss.BoxCalendarHeader]
                        if state.DayOnWeek.StartOfISOWeek().AddDays(2).Date=DateTime.Today then
                            prop.style[
                                style.opacity 1.0
                                style.color "#3572BE"
                            ]
                    ]
                    for case in cases do
                        if ((case.VideoCallDate|> Option.map (fun d -> d.ToLocalTime().DateTime.Format("MMM dd yyyy")) |> Option.defaultValue "") = state.DayOnWeek.StartOfISOWeek().AddDays(2).Format("MMM dd yyyy")) then
                            Bulma.box[
                                prop.classes [AppCss.BoxCalendar]
                                prop.children[
                                    Html.p(case.CaseNumber)
                                    Html.p[
                                        prop.style[style.opacity 0.5]
                                        prop.text(case.DamageType |> renderDamageType)
                                    ]
                                    Html.p[
                                        prop.style[
                                            style.paddingTop 10
                                            style.textAlign.right
                                            style.alignItems.center
                                            style.color "#3572BE"
                                        ]
                                        prop.text(case.VideoCallDate.Value.ToLocalTime().DateTime.Format("HH:mm"))
                                    ]
                                ]
                                prop.onClick (fun x -> x.preventDefault(); GoToDetails (case.Id.ToString()) |> dispatch)
                            ]
                ]
                Bulma.column[
                    //Thursday
                    Html.div[
                        prop.text(t "title.thursday" + ", " + state.DayOnWeek.StartOfISOWeek().AddDays(3).Format("d MMM yyyy"))
                        prop.classes[AppCss.BoxCalendarHeader]
                        if state.DayOnWeek.StartOfISOWeek().AddDays(3).Date=DateTime.Today then
                            prop.style[
                                style.opacity 1.0
                                style.color "#3572BE"
                            ]
                    ]
                    for case in cases do
                        if ((case.VideoCallDate|> Option.map (fun d -> d.ToLocalTime().DateTime.Format("MMM dd yyyy")) |> Option.defaultValue "") = state.DayOnWeek.StartOfISOWeek().AddDays(3).Format("MMM dd yyyy")) then
                            Bulma.box[
                                prop.classes [AppCss.BoxCalendar]
                                prop.children[
                                    Html.p(case.CaseNumber)
                                    Html.p[
                                        prop.style[style.opacity 0.5]
                                        prop.text(case.DamageType |> renderDamageType)
                                    ]
                                    Html.p[
                                        prop.style[
                                            style.paddingTop 10
                                            style.textAlign.right
                                            style.alignItems.center
                                            style.color "#3572BE"
                                        ]
                                        prop.text(case.VideoCallDate.Value.ToLocalTime().DateTime.Format("HH:mm"))
                                    ]
                                ]
                                prop.onClick (fun x -> x.preventDefault(); GoToDetails (case.Id.ToString()) |> dispatch)
                            ]
                ]
                Bulma.column[
                    //Friday
                    Html.div[
                        prop.text(t "title.friday" + ", " + state.DayOnWeek.StartOfISOWeek().AddDays(4).Format("d MMM yyyy"))
                        prop.classes[AppCss.BoxCalendarHeader]
                        if state.DayOnWeek.StartOfISOWeek().AddDays(4).Date=DateTime.Today then
                            prop.style[
                                style.opacity 1.0
                                style.color "#3572BE"
                            ]
                    ]
                    for case in cases do
                        if ((case.VideoCallDate|> Option.map (fun d -> d.ToLocalTime().DateTime.Format("MMM dd yyyy")) |> Option.defaultValue "") = state.DayOnWeek.StartOfISOWeek().AddDays(4).Format("MMM dd yyyy")) then
                            Bulma.box[
                                prop.classes [AppCss.BoxCalendar]
                                prop.children[
                                    Html.p(case.CaseNumber)
                                    Html.p[
                                        prop.style[style.opacity 0.5]
                                        prop.text(case.DamageType |> renderDamageType)
                                    ]
                                    Html.p[
                                        prop.style[
                                            style.paddingTop 10
                                            style.textAlign.right
                                            style.alignItems.center
                                            style.color "#3572BE"
                                        ]
                                        prop.text(case.VideoCallDate.Value.ToLocalTime().DateTime.Format("HH:mm"))
                                    ]
                                ]
                                prop.onClick (fun x -> x.preventDefault(); GoToDetails (case.Id.ToString()) |> dispatch)
                            ]
                ]
    ]

open Feliz.ElmishComponents
let renderForm (state: State) dispatch =
    match state.LoadedCalendar with
    | HasNotStartedYet
    | InProgress -> Components.Loader.fullScreenMedium (t "loading.calendar")
    | Resolved (Ok case) -> renderCalendar case state dispatch
    | Resolved (Error ex) -> Html.none

let render (state: State) dispatch =
    Bulma.section[
        prop.style[style.paddingTop 12]
        prop.children[
            Bulma.container[
                renderToolbar state dispatch
                renderForm state dispatch
            ]
        ]
    ]

let openCalendar() =
    React.elmishComponent("OpenCalendar", init(), update, render)