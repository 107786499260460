﻿module Common

module Form =
    open Fable.Validation.Core

    let filter x (errors: Map<string,string list> option) =
            if x then errors
            else None
    let getFieldError errors field =
        errors |> Map.tryFind field |> Option.defaultValue []

    let validatePhone (phone: string) =

            let testerPhone phone =
                let exp = """(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})"""
                if System.Text.RegularExpressions.Regex(exp).Match(phone).Success then true
                else false

            all <| fun t ->
                        t.Test "Phone" phone
                        |> t.IsValid testerPhone ("The phone number is invalid.")
                        |> t.End


    let validationErrors (phone: string) validationState =
        let form = phone |> validatePhone
        let result =
            match form with
            | Ok x -> Some Map.empty
            | Error errors ->
                Some (
                    if validationState then errors
                    else Map.empty
                )
        result

    let validateEmail email =
        let testerEmail (email: string) =
            let res =
                if email.Contains("ä") || email.Contains("Ä") ||
                   email.Contains("ö") ||email.Contains("Ö") ||
                   email.Contains("å") || email.Contains("Å") then false
                else true
            res
        all <| fun t ->
            t.Test "Email" email
            |> t.IsMail "Email is invalid."
            |> t.IsValid testerEmail "Email is invalid."
            |> t.End
    let validationEmail email validationState =
        let form = email |> validateEmail
        let result =
            match form with
            | Ok x -> Some Map.empty
            | Error errors ->
                Some (
                    if validationState then errors
                    else Map.empty
                )
        result

    module Field =
        let errors (fieldName: string) errors =
            getFieldError errors fieldName

        let errorsAsString (fieldName: string)=
            errors fieldName >> List.fold (fun s x -> sprintf "%s %s" s x) ""

        let hasErrors (fieldName: string) =
            errors fieldName >> List.isEmpty >> not

module CallBooking =
    type DataForValidation = {
        CallBookingDate: System.DateTime option
        CallBookingTimeslotId: int option
    }

    let validateCase (form: DataForValidation) =
        match form.CallBookingDate, form.CallBookingTimeslotId with
        | Some _, Some _ -> Ok form
        | Some _, None -> Error (Map.empty.Add("CallBooking",["Call booking timeslot id is required"]))
        | None , Some _ -> Error (Map.empty.Add("CallBooking",["Call booking date is required"]))
        | _, _ -> Ok form
    let validationCase form needValidate =
        let form = form |> validateCase
        let result =
            match form with
            | Ok x -> Some Map.empty
            | Error errors ->
                Some (
                    if needValidate then errors
                    else Map.empty
                )
        result