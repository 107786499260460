namespace Case.Register

open System
open Common
open Shared.Errors
open Shared.Case

module Types =

    let t = Localization.ns ("registerCase")

    type SavedCaseId = Guid
    type Case = {
        Id: Guid
        CaseNumber: string
        DamageType: DamageType
        Address: Address option
        Inspector: Inspector option
        //VideoCallDate: DateTimeOffset option
        CallBookingDate: DateTime option
        CallBookingTimeslotId: int option
        InspectionDate: DateTimeOffset option
        DeviceId: string option
    }
    let Default (): Case = {
        Id = Guid.NewGuid()
        CaseNumber = ""; DamageType = Motor ""; Address = None
        Inspector = None; CallBookingDate = None;
        CallBookingTimeslotId = None;
        InspectionDate = None
        DeviceId = None
    }

    type State = {
        Case: Case
        Inspectors: Deferred<Result<Inspector list, string>>
        Registration: Deferred<Result<SavedCaseId, ServerError>>
        TimeSlots: Deferred<Result<TimeSlot list, string>>
        Errors: Map<string, string list>
        IsNeedValidation: bool
    }

    type Msg =
        | CaseIdChanged of string
        | CaseTypeChanged of DamageType
        | RegNumberChanged of string
        | AddressChanged of string
        | DeviceIdChanged of string
        | InspectorChanged of Inspector option
        | LoadTimeSlots of AsyncOperationStatus<Result<TimeSlot list, string>> * DateTime
        | CallBookingDateChanged of DateTime option
        | CallBookingTimeslotChanged of int option
        | InspectionDateChanged of DateTime option
        | LoadInspectors of AsyncOperationStatus<Result<Inspector list, string>>
        | RegisterCase of AsyncOperationStatus<Result<SavedCaseId, ServerError>>

    module CaseType =
        let toString = function
            | Construction _ -> "construction"
            | Motor _ -> "motor"
            | Prevention -> "prevention"
            | Other -> "other"
        let fromString = function
            | "construction" -> Construction
            | "motor" -> Motor ""
            | "prevention" -> Prevention
            | _ -> Other

module Cmd =
    open Types
    open Elmish
    open Communication

    let loadInspectors (): Cmd<Msg> =
        async {
            try
                let! inspectors = caseApi().inspectors() |> Remoting.handleNonAuth
                return LoadInspectors (Finished (Ok inspectors))
            with
                ex -> return LoadInspectors (Finished (Error ex.Message))
        }
        |> Cmd.fromAsync

    let loadTimeSlots d = async {
        try
            let! result = caseApi().getTimeSlots d |> Remoting.handleNonAuth
            return LoadTimeSlots (Finished (Ok result), d)
        with
            ex -> return LoadTimeSlots (Finished (Error ex.Message), d)
    }
    let register (state: State): Cmd<Msg> =
        async {
            try
                let callBooking =
                    match state.Case.CallBookingDate, state.Case.CallBookingTimeslotId with
                    | Some date, Some id ->
                        Some {
                            Date = date.AddHours(12.)
                            TimeSlotId = id
                        }
                    | _, _ -> None
                let case = {
                    Id = state.Case.Id
                    CaseNumber = state.Case.CaseNumber
                    DamageType = state.Case.DamageType
                    Address = state.Case.Address
                    Inspector = state.Case.Inspector
                    CallBooking = callBooking
                    InspectionDate = state.Case.InspectionDate
                    DeviceId = state.Case.DeviceId
                }
                match! caseApi().register case |> Remoting.handleNonAuth with
                | Ok _ -> return RegisterCase (Finished (Ok state.Case.Id))
                | Error er -> return RegisterCase (Finished (Error er))
            with
                ex -> return RegisterCase (Finished (Error (Exception ex.Message)))
        }
        |> Cmd.fromAsync

module State =
    open Types
    open Elmish

    let withCase case state = { state with Case = case }

    let init () =
        { Case = Default()
          Inspectors = HasNotStartedYet
          Registration = HasNotStartedYet
          TimeSlots = HasNotStartedYet
          Errors = Map.empty
          IsNeedValidation = false },
        Cmd.batch [
            Cmd.ofMsg (LoadInspectors Started)
            Events.AddressBook.subscribeToDeviceIdSelected DeviceIdChanged
        ]

    let update (msg: Msg) (state: State) =
        match msg with
        | CaseIdChanged caseId ->
            state |> withCase { state.Case with CaseNumber = caseId }, Cmd.none
        | CaseTypeChanged caseType ->
            state |> withCase { state.Case with DamageType = caseType }, Cmd.none
        | RegNumberChanged regNumber ->
            match state.Case.DamageType with
            | Motor _ ->
                state |> withCase { state.Case with DamageType = Motor regNumber }, Cmd.none
            | _ -> state, Cmd.none
        | AddressChanged address ->
            state |> withCase { state.Case with Address = Some { Description = address; Location = None } }, Cmd.none
        | DeviceIdChanged deviceId ->
            state |> withCase { state.Case with DeviceId = if deviceId.Length = 0 then None else Some deviceId }, Cmd.none
        | InspectorChanged inspector ->
            state |> withCase { state.Case with Inspector = inspector }, Cmd.none
        | LoadTimeSlots (Started, date) ->
            { state with TimeSlots = InProgress }, Cmd.fromAsync (Cmd.loadTimeSlots date)
        | LoadTimeSlots (Finished result, _) ->
            { state with TimeSlots = Resolved result }, Cmd.none
        | CallBookingDateChanged date ->
            let newState = state |> withCase { state.Case with CallBookingDate = date}
            let errors = CallBooking.validationCase
                             { CallBookingDate = newState.Case.CallBookingDate; CallBookingTimeslotId = newState.Case.CallBookingTimeslotId; }
                             newState.IsNeedValidation
            let cmd =
                match date with
                | Some date -> Cmd.ofMsg (LoadTimeSlots (Started, date))
                | None -> Cmd.none
            { newState with Errors = errors.Value }, cmd
        | CallBookingTimeslotChanged id ->
            let newState = state |> withCase { state.Case with CallBookingTimeslotId = id}
            let errors = CallBooking.validationCase
                             { CallBookingDate = newState.Case.CallBookingDate; CallBookingTimeslotId = newState.Case.CallBookingTimeslotId; }
                             newState.IsNeedValidation
            { newState with Errors = errors.Value }, Cmd.none
        | InspectionDateChanged date ->
            state |> withCase { state.Case with InspectionDate = date |> Option.map DateTimeOffset }, Cmd.none
        | LoadInspectors Started ->
            { state with Inspectors = InProgress }, Cmd.loadInspectors ()
        | LoadInspectors (Finished inspectors) ->
            { state with Inspectors = Resolved inspectors }, Cmd.none
        | RegisterCase Started ->
            let validationResult =
                CallBooking.validateCase { CallBookingDate = state.Case.CallBookingDate
                                           CallBookingTimeslotId = state.Case.CallBookingTimeslotId }
            match validationResult with
            | Ok _ -> { state with Registration = InProgress; Errors = Map.empty; IsNeedValidation = true }, Cmd.register state
            | Error er -> { state with Registration = HasNotStartedYet; Errors = er; IsNeedValidation = true }, Cmd.none
        | RegisterCase (Finished (Ok savedCaseId)) ->
            { state with Registration = Resolved (Ok savedCaseId) }, Router.navigateTo (Router.Route.CaseDetails (string savedCaseId))
        | RegisterCase (Finished (Error ex)) ->
            { state with Registration = Resolved (Error ex)}, Cmd.none

module View =
    open Types
    open Feliz
    open Feliz.Bulma
    open Feliz.ElmishComponents

    let renderToolbar (state: State) (dispatch: Msg -> unit) =
        Bulma.level [
            prop.children [
                Bulma.levelLeft [
                    Bulma.levelItem [
                        Bulma.title.h3 [
                            text.isUppercase
                            prop.text (t "case.report")
                        ]
                    ]
                ]
                Bulma.levelRight[
                    Bulma.levelItem [
                        Bulma.button.a [
                            Bulma.color.isPrimary
                            prop.className "button-shadow"
                            if state.Registration = InProgress then button.isLoading
                            prop.text (t "button.register")
                            prop.onClick (fun x -> x.preventDefault(); dispatch (RegisterCase Started))
                        ]
                    ]
                ]
            ]
        ]

    let row (label: string) (input: ReactElement list) =
        Bulma.columns [
            prop.style [
                style.marginBottom (length.rem 0.75)
            ]
            columns.isGapless
            columns.isVCentered
            prop.children [
                Bulma.column [
                    column.is4
                    Bulma.color.hasTextGrey
                    prop.text label
                ]
                Bulma.column [
                    prop.children input
                ]
            ]
        ]

    let renderRegistrationError (state: State) dispatch =
        match state.Registration with
        | HasNotStartedYet
        | InProgress
        | Resolved (Ok _) -> Html.none
        | Resolved (Error ex) ->
            Bulma.notification [
                color.isDanger
                prop.text (Components.Common.TranslatedErrors.ServerError.explainTranslation ex)
            ]

    let renderRegNumber (state: State) dispatch =
        match state.Case.DamageType with
        | Motor x ->
            row (t "case.regnumber")
                [
                    Bulma.input.text [
                        prop.valueOrDefault x
                        prop.onChange (RegNumberChanged >> dispatch)
                    ]
                ]
        | _ -> Html.none

    let renderForm (state: State) dispatch =
        let renderSelectOption (inspector: Inspector) =
            Html.option [
                prop.value inspector.Id
                prop.text inspector.Name
                prop.selected (state.Case.Inspector = Some inspector)
            ]
        Bulma.column [
            column.is6
            prop.style[style.paddingLeft 0]
            prop.children [
                renderRegistrationError state dispatch
                Html.form [
                    Html.fieldSet [
                        prop.disabled (state.Registration = InProgress)
                        prop.children [
                            row (t "case.number")
                                [
                                    Bulma.field.div [
                                        prop.children [
                                            Bulma.input.text [
                                                prop.valueOrDefault state.Case.CaseNumber
                                                prop.onChange (CaseIdChanged >> dispatch)
                                            ]
                                        ]
                                    ]
                                ]
                            row (t "case.damageType")
                                [
                                    Bulma.select [
                                        select.isFullWidth
                                        prop.children [
                                            Html.option [
                                                prop.value (CaseType.toString Construction)
                                                prop.text (t "case.damageConstruction")
                                            ]
                                            Html.option [
                                                prop.value (CaseType.toString (Motor ""))
                                                prop.text (t "case.damageMotor")
                                            ]
                                            Html.option [
                                                prop.value (CaseType.toString Prevention)
                                                prop.text (t "case.damagePrevention")
                                            ]
                                            Html.option [
                                                prop.value (CaseType.toString Other)
                                                prop.text (t "case.damageOther")
                                            ]
                                        ]
                                        prop.onChange (CaseType.fromString >> CaseTypeChanged >> dispatch)
                                        prop.value (CaseType.toString (state.Case.DamageType))
                                    ]
                                ]
//                            renderRegNumber state dispatch
//                            row (t "case.address") [
//                                Bulma.input.text [
//                                    prop.valueOrDefault (state.Case.Address |> Option.map (fun x -> x.Description) |> Option.defaultValue "")
//                                    prop.onChange (AddressChanged >> dispatch)
//                                ]
//                            ]
                            row (t "case.inspector")
                                [
                                    Bulma.select [
                                        select.isFullWidth
                                        prop.onChange (fun (id: string) ->
                                                state.Inspectors
                                                |> DeferredResult.tee
                                                   (fun x ->
                                                        x |> List.tryFind (fun y -> string y.Id = id)
                                                        |> InspectorChanged |> dispatch) |> ignore)
                                        prop.children
                                            (match state.Inspectors with
                                            | HasNotStartedYet
                                            | InProgress
                                            | Resolved (Error _) ->[]
                                            | Resolved (Ok inspectors) ->
                                                [for inspector in { Id = -1; Name = t "input.text.noInspectors"}::inspectors
                                                    do renderSelectOption inspector])
                                    ]
                                ]
                            Bulma.columns [
                                prop.style [
                                    style.marginBottom (length.rem 0.75)
                                ]
                                columns.isGapless
                                columns.isVCentered
                                prop.children [
                                    Bulma.column [
                                        column.is4
                                        Bulma.color.hasTextGrey
                                        prop.text (t "case.date")
                                    ]
                                    Bulma.column [
                                        Bulma.field.div[
                                            field.hasAddons
                                            prop.style [style.justifyContent.center]
                                            prop.children [
                                                Bulma.control.div[
                                                    prop.classes [BulmaCss.``is-expanded``]
                                                    prop.children [
                                                        Flatpickr.flatpickr [
                                                            Flatpickr.Id (sprintf "bookDate%s" (state.Case.Id.ToString()))
                                                            Flatpickr.DateFormat("M d Y")
                                                            Flatpickr.custom "value" state.Case.CallBookingDate false
                                                            Flatpickr.custom "locale" {|firstDayOfWeek= 1|} true
                                                            Flatpickr.OnChange (fun x -> dispatch (CallBookingDateChanged (Some x)); (*LoadTimeSlots x;*))
                                                            Flatpickr.ClassName "input"
                                                            if Localization.currentLanguage () = "se" then
                                                                Flatpickr.Locale FlatpickrExtensions.Locales.swedish
                                                        ]
                                                    ]
                                                ]

                                                Bulma.control.div [
                                                    Bulma.button.button [
                                                        Bulma.color.hasTextGrey
                                                        button.isOutlined
                                                        prop.text "Rensa"
                                                        prop.onClick(fun x ->
                                                            x.preventDefault()
                                                            dispatch (CallBookingDateChanged None);
                                                            dispatch (CallBookingTimeslotChanged None)
                                                        )
                                                    ]
                                                ]

                                            ]
                                        ]
                                    ]
                                ]
                            ]
                            match state.Case.CallBookingDate with
                            | None -> Html.none
                            | Some _ ->
                                match state.TimeSlots with
                                | HasNotStartedYet -> Html.none
                                | InProgress -> Components.Loader.view Components.Loader.Props.Default
                                | Resolved (Error error) -> Html.div error
                                | Resolved (Ok timeSlots) ->
                                    Bulma.columns [
                                        prop.style [
                                            style.marginBottom (length.rem 0.75)
                                        ]
                                        columns.isGapless
                                        prop.children [
                                            Bulma.column [
                                                column.is4
                                            ]
                                            Bulma.column [
                                                Bulma.buttons [
                                                    for timeSlot in timeSlots do
                                                        Bulma.button.button[
                                                            if state.Case.CallBookingTimeslotId = Some timeSlot.Id then
                                                                color.isPrimary
                                                                prop.style [style.color.white]
                                                            else
                                                                prop.style [style.backgroundColor.white]
                                                            if not timeSlot.IsAvailable then prop.disabled true
                                                            prop.text timeSlot.Time
                                                            prop.onClick(fun x ->
                                                                x.preventDefault()
                                                                match state.Case.CallBookingDate with
                                                                | Some _ -> dispatch (CallBookingTimeslotChanged (Some timeSlot.Id))
                                                                | None -> ()
                                                            )
                                                        ]
                                                ]
                                            ]
                                        ]
                                    ]
                                    Bulma.help [
                                        color.isDanger
                                        prop.style [style.alignContent.flexEnd]
                                        prop.text (state.Errors |> Common.Form.Field.errorsAsString "CallBooking")
                                    ]
//                            row (t "case.inspectionDate")
//                                [
//                                    Bulma.field.div[
//                                        field.hasAddons
//                                        prop.style [style.justifyContent.center]
//                                        prop.children [
//                                            Bulma.control.div[
//                                                prop.classes [BulmaCss.``is-expanded``]
//                                                prop.children [
//                                                    Flatpickr.flatpickr [
//                                                        Flatpickr.EnableTimePicker true
//                                                        Flatpickr.DateFormat("M d Y, H:i")
//                                                        Flatpickr.custom "value" state.Case.InspectionDate false
//                                                        Flatpickr.custom "locale" {|firstDayOfWeek= 1|} true
//                                                        Flatpickr.ClassName "input"
//                                                        Flatpickr.OnChange (fun x -> dispatch (InspectionDateChanged (Some x)))
//                                                        if Localization.currentLanguage () = "se" then
//                                                            Flatpickr.Locale FlatpickrExtensions.Locales.swedish
//                                                    ]
//                                                ]
//                                            ]
//
//                                            Bulma.control.div [
//                                                Bulma.button.button [
//                                                    Bulma.color.hasTextGrey
//                                                    button.isOutlined
//                                                    prop.text "Rensa"
//                                                    prop.onClick(fun x ->
//                                                        x.preventDefault();
//                                                        dispatch (InspectionDateChanged None)
//                                                    )
//                                                ]
//                                            ]
//                                        ]
//                                    ]
//                                ]
                            row (t "case.deviceId") [
                                Bulma.input.text [
                                    prop.valueOrDefault (state.Case.DeviceId |> Option.defaultValue "")
                                    prop.onChange (DeviceIdChanged >> dispatch)
                                ]
                            ]
                        ]
                    ]
                ]
            ]
        ]

    let render (state: State) dispatch =
        Html.div [
            Html.div [
                prop.classes [ AppCss.Toolbar ]
                prop.children [
                    Bulma.column [
                        column.is6
                        prop.style[style.paddingLeft 0]
                        prop.children [ renderToolbar state dispatch ]
                    ]
                ]
            ]
            renderForm state dispatch
        ]

    let registerCase () =
        React.elmishComponent("RegisterCase", State.init (), State.update, render)
