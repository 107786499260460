namespace Case.Edit

open System
open Components
open Router
open Shared.Case
open Shared.Errors

module Types =

    let t = Localization.ns("editCase")

    type Case = {
        Id: Guid
        CaseNumber: string
        DamageType: DamageType
        Address: Address option
        Inspector: Inspector option
        VideoCallDate: DateTimeOffset option
        CallBookingDate: DateTime option
        CallBookingTimeslotId: int option
        InspectionDate: DateTimeOffset option
        DeviceId: string option
    }
    type State = {
        Case: Deferred<Result<Case, string>>
        Inspectors: Deferred<Result<Inspector list, string>>
        IsSaveInProgress: bool
        TimeSlots: Deferred<Result<TimeSlot list, string>>
        Errors: Map<string, string list>
        IsNeedValidation: bool
    }

    type Msg =
        | CaseNumberChanged of string
        | CaseTypeChanged of DamageType
        | RegNumberChanged of string
        | AddressChanged of string
        | DeviceIdChanged of string
        | LoadTimeSlots of AsyncOperationStatus<Result<TimeSlot list, string>> * DateTime
        | CallBookingDateChanged of DateTime option
        | CallBookingTimeslotChanged of int option
        | InspectorChanged of Inspector option
        | InspectionDateChanged of DateTime
        | LoadCase of Guid * AsyncOperationStatus<Result<Case, string>>
        | LoadInspectors of AsyncOperationStatus<Result<Inspector list, string>>
        | SaveChanges of AsyncOperationStatus<Result<unit, string>>

    module CaseType =
        let toString = function
            | Construction _ -> "construction"
            | Motor _ -> "motor"
            | Prevention -> "prevention"
            | Other -> "other"
        let fromString regNumber = function
            | "construction" -> Construction
            | "prevention" -> Prevention
            | "motor" -> Motor regNumber
            | _ -> Other
        let toCase (x: Queries.Case) : Case =
            Browser.Dom.console.log("toCase. from Server x.CallBooking {CallBooking}",x.CallBooking)
            let date, timeSlotId =
                match x.CallBooking with
                | Some booking ->
                   Some booking.Date, Some booking.TimeSlotId
                | None -> None, None
            Browser.Dom.console.log("toCase. mapped {date}, {timeSlotId}",date, timeSlotId)
            {
                Id = x.Id
                CaseNumber = x.CaseNumber
                DamageType = x.DamageType
                Address = { Description = x.Address; Location = None } |> Some
                Inspector = x.Inspector
                VideoCallDate = x.VideoCallDate
                CallBookingDate = date;
                CallBookingTimeslotId = timeSlotId;
                InspectionDate = x.InspectionDate
                DeviceId = x.DeviceId
            }

    module DamageType =
        let regNumber = function
            | Motor x -> Some x
            | _ -> None

    [<AutoOpen>]
    module Utils =
        let (|CaseReady|_|) (state: State) =
            state.Case |> DeferredResult.fold Some (fun _ -> None) (fun _ -> None)

module Cmd =
    open Elmish
    open Types
    open Communication
    let loadInspectors (): Cmd<Msg> =
        async {
            try
                let! inspectors = caseApi().inspectors() |> Remoting.handleNonAuth
                return LoadInspectors (Finished (Ok inspectors))
            with
                ex -> return LoadInspectors (Finished (Error ex.Message))
        }
        |> Cmd.fromAsync

    let loadTimeSlots d = async {
        try
            let! result = caseApi().getTimeSlots d |> Remoting.handleNonAuth
            return LoadTimeSlots (Finished (Ok result), d)
        with
            ex -> return LoadTimeSlots (Finished (Error ex.Message), d)
    }

    let loadCase caseId : Cmd<Msg> =
        async {
            try
                let! case = caseApi().case caseId |> Remoting.handleNonAuth
                let case = case |> Result.map CaseType.toCase |> Result.mapError (Components.Common.TranslatedErrors.ServerError.explainTranslation)

                return LoadCase (caseId, Finished case)
            with
                ex -> return LoadCase (caseId, Finished (Error ex.Message))
        }
        |> Cmd.fromAsync

    let changeCase case : Cmd<Msg> =
        async {
            try
                let callBooking =
                    match case.CallBookingDate, case.CallBookingTimeslotId with
                    | Some date, Some id ->
                        Some {
                            Date = date.AddHours(12.)
                            TimeSlotId = id
                        }
                    | _, _ -> None
                let case = {
                    Id = case.Id
                    CaseNumber = case.CaseNumber
                    DamageType = case.DamageType
                    Address = case.Address
                    Inspector = case.Inspector
                    CallBooking = callBooking
                    InspectionDate = case.InspectionDate
                    DeviceId = case.DeviceId
                }
                let! res = caseApi().change case |> Remoting.handleNonAuth
                let res = res |> Result.mapError (Components.Common.TranslatedErrors.ServerError.explainTranslation)
                return SaveChanges (Finished res)
            with
                ex -> return SaveChanges (Finished (Error ex.Message))
        }
        |> Cmd.fromAsync
module State =
    open Types
    open Elmish
    open Extensions.View

    let withCase case state = { state with Case = Resolved (Ok case) }
    let mapRegNumber regNumber caseType  =
        match caseType with
        | Motor _ -> Motor regNumber
        | x -> x

    let init caseId =
        {
            Case = HasNotStartedYet;
            Inspectors = HasNotStartedYet;
            IsSaveInProgress = false
            TimeSlots = HasNotStartedYet
            Errors = Map.empty
            IsNeedValidation = false
        }, Cmd.batch [
            Cmd.ofMsg (LoadInspectors Started)
            Cmd.ofMsg (LoadCase (caseId, Started))
            Events.AddressBook.subscribeToDeviceIdSelected DeviceIdChanged
        ]

    let update (msg: Msg) (state: State) =
        match msg, state with
        | CaseNumberChanged caseNumber, CaseReady case ->
            state |> withCase { case with CaseNumber = caseNumber }, Cmd.none
        | CaseTypeChanged caseType, CaseReady case ->
            state |> withCase { case with DamageType = caseType }, Cmd.none
        | RegNumberChanged regNumber, CaseReady case  ->
            state |> withCase { case with DamageType = case.DamageType |> mapRegNumber regNumber }, Cmd.none
        | AddressChanged address, CaseReady case ->
            state |> withCase { case with Address = { Description = address; Location = None } |> Some }, Cmd.none
        | DeviceIdChanged deviceId, CaseReady case ->
            state |> withCase { case with DeviceId = if deviceId.Length = 0 then None else Some deviceId }, Cmd.none
        | LoadTimeSlots (Started, date), CaseReady case ->
            { state with TimeSlots = InProgress }, Cmd.fromAsync (Cmd.loadTimeSlots date)
        | LoadTimeSlots (Finished result, _), CaseReady case ->
            { state with TimeSlots = Resolved result }, Cmd.none
        | CallBookingDateChanged date, CaseReady case ->
            let newCase = { case with CallBookingDate = date}
            let newState = state |> withCase newCase
            let errors = Common.CallBooking.validationCase
                             { CallBookingDate = newCase.CallBookingDate; CallBookingTimeslotId = newCase.CallBookingTimeslotId; }
                             newState.IsNeedValidation
            let cmd =
                match date with
                | Some date -> Cmd.ofMsg (LoadTimeSlots (Started, date))
                | None -> Cmd.none
            { newState with Errors = errors.Value }, cmd
        | CallBookingTimeslotChanged id, CaseReady case ->
            let newCase = { case with CallBookingTimeslotId = id}
            let newState = state |> withCase newCase
            let errors = Common.CallBooking.validationCase
                             { CallBookingDate = newCase.CallBookingDate; CallBookingTimeslotId = newCase.CallBookingTimeslotId; }
                             newState.IsNeedValidation
            { newState with Errors = errors.Value }, Cmd.none
        | InspectorChanged inspector, CaseReady case ->
            state |> withCase { case with Inspector = inspector }, Cmd.none
        | InspectionDateChanged date, CaseReady case ->
            state |> withCase { case with InspectionDate = date |> DateTimeOffset |> Some }, Cmd.none
        | LoadInspectors Started, _ ->
            { state with Inspectors = InProgress }, Cmd.loadInspectors ()
        | LoadInspectors (Finished (Ok inspectors)), _ ->
            { state with Inspectors = Resolved (Ok inspectors) }, Cmd.none
        | LoadInspectors (Finished (Error e)), _ ->
            { state with Inspectors = Resolved (Error e) }, Cmd.toastServerError "Load inspectors" e
        | LoadCase (caseId, Started), state when state.Case = HasNotStartedYet ->
            { state with Case = InProgress }, Cmd.loadCase caseId
        | LoadCase (_, Finished (Ok case)), state when state.Case = InProgress ->
            let cmd =
                match case.CallBookingDate with
                | Some date -> Cmd.ofMsg (LoadTimeSlots (Started, date))
                | None -> Cmd.none
            { state with Case = Resolved (Ok case) }, cmd
        | LoadCase (_, Finished (Error e)), state when state.Case = InProgress ->
            { state with Case = Resolved (Error e) }, Cmd.toastServerError "Load case" e
        | SaveChanges Started, CaseReady case ->
            let validationResult =
                Common.CallBooking.validateCase { CallBookingDate = case.CallBookingDate
                                                  CallBookingTimeslotId = case.CallBookingTimeslotId }
            match validationResult with
            | Ok _ -> { state with IsSaveInProgress = true; Errors = Map.empty; IsNeedValidation = true }, Cmd.changeCase case
            | Error er -> { state with IsSaveInProgress = false; Errors = er; IsNeedValidation = true }, Cmd.none
        | SaveChanges (Finished (Ok _)), CaseReady case ->
            { state with IsSaveInProgress = false }, navigateTo (Route.CaseDetails (string case.Id))
        | SaveChanges (Finished (Error e)), CaseReady case ->
            { state with IsSaveInProgress = false }, Cmd.toastServerError "Save case" e
        | _, _ ->
            Browser.Dom.console.warn (sprintf "The combination of state and message is not expected", state, msg)
            state, Cmd.none

module View =
    open Types
    open Feliz
    open Feliz.Bulma
    open Feliz.ElmishComponents

    let renderToolbar (state: State) (dispatch: Msg -> unit) =
        Bulma.level [
            prop.children [
                Bulma.levelLeft [
                    Bulma.levelItem [
                        Bulma.title.h3 [
                            Bulma.text.isUppercase
                            prop.text (t "case.edit")
                        ]
                    ]
                ]
                Bulma.levelRight[
                    Bulma.levelItem [
                        Bulma.button.a [
                            Bulma.color.isPrimary
                            prop.className "button-shadow"
                            prop.disabled (state.Case |> DeferredResult.isError)
                            if state.IsSaveInProgress then Bulma.button.isLoading
                            prop.text (t "case.save")
                            prop.onClick (fun x -> x.preventDefault(); dispatch (SaveChanges Started))
                        ]
                    ]
                ]
            ]
        ]

    let row (label: string) (input: ReactElement list) =
        Bulma.columns [
            prop.style [
                style.marginBottom (length.rem 0.75)
            ]
            Bulma.columns.isGapless
            Bulma.columns.isVCentered
            prop.children [
                Bulma.column [
                    Bulma.column.is4
                    Bulma.color.hasTextGrey
                    prop.text label
                ]
                Bulma.column [
                    prop.children input
                ]
            ]
        ]

    let renderRegNumber (case: Case) dispatch =
        match case.DamageType with
        | Motor x ->
            row (t "case.regnumber")
                [
                    Bulma.input.text [
                        prop.valueOrDefault x
                        prop.onChange (RegNumberChanged >> dispatch)
                    ]
                ]
        | _ -> Html.none

    let renderEditCase (state: State) (case: Case) dispatch =
        let renderSelectOption (inspector: Inspector) =
            Html.option [
                prop.value inspector.Id
                prop.text inspector.Name
                prop.selected (case.Inspector = Some inspector)
            ]

        Bulma.column [
            Bulma.column.is6
            prop.style[style.paddingLeft 0]
            prop.children [
                Html.form [
                    Html.fieldSet [
                        prop.disabled state.IsSaveInProgress
                        prop.children [
                            row (t "case.number")
                                [
                                    Bulma.input.text [
                                        prop.valueOrDefault case.CaseNumber
                                        prop.onChange (CaseNumberChanged >> dispatch)
                                    ]
                                ]
                            row (t "case.damageType")
                                [
                                    Bulma.select [
                                        Bulma.select.isFullWidth
                                        prop.children [
                                            Html.option [
                                                prop.value (CaseType.toString Construction)
                                                prop.text (t "case.damageConstruction")
                                            ]
                                            Html.option [
                                                prop.value (CaseType.toString (Motor ""))
                                                prop.text (t "case.damageMotor")
                                            ]
                                            Html.option [
                                                prop.value (CaseType.toString Prevention)
                                                prop.text (t "case.damagePrevention")
                                            ]
                                            Html.option [
                                                prop.value (CaseType.toString Other)
                                                prop.text (t "case.damageOther")
                                            ]
                                        ]
                                        prop.onChange (CaseType.fromString "" >> CaseTypeChanged >> dispatch)
                                        prop.value (CaseType.toString (case.DamageType))
                                    ]
                                ]
//                            renderRegNumber case dispatch
//                            row (t "case.address") [
//                                Bulma.input.text [
//                                    prop.valueOrDefault (case.Address |> Option.map (fun x -> x.Description) |> Option.defaultValue "")
//                                    prop.onChange (AddressChanged >> dispatch)
//                                ]
//                            ]
                            row (t "case.inspector")
                                [
                                    Bulma.select [
                                        Bulma.select.isFullWidth
                                        prop.onChange (fun (id: string) ->
                                                state.Inspectors
                                                |> DeferredResult.tee
                                                   (fun x ->
                                                        x |> List.tryFind (fun y -> string y.Id = id)
                                                        |> InspectorChanged |> dispatch) |> ignore)
                                        prop.children
                                            (match state.Inspectors with
                                            | HasNotStartedYet
                                            | InProgress
                                            | Resolved (Error _) ->
                                                    case.Inspector
                                                    |> Option.map (fun x ->[renderSelectOption x])
                                                    |> Option.defaultValue []
                                            | Resolved (Ok inspectors) ->
                                                [for inspector in { Id = -1; Name = "Inte tilldelat"}::inspectors
                                                    do renderSelectOption inspector])
                                    ]
                                ]
                            Bulma.columns [
                                prop.style [
                                    style.marginBottom (length.rem 0.75)
                                ]
                                columns.isGapless
                                columns.isVCentered
                                prop.children [
                                    Bulma.column [
                                        column.is4
                                        Bulma.color.hasTextGrey
                                        prop.text (t "case.date")
                                    ]
                                    Bulma.column [
                                        Bulma.field.div[
                                            field.hasAddons
                                            prop.style [style.justifyContent.center]
                                            prop.children [
                                                Bulma.control.div[
                                                    prop.classes [BulmaCss.``is-expanded``]
                                                    prop.children [
                                                        Flatpickr.flatpickr [
                                                            Flatpickr.DateFormat("M d Y")
                                                            Flatpickr.custom "value" case.CallBookingDate false
                                                            Flatpickr.custom "locale" {|firstDayOfWeek= 1|} true
                                                            Flatpickr.OnChange (fun x -> dispatch (CallBookingDateChanged (Some x)); (*LoadTimeSlots x;*))
                                                            Flatpickr.ClassName "input"
                                                            if Localization.currentLanguage () = "se" then
                                                                Flatpickr.Locale FlatpickrExtensions.Locales.swedish
                                                        ]
                                                    ]
                                                ]

                                                Bulma.control.div [
                                                    Bulma.button.button [
                                                        Bulma.color.hasTextGrey
                                                        button.isOutlined
                                                        prop.text "Rensa"
                                                        prop.onClick(fun x ->
                                                            x.preventDefault()
                                                            dispatch (CallBookingDateChanged None);
                                                            dispatch (CallBookingTimeslotChanged None)
                                                        )
                                                    ]
                                                ]

                                            ]
                                        ]
                                    ]
                                ]
                            ]
                            match case.CallBookingDate with
                            | None -> Html.none
                            | Some _ ->
                                match state.TimeSlots with
                                | HasNotStartedYet -> Html.none
                                | InProgress -> Components.Loader.view Components.Loader.Props.Default
                                | Resolved (Error error) -> Html.div error
                                | Resolved (Ok timeSlots) ->
                                    Browser.Dom.console.log("state.TimeSlots timeSlots {timeSlots}",timeSlots)
                                    Bulma.columns [
                                        prop.style [
                                            style.marginBottom (length.rem 0.75)
                                        ]
                                        columns.isGapless
                                        prop.children [
                                            Bulma.column [
                                                column.is4
                                            ]
                                            Bulma.column [
                                                Bulma.buttons [
                                                    for timeSlot in timeSlots do
                                                        Bulma.button.button[
                                                            if case.CallBookingTimeslotId = Some timeSlot.Id then
                                                                color.isPrimary
                                                                prop.style [style.color.white]
                                                            else
                                                                prop.style [style.backgroundColor.white]
                                                            if not timeSlot.IsAvailable then prop.disabled true
                                                            prop.text timeSlot.Time
                                                            prop.onClick(fun x ->
                                                                x.preventDefault()
                                                                match case.CallBookingDate with
                                                                | Some _ -> dispatch (CallBookingTimeslotChanged (Some timeSlot.Id))
                                                                | None -> ()
                                                            )
                                                        ]
                                                ]
                                            ]
                                        ]
                                    ]
                                    Bulma.help [
                                        color.isDanger
                                        prop.style [style.alignContent.flexEnd]
                                        prop.text (state.Errors |> Common.Form.Field.errorsAsString "CallBooking")
                                    ]
//                            row (t "case.inspectionDate")
//                                [
//                                    Flatpickr.flatpickr [
//                                        Flatpickr.EnableTimePicker true
//                                        Flatpickr.DateFormat("M d Y, H:i")
//                                        Flatpickr.custom "locale" {|firstDayOfWeek= 1|} true
//                                        Flatpickr.ClassName "input"
//                                        Flatpickr.OnChange (InspectionDateChanged >> dispatch)
//                                        if Localization.currentLanguage () = "se" then
//                                            Flatpickr.Locale FlatpickrExtensions.Locales.swedish
//                                        match case.InspectionDate with
//                                        | Some date -> Flatpickr.Value date.LocalDateTime
//                                        | None -> ()
//                                    ]
//                                ]
                            row (t "case.deviceId") [
                                Bulma.input.text [
                                    prop.valueOrDefault (case.DeviceId |> Option.defaultValue "")
                                    prop.onChange (DeviceIdChanged >> dispatch)
                                ]
                            ]
                        ]
                    ]
                ]
            ]
        ]

    let renderForm (state: State) dispatch =
        match state.Case with
        | HasNotStartedYet
        | InProgress -> Loader.medium (t "case.loadingCase")
        | Resolved (Ok case) -> renderEditCase state case dispatch
        | Resolved (Error ex) -> Html.none

    let render (state: State) dispatch =
        Html.div [
            Html.div [
                prop.classes[AppCss.Toolbar]
                prop.children[
                    Bulma.column [
                        column.is6
                        prop.style[style.paddingLeft 0]
                        prop.children [ renderToolbar state dispatch ]
                    ]
                ]
            ]
            renderForm state dispatch
        ]

    let editCase caseId =
        React.elmishComponent("EditCase", State.init caseId, State.update, render)