module Recording

open System
open Elmish
open Components
open Shared.Errors

let t = Localization.ns("recordingCall")

module Types =
    type ChannelState = Channel

    type Permission = Allowed | Denied
    type LiveState = {
        Context: Shared.VideoCall.Context
        Resource: Shared.VideoCall.Resource
        Start: DateTimeOffset
    }

    type VideoRecord =
        | RequestRecord of Deferred<Result<Permission, string>>
        | Recording of Deferred<Result<LiveState, string>>
        | Stop of LiveState * Deferred<Result<TimeSpan, string>>

    type State = {
        Context: Shared.VideoCall.Context
        Channel: Rtm.Chat
        Video: VideoRecord
    }

    type Msg =
        | RequestRecordPermission of AsyncOperationStatus<Result<unit, string>>
        | OnMessageReceived of string
        | StartRecording of AsyncOperationStatus<Result<Shared.VideoCall.Context * Shared.VideoCall.Resource, string>>
        | StopRecording of LiveState * AsyncOperationStatus<Result<TimeSpan, string>>

    [<AutoOpen>]
    module Utils =
        let (|AllowedRecord|_|) (state: State) =
            match state.Video with
            | RequestRecord (Resolved (Ok Allowed)) -> Some state
            | _ -> None

        let (|StartingRecording|_|) (state: State) =
            match state.Video with
            | Recording InProgress -> Some state
            | _ -> None

        let (|CanStopRecording|_|) (state: State) =
            match state.Video with
            | Recording (Resolved (Ok _)) -> Some state
            | Stop (_, Resolved (Error _)) -> Some state
            | _ -> None
        let (|StoppingRecording|_|) (state: State) =
            match state.Video with
            | Stop (_, InProgress) -> Some state
            | _ -> None
    [<AutoOpen>]
    module MessageParser =
        let (|Coordinates_Reported|_|) (msg: string) : Shared.VideoCall.Coordinates option =
            match String.tokenize msg with
            | [|cmd; lat; long|] when String.toUpperInvariant cmd = "COORDINATES_REPORTED" -> Some (Coordinates_Reported ({Latitude = decimal lat; Longitude = decimal long}))
            | _ -> None
module Cmd =
    open Types
    open Communication

    let requestRecord (x: State) : Cmd<Msg> =
        async {
            try
                x.Channel.SendMessage("REQUEST_VIDEO_RECORD")
                return RequestRecordPermission (Finished (Ok ()))
            with
                ex -> return RequestRecordPermission (Finished (Error ex.Message))
        }
        |> Cmd.fromAsync

    let start (s: State) : Cmd<Msg> =
        async {
            try
                match! recordingApi().start s.Context |> Remoting.handleNonAuth with
                | Ok (context, resource) ->
                    s.Channel.SendMessage("RECORDING_STARTED")
                    return StartRecording (Finished (Ok (context, resource)))
                | Error ex ->
                    return StartRecording (Finished (Error (ServerError.explain ex)))
            with
                ex -> return StartRecording (Finished (Error ex.Message))
        }
        |> Cmd.fromAsync

    let stop (liveState: LiveState) (s: State) : Cmd<Msg> =
        let context : Shared.VideoCall.Context =
            { CaseId = liveState.Context.CaseId
              ChannelId = liveState.Context.ChannelId
              AgentUID = liveState.Context.AgentUID
              GpsCoordinates = s.Context.GpsCoordinates }
        async {
            try
                match! recordingApi().stop (context, liveState.Resource) |> Remoting.handleNonAuth with
                | Ok ()->
                    s.Channel.SendMessage("RECORDING_STOPPED")
                    return StopRecording (liveState, Finished (Ok (DateTimeOffset.Now - liveState.Start)))
                | Error ex ->
                    return StopRecording (liveState, Finished (Error (ServerError.explain ex)))
            with
                ex -> return StopRecording (liveState, Finished (Error ex.Message))
        }
        |> Cmd.fromAsync

module State =
    open Types
    open Extensions.View

    let init context rtmChannel =
        let state = {
            Context = context
            Channel = rtmChannel
            Video = RequestRecord HasNotStartedYet
        }
        let onMessageReceived dispatch = rtmChannel.OnMessage.Add(OnMessageReceived >> dispatch)
        state, Cmd.ofSub onMessageReceived

    let update (msg: Msg) (state: State) =
        match msg, state with
        | RequestRecordPermission Started, x ->
            { x with Video = RequestRecord InProgress }, Cmd.requestRecord x
        | RequestRecordPermission (Finished (Ok _)), s ->
            { s with Video = RequestRecord InProgress }, Cmd.none
        | RequestRecordPermission (Finished (Error ex)), s ->
            { s with Video = RequestRecord (Resolved (Error ex)) }, Cmd.none
        | OnMessageReceived message, s ->
            let newState, cmd =
                match message.ToUpperInvariant() with
                | "RECORD_ALLOWED" ->
                    { s with Video = RequestRecord (Resolved (Ok Allowed)) }, Cmd.toastSuccess (t "recoring.video.allowed")
                | "RECORD_DENIED" ->
                    { s with Video = RequestRecord (Resolved (Ok Denied)) }, Cmd.toastError (t "recording.denied")
                | Coordinates_Reported (coords) ->
                    let coor : Shared.VideoCall.Coordinates = {Latitude = coords.Latitude; Longitude = coords.Longitude }
                    {s with Context = {CaseId = state.Context.CaseId; ChannelId = state.Context.ChannelId; AgentUID = state.Context.AgentUID; GpsCoordinates = Some coor}}, Cmd.none
                | _ ->
                    Browser.Dom.console.warn (sprintf "Unknown message arrived. Will be ignored.", message)
                    s, Cmd.none
            newState, cmd
        | StartRecording Started, AllowedRecord s ->
            { s with Video = Recording InProgress }, Cmd.start s
        | StartRecording (Finished (Ok (context, resource))), StartingRecording s ->
            let liveState = { Context = context; Resource = resource; Start = DateTimeOffset.Now }
            { s with Video = Recording (Resolved (Ok liveState)) }, Cmd.toastSuccess (t "recording.started")
        | StartRecording (Finished (Error x)), StartingRecording s ->
            { s with Video = Recording (Resolved (Error x)) }, Cmd.none
        | StopRecording (liveState, Started), CanStopRecording s ->
            { s with Video = Stop (liveState, InProgress) }, Cmd.stop liveState s
        | StopRecording (liveState, Finished (Ok x)), StoppingRecording s ->
            { s with Video = Stop (liveState, Resolved (Ok x)) }, Cmd.toastSuccess (t "recording.successfull")
        | StopRecording (liveState, Finished (Error x)), StoppingRecording s ->
            { s with Video = Stop (liveState, Resolved (Error x))}, Cmd.none
        | _, _ ->
            Browser.Dom.console.warn (sprintf "The combination of state and message is not expected", state, msg)
            state, Cmd.none

module View =
    open Types
    open Feliz
    open Feliz.Bulma

    let videoIcon = Bulma.icon [ Html.i [ prop.className [ MdiCss.Mdi; MdiCss.Mdi24Px; MdiCss.MdiVideo ] ] ]
    let view (state: State) dispatch =
        match state.Video with
        | RequestRecord HasNotStartedYet ->
            Bulma.button.button [
                color.isPrimary
                prop.onClick (fun x -> x.preventDefault(); dispatch (RequestRecordPermission Started))
                prop.children [
                    videoIcon
                    Html.span (t "recording.video.permission")
                ]
            ]
        | RequestRecord InProgress ->
            Bulma.button.button [
                button.isLoading
                color.isPrimary
                prop.text "Requesting"
            ]
        | RequestRecord (Resolved (Ok Allowed)) ->
            Bulma.button.button [
                color.isSuccess
                prop.onClick (fun x -> x.preventDefault(); dispatch (StartRecording Started))
                prop.children [
                    videoIcon
                    Html.span (t "recording.start")
                ]
            ]
        | RequestRecord (Resolved (Ok Denied)) ->
            Bulma.button.button [
                color.isPrimary
                prop.onClick (fun x -> x.preventDefault(); dispatch (RequestRecordPermission Started))
                prop.children [
                    videoIcon
                    Html.span (t "recording.request")
                ]
            ]
        | RequestRecord (Resolved (Error _)) ->
            Bulma.button.button [
                color.isWarning
                prop.onClick (fun x -> x.preventDefault(); dispatch (RequestRecordPermission Started))
                prop.children [
                    videoIcon
                    Html.span "Failed request permission. Request again?"
                ]
            ]
        | Recording HasNotStartedYet
        | Recording InProgress ->
            Bulma.button.button [
                button.isLoading
                color.isPrimary
                prop.text "Starting"
            ]
        | Recording (Resolved (Ok s)) ->
            Bulma.field.div [
                field.hasAddons
                prop.children [
                    Bulma.control.p [
                        Bulma.button.button [
                            prop.children [
                                Timer.reactComponent { Start = s.Start }
                            ]
                        ]
                    ]
                    Bulma.control.p [
                        Bulma.button.button [
                            prop.children [
                                Bulma.icon [
                                    Html.i [
                                        prop.className [  MdiCss.Mdi; MdiCss.Mdi24Px; MdiCss.MdiStop  ]
                                    ]
                                ]
                            ]
                            color.isDanger
                            prop.onClick (fun x -> x.preventDefault(); dispatch (StopRecording (s, Started)))
                        ]
                    ]
                ]
            ]
        | Recording (Resolved (Error _)) ->
            Bulma.button.button [
                prop.text "Error start recording. Try again?"
                color.isWarning
                prop.onClick (fun x -> x.preventDefault(); dispatch (StartRecording Started))
            ]
        | Stop (_, HasNotStartedYet)
        | Stop (_, InProgress) ->
            Bulma.button.button [
                button.isLoading
                color.isPrimary
                prop.text "Stopping"
            ]
        | Stop (_, Resolved (Ok _)) ->
            Bulma.button.button [
                color.isPrimary
                prop.onClick (fun x -> x.preventDefault(); dispatch (RequestRecordPermission Started))
                prop.children [
                    videoIcon
                    Html.span (t "recording.video.permission")
                ]
            ]
        | Stop (liveState, Resolved (Error _)) ->
            Bulma.button.button [
                color.isWarning
                prop.onClick (fun x -> x.preventDefault(); dispatch (StopRecording (liveState, Started)))
                prop.children [
                    videoIcon
                    Html.span "Failed stop recording. Try again?"
                ]
            ]

module Component =
    open Feliz
    open Feliz.UseElmish
    open State
    open View

    type RecordingProps = {
        Context: Shared.VideoCall.Context
        RtmChat: Rtm.Chat
    }

    let recording = React.functionComponent(fun (props: RecordingProps) ->
        let state, dispatch = React.useElmish(init props.Context props.RtmChat, update, [||])
        view state dispatch
    )
