module Communication
    open Fable.Remoting.Client
    open Shared
    open Shared.Case
    open Shared.Users
    open Shared.VideoCall

    let private token() = sprintf "Bearer %s" (LocalStorage.getToken())

    let authApi () : Auth.SignInApi =
      Remoting.createApi()
      |> Remoting.withRouteBuilder Route.builder
      |> Remoting.buildProxy<Auth.SignInApi>

    let caseApi() : CaseApi =
        Remoting.createApi()
        |> Remoting.withRouteBuilder Route.builder
        |> Remoting.withAuthorizationHeader (token())
        |> Remoting.buildProxy<CaseApi>

    let recordingApi() : RecordingApi =
        Remoting.createApi()
        |> Remoting.withRouteBuilder Route.builder
        |> Remoting.withAuthorizationHeader (token())
        |> Remoting.buildProxy<RecordingApi>

    let usersApi() : UserApi =
        Remoting.createApi()
        |> Remoting.withRouteBuilder Route.builder
        |> Remoting.withAuthorizationHeader (token())
        |> Remoting.buildProxy<UserApi>

    let addressBookApi() : AddressBook.AddressBookApi =
        Remoting.createApi()
        |> Remoting.withRouteBuilder Route.builder
        |> Remoting.withAuthorizationHeader (token())
        |> Remoting.buildProxy<AddressBook.AddressBookApi>
    let sendingSmsApi() =
        Remoting.createApi()
        |> Remoting.withAuthorizationHeader (token())
        |> Remoting.withRouteBuilder Route.builder
        |> Remoting.buildProxy<SendingSms.SendingApi>