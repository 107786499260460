module AddressBook

open System
open Shared

let localize = Localization.i18n.ns("addressBook")

module AddrBookShow =

    open Elmish
    open Router
    open Shared.AddressBook
    open Extensions.View

    type DeleteContactResult = {
        Contact: Contact
        Result: ConfirmedOperation<Result<unit, string>>
    }

    type DeleteGroupResult = {
        Group: Group
        Result: ConfirmedOperation<Result<unit, string>>
    }

    type State = {
        IsVisible: bool
        DeviceId: string
        SelectedCaseId: string option
        LoadedCases : Deferred<Result<Shared.Case.Queries.Case list, string>>
        MenuOpened: bool
        AddressBook: Deferred<Result<AddressBook, string>>
        CollapsedGroups: Map<Guid, bool>
        OpenedEditContact: Contact option
        OpenedEditGroup: Group option
        OpenedAddContact: bool
        OpenedAddGroup: bool
        DeleteContactResult: DeleteContactResult option
        DeleteGroupResult: DeleteGroupResult option
        GroupOfUngroupedContacts: Group
    }

    type Msg =
        | Close
        | DeviceIdChanged of string
        | CaseIdSelected of string option
        | LoadCases of AsyncOperationStatus<Result<Shared.Case.Queries.Case list, string>>
        | LoadAddressBook of AsyncOperationStatus<Result<AddressBook,string>>
        | ToggleGroup of Group
        | OpenMenu
        | CloseMenu
        | DeleteContact of Contact * AsyncConfirmOperationStatus<Result<unit, string>>
        | DeleteGroup of Group * AsyncConfirmOperationStatus<Result<unit, string>>
        | EditContact of Contact
        | EditGroup of Group
        | AddContact
        | AddGroup
        | CloseEditWindow

    module Cmd =
        let deleteContact (contact: Contact) : Cmd<Msg> =
            async {
                try
                    let! result = Communication.addressBookApi().deleteContact contact.Id |> Remoting.handleNonAuth
                    let result = result |> Result.mapError (Components.Common.TranslatedErrors.ServerError.explainTranslation)
                    return DeleteContact (contact, ConfirmationFinished result)
                with
                    ex -> return DeleteContact (contact, ConfirmationFinished (Error ex.Message))
            }
            |> Cmd.fromAsync

        let deleteGroup (group: Group) : Cmd<Msg> =
            async {
                try
                    let! result = Communication.addressBookApi().deleteGroup group.Id |> Remoting.handleNonAuth
                    let result = result |> Result.mapError (Components.Common.TranslatedErrors.ServerError.explainTranslation)
                    return DeleteGroup (group, ConfirmationFinished result)
                with
                    ex -> return DeleteGroup (group, ConfirmationFinished (Error ex.Message))
            }
            |> Cmd.fromAsync

        let loadAddressBook : Cmd<Msg> =
            async {
                try
                    let! x = Communication.addressBookApi().addressBook () |> Remoting.handleNonAuth
                    return LoadAddressBook (Finished (Ok x))
                with
                    ex -> return LoadAddressBook (Finished (Error ex.Message))
            }
            |> Cmd.fromAsync

        let notifyDeviceIdChanged (deviceId: string) =
            Events.AddressBook.notifyDeviceIdSelected deviceId
    module State =
        let init() =
            {
                IsVisible = true
                SelectedCaseId = None
                DeviceId = ""
                AddressBook = HasNotStartedYet
                LoadedCases = HasNotStartedYet
                MenuOpened = false
                CollapsedGroups = Map.empty
                OpenedEditContact = None
                OpenedEditGroup = None
                OpenedAddContact = false
                OpenedAddGroup = false
                DeleteContactResult = None
                DeleteGroupResult = None
                GroupOfUngroupedContacts = { Name = localize.translate "group.ungroupedContacts"; Id = Guid.NewGuid()}
            }, Cmd.loadAddressBook

        let update (hiddenMode: unit -> unit)(msg: Msg) (state: State) =
            match msg with
            | Close ->
                hiddenMode()
                {state with IsVisible = false}, Cmd.none
            | CloseEditWindow ->
                {state with
                    OpenedEditContact = None
                    OpenedEditGroup = None
                    OpenedAddContact = false
                    OpenedAddGroup = false
                    MenuOpened = false}, Cmd.loadAddressBook
            | DeviceIdChanged deviceId ->
                Cmd.notifyDeviceIdChanged deviceId
                { state with DeviceId = deviceId.Substring(0, 10) }, Cmd.none
            | CaseIdSelected case -> { state with SelectedCaseId = case }, Cmd.none
            | LoadCases Started -> { state with LoadedCases = InProgress }, Cmd.none
            | LoadCases (Finished cases) -> { state with LoadedCases = Resolved cases }, Cmd.none
            | LoadAddressBook Started -> { state with AddressBook = InProgress}, Cmd.none
            | LoadAddressBook (Finished groups) -> {state with AddressBook = Resolved groups}, Cmd.none
            | ToggleGroup group ->
                let shouldGroupBeCollapsed =
                    state.CollapsedGroups |> Map.tryFind group.Id |> Option.map not |> Option.defaultValue false
                {state with CollapsedGroups = state.CollapsedGroups |> Map.add group.Id shouldGroupBeCollapsed}, Cmd.none
            | OpenMenu ->
                {state with MenuOpened = true}, Cmd.none
            | CloseMenu ->
                {state with MenuOpened = false}, Cmd.none
            | EditContact contact -> {state with OpenedEditContact = Some contact}, Cmd.none
            | EditGroup group -> {state with OpenedEditGroup = Some group}, Cmd.none
            | AddContact -> {state with OpenedAddContact = true}, Cmd.none
            | AddGroup -> {state with OpenedAddGroup = true}, Cmd.none
            | DeleteContact (contact, ConfirmationStarted) ->
                { state with DeleteContactResult = Some { Contact = contact; Result = Requested} }, Cmd.none
            | DeleteContact (contact, Canceled) ->
                { state with DeleteContactResult = None }, Cmd.none
            | DeleteContact (contact, Confirmed) ->
                { state with DeleteContactResult = Some { Contact = contact; Result = ConfirmationInProgress } }, Cmd.deleteContact contact
            | DeleteContact (contact, ConfirmationFinished (Ok _)) ->
                { state with
                    DeleteContactResult = Some { Contact = contact; Result = Done (Ok ()) }
                    AddressBook = HasNotStartedYet }, Cmd.loadAddressBook
            | DeleteContact (contact, ConfirmationFinished (Error error)) ->
                { state with DeleteContactResult = Some { Contact = contact; Result = Done (Error error) } },
                Cmd.toastServerError "Delete contact" error
            | DeleteGroup (group, ConfirmationStarted) ->
                { state with DeleteGroupResult = Some { Group = group; Result = Requested} }, Cmd.none
            | DeleteGroup (group, Canceled) ->
                { state with DeleteGroupResult = None }, Cmd.none
            | DeleteGroup (group, Confirmed) ->
                { state with DeleteGroupResult = Some { Group = group; Result = ConfirmationInProgress } }, Cmd.deleteGroup group
            | DeleteGroup (group, ConfirmationFinished (Ok _)) ->
                { state with
                    DeleteGroupResult = Some { Group = group; Result = Done (Ok ()) }
                    AddressBook = HasNotStartedYet }, Cmd.loadAddressBook
            | DeleteGroup (group, ConfirmationFinished (Error error)) ->
                { state with DeleteGroupResult = Some { Group = group; Result = Done (Error error) } },
                Cmd.toastServerError "Delete group" error

    module View =
        open Feliz
        open Feliz.ElmishComponents
        open Feliz.Bulma
        open Feliz.Bulma.QuickView
        open State

        open Browser.Types

        module SearchCase =
            open Components
            open Communication
            open Extensions
            open Shared.Case

            let private toKeyValue (x: Queries.Case) = KeyValuePair.create (x.Id.ToString()) x.CaseNumber

            let cmd search : Async<Result<Autocomplete.FoundResult, string>> = async {
                try
                    let! result = caseApi().searchCases search
                    return Ok { Total = result.Total
                                Items = result.Items |> List.map toKeyValue }
                with
                    ex -> return Error ex.Message
            }

            let searchCase = React.functionComponent(fun (props: {| OnSelectCaseId: string option -> unit |}) ->
                let autocompleteProps: Autocomplete.Props =
                    {
                        Autocomplete.Props.Default
                        with
                            Search = cmd
                            OnSelect = props.OnSelectCaseId
                            Localization = {
                                Placeholder = localize.translate "search.case.placeholder"
                                StartTypeToSearch = fun () -> localize.translate "search.case.startTypeToSearch"
                                NoItemsFound = fun () -> localize.translate "search.case.noItemsFound"
                                FoundItems = fun found total ->
                                    localize.translate ("search.case.info", {| found = string found; total = string total |})
                            }
                        }

                Autocomplete.autocomplete autocompleteProps
            )

        //ContextMenu for edit delete
        let ContextMenu = React.functionComponent (fun (props: {|onEdit: unit-> unit; onDelete: unit -> unit; IsContact: bool|}) ->
            let visible, setVisible = React.useState(false)

            let attachEvent (f: Event->unit) (node: Node) (eventType: string) =
                node.addEventListener(eventType, f)
                { new System.IDisposable with
                    member __.Dispose() = node.removeEventListener(eventType, f) }

            let editRef = React.useElementRef()
            let deleteRef = React.useElementRef()
            let selfRef = React.useElementRef()
            React.useEffect((fun () ->
                (Browser.Dom.document, "mousedown") ||> attachEvent (fun ev ->
                    let menuEl: HTMLElement = selfRef.current.Value
                    if not(menuEl.contains(ev.target :?> _)) then setVisible(false)
                    let editEl: HTMLElement = editRef.current.Value
                    let deleteEl: HTMLElement = deleteRef.current.Value
                    if ((editEl.contains(ev.target :?> _)) || (deleteEl.contains(ev.target :?> _))) then setVisible(true)
                    )
            ), [||])
            Bulma.dropdown[
                if visible then dropdown.isActive
                prop.classes[BulmaCss.``is-right``]
                prop.children[
                    Bulma.dropdownTrigger[
                        Bulma.icon[
                                if props.IsContact then
                                    prop.classes [AppCss.ShowIcons]
                                    prop.style[
                                        style.marginLeft 3
                                        style.marginRight 3
                                        style.marginTop -2
                                    ]
                                prop.children[
                                    Html.i[
                                        prop.ref selfRef
                                        prop.classes[MdiCss.Mdi; MdiCss.Mdi18Px; MdiCss.MdiDotsVerticalCircleOutline; AppCss.ClickableItem]
                                        prop.style[style.opacity 0.25]
                                        prop.onClick(fun _ -> setVisible(not visible))
                                    ]
                                ]
                            ]
                    ]
                    Bulma.dropdownMenu[
                        prop.style[
                            style.textAlign.left
                            style.width 120
                            style.padding 0
                            style.marginRight 25
                        ]
                        prop.children[
                            Bulma.dropdownContent[
                                prop.style [style.padding 0]
                                prop.children[
                                    Html.div [
                                        prop.ref editRef
                                        prop.classes [AppCss.ClickableItem]
                                        prop.style[
                                            style.padding 15
                                            ]
                                        prop.text (localize.translate "contextMenu.edit")
                                        prop.onClick (fun _ -> props.onEdit())
                                    ]
                                    Html.hr[
                                        prop.classes[BulmaCss.``dropdown-divider``]
                                        prop.style[
                                            style.marginLeft 15
                                            style.marginRight 15
                                            style.marginTop 0
                                            style.marginBottom 0
                                        ]
                                    ]
                                    Html.div [
                                        prop.ref deleteRef
                                        prop.classes [AppCss.ClickableItem]
                                        prop.style[
                                            style.padding 15
                                            style.color "#D64D22"
                                        ]
                                        prop.text (localize.translate "contextMenu.delete")
                                        prop.onClick (fun _ -> props.onDelete())
                                    ]
                                ]
                            ]
                        ]
                    ]
                ]
            ]
        )

        //ContextMenu for add contact and add group
        let ContextMenuAddMode = React.functionComponent (fun (props: {|addContact: unit-> unit; addGroup: unit -> unit|}) ->
            let visible, setVisible = React.useState(false)

            let attachEvent (f: Event->unit) (node: Node) (eventType: string) =
                node.addEventListener(eventType, f)
                { new System.IDisposable with
                    member __.Dispose() = node.removeEventListener(eventType, f) }

            let addContactRef = React.useElementRef()
            let addGroupRef = React.useElementRef()
            let selfRef = React.useElementRef()
            React.useEffect((fun () ->
                (Browser.Dom.document, "mousedown") ||> attachEvent (fun ev ->
                    let menuEl: HTMLElement = selfRef.current.Value
                    if not(menuEl.contains(ev.target :?> _)) then setVisible(false)
                    let addContactEl: HTMLElement = addContactRef.current.Value
                    let addGroupEl: HTMLElement = addGroupRef.current.Value
                    if ((addContactEl.contains(ev.target :?> _)) || (addGroupEl.contains(ev.target :?> _))) then setVisible(true)
                    )
            ), [||])
            Bulma.dropdown[
                if visible then dropdown.isActive
                prop.classes[BulmaCss.``is-right``]
                prop.children[
                    Bulma.dropdownTrigger[
                        Bulma.button.a [
                            prop.style[ style.backgroundColor.transparent]
                            prop.children[
                                Bulma.icon[
                                    Html.i[
                                        prop.ref selfRef
                                        prop.classes[MdiCss.Mdi; MdiCss.Mdi24Px; MdiCss.MdiPlus]
                                        prop.style[style.color "#3572BE"]
                                    ]
                                ]
                            ]
                            prop.onClick(fun _ -> setVisible(not visible))
                            prop.target "_blank"
                        ]
                    ]
                    Bulma.dropdownMenu[
                        prop.style[
                            style.textAlign.left
                            style.width 160
                            style.padding 0
                            style.marginRight 25
                            style.fontWeight 500
                        ]
                        prop.children[
                            Bulma.dropdownContent[
                                prop.style [style.padding 0]
                                prop.children[
                                    Html.div [
                                        prop.ref addContactRef
                                        prop.classes [AppCss.ClickableItem]
                                        prop.style[
                                            style.padding 15
                                            ]
                                        prop.text (localize.translate "contextMenu.newContact")
                                        prop.onClick (fun _ -> props.addContact())
                                    ]
                                    Html.hr[
                                        prop.classes[BulmaCss.``dropdown-divider``]
                                        prop.style[
                                            style.marginLeft 15
                                            style.marginRight 15
                                            style.marginTop 0
                                            style.marginBottom 0
                                        ]
                                    ]
                                    Html.div [
                                        prop.ref addGroupRef
                                        prop.classes [AppCss.ClickableItem]
                                        prop.style[
                                            style.padding 15
                                        ]
                                        prop.text (localize.translate "contextMenu.newGroup")
                                        prop.onClick (fun _ -> props.addGroup())
                                    ]
                                ]
                            ]
                        ]
                    ]
                ]
            ]
        )

        //Render AddressBook
        let renderCallSection state dispatch =
            let url =
                match state.SelectedCaseId with
                | Some caseId ->
                    if state.DeviceId |> String.IsNullOrEmpty |> not then
                        Route.VideoCall4Version (caseId, Some state.DeviceId) |> toPath |> Some
                    else Route.VideoCall4Version (caseId, None) |> toPath |> Some
                | _ -> None

            Bulma.section[
                    prop.style[
                        style.backgroundColor "#E5E5E5"
                        style.height 140
                        style.marginBottom 0
                    ]
                    prop.children[
                        Bulma.level [
                            prop.style[
                                style.opacity 0.5
                                style.fontWeight 600
                                style.marginLeft 11
                                style.marginBottom 0
                                style.height 24
                            ]
                            prop.children[
                                Bulma.levelLeft[
                                    Bulma.levelItem[
                                        match state.AddressBook with
                                            | HasNotStartedYet
                                            | InProgress -> Html.none
                                            | Resolved (Ok addressbook) ->
                                                for contact in addressbook.Contacts do
                                                    if contact.DeviceId = state.DeviceId then
                                                        Html.p [
                                                            Bulma.help [
                                                                prop.classes [AppCss.NameCallSection]
                                                                prop.text(contact.FirstName + " " + contact.LastName)
                                                            ]
                                                        ]
                                            | Resolved (Error x) -> Html.none
                                    ]
                                ]
                            ]
                        ]
                        Bulma.level[
                            prop.children[
                                Bulma.levelLeft[
                                    Bulma.levelItem[
                                        Bulma.input.text[
                                            prop.style [style.width 200]
                                            prop.valueOrDefault state.DeviceId
                                            prop.placeholder (localize.translate "input.placeholder.enterDeviceId")
                                            prop.maxLength 10
                                            prop.onChange (DeviceIdChanged >> dispatch)
                                        ]
                                    ]
                                    Bulma.levelItem[
                                        prop.style [style.width 160]
                                        prop.children [
                                            SearchCase.searchCase {| OnSelectCaseId = CaseIdSelected >> dispatch |}
                                        ]
                                    ]
                                ]
                                Bulma.levelRight[
                                    Bulma.levelItem[
                                        Bulma.button.a[
                                            prop.style[
                                                style.backgroundColor "#3572BE"
                                                style.marginBottom 0
                                                style.borderWidth 0
                                            ]
                                            prop.children[
                                                Bulma.icon[
                                                    Html.i[
                                                        prop.classes[MdiCss.Mdi; MdiCss.Mdi18Px; MdiCss.MdiPhone]
                                                        prop.style[
                                                            style.color.white
                                                        ]
                                                    ]
                                                ]
                                            ]
                                            prop.disabled (url |> Option.isNone)
                                            prop.target "_blank"
                                            if url.IsSome then prop.href url.Value
                                        ]
                                    ]
                                ]
                            ]
                        ]
                    ]
                ]

        let renderContact dispatch state (contact: Contact)=
            Bulma.box[
                prop.classes [AppCss.BoxAddrbook; AppCss.DisplayIcons; AppCss.ClickableItem]
                if contact.DeviceId = state.DeviceId then
                    prop.style [style.backgroundColor "#D6DFEA"]
                    dispatch (DeviceIdChanged contact.DeviceId)
                prop.children[
                    Bulma.columns[
                        Bulma.column[
                            prop.text (contact.FirstName + " " + contact.LastName)
                            ]
                        Bulma.column[
                            prop.classes [BulmaCss.``has-text-right``]
                            prop.children[
                                Html.text [
                                    prop.style [style.opacity 0.5]
                                    prop.text contact.DeviceId
                                ]
                                ContextMenu {|
                                            onEdit = fun _ -> dispatch (EditContact contact);
                                            onDelete = fun _ -> dispatch (DeleteContact (contact, ConfirmationStarted))
                                            IsContact = true
                                        |}
                            ]
                        ]
                    ]
                ]
                prop.onClick (fun x -> x.preventDefault(); dispatch (DeviceIdChanged contact.DeviceId))

            ]

        let renderContactsWithoutGroup (state: State) dispatch (group: Group, contacts: Contact list) =
            let isExpanded = state.CollapsedGroups |> Map.tryFind group.Id |> Option.defaultValue true
            Html.div[
                Bulma.box[
                    prop.classes [AppCss.BoxAddrbook]
                    prop.style [style.backgroundColor "#E6E6E6"]
                    prop.children [
                        Bulma.columns[
                            Bulma.column[ prop.text group.Name]
                            Bulma.column[
                                prop.classes[BulmaCss.``has-text-right``]
                                prop.children[
                                    Html.text [
                                        prop.style[style.opacity 0.25]
                                        prop.text (string contacts.Length + " " + localize.translate "table.contacts")
                                    ]
                                    Bulma.icon[
                                        prop.style[
                                            style.opacity 0.25
                                            style.marginRight 15
                                            style.marginLeft 15
                                        ]
                                        prop.children[
                                            Html.i[
                                                prop.classes[
                                                    AppCss.ClickableItem;
                                                    MdiCss.Mdi;
                                                    MdiCss.Mdi18Px;
                                                    if isExpanded then MdiCss.MdiChevronUp else MdiCss.MdiChevronDown
                                                ]
                                                prop.onClick(fun x -> x.preventDefault(); dispatch (ToggleGroup group))
                                            ]
                                        ]
                                    ]
                                    Bulma.icon[
                                        prop.children[
                                            Html.i[prop.classes[MdiCss.Mdi; MdiCss.Mdi24Px;]]
                                        ]
                                    ]
                                ]
                            ]
                        ]
                    ]
                ]
                if isExpanded then
                    yield! contacts |> List.map (renderContact dispatch state)
            ]

        let renderGroup (state: State) dispatch (group: Group, contacts: Contact list) =
            let isExpanded = state.CollapsedGroups |> Map.tryFind group.Id |> Option.defaultValue true
            Html.div[
                Bulma.box[
                    prop.classes [AppCss.BoxAddrbook]
                    prop.style [style.backgroundColor "#E6E6E6"]
                    prop.children [
                        Bulma.columns[
                            Bulma.column[ prop.text group.Name]
                            Bulma.column[
                                prop.classes[BulmaCss.``has-text-right``]
                                prop.children[
                                    Html.text [
                                        prop.style[style.opacity 0.25]
                                        prop.text (string contacts.Length + " " + localize.translate "table.contacts")
                                    ]
                                    Bulma.icon[
                                        prop.style[
                                            style.opacity 0.25
                                            style.marginRight 15
                                            style.marginLeft 15
                                        ]
                                        prop.children[
                                            Html.i[
                                                prop.classes[
                                                    AppCss.ClickableItem;
                                                    MdiCss.Mdi;
                                                    MdiCss.Mdi18Px;
                                                    if isExpanded then MdiCss.MdiChevronUp else MdiCss.MdiChevronDown
                                                ]
                                                prop.onClick(fun x -> x.preventDefault(); dispatch (ToggleGroup group))
                                            ]
                                        ]
                                    ]
                                    ContextMenu {|
                                                onEdit = fun _ -> dispatch (EditGroup group);
                                                onDelete = fun _ -> dispatch (DeleteGroup (group, ConfirmationStarted))
                                                IsContact = false
                                            |}
                                ]
                            ]
                        ]
                    ]
                ]
                if isExpanded then
                    yield! contacts |> List.map (renderContact dispatch state)
            ]

        let renderGroups state dispatch =
            match state.AddressBook with
            | HasNotStartedYet
            | InProgress -> Components.Loader.medium (localize.translate "loading.groups")
            | Resolved (Ok addressBook) ->
                let groups =
                    addressBook.Contacts
                    |> List.groupBy (fun x -> x.Group)
                let contactsWithoutGroup =
                    groups
                    |> List.tryFind (fun (g, contacts) -> g.IsNone)
                    |> Option.map snd
                    |> Option.defaultValue []
                let groups =
                    addressBook.Groups |> List.map (fun x -> x, [])
                    |> List.append (
                        groups
                        |> List.filter (fun (g, contacts) -> g.IsSome)
                        |> List.map (fun (g, c) -> g.Value, c))
                    |> List.distinctBy fst
                    |> List.sortBy (fun (g, _) -> g.Name)
                Html.div [
                    if contactsWithoutGroup<>[] then
                        renderContactsWithoutGroup state dispatch (state.GroupOfUngroupedContacts, contactsWithoutGroup)
                    yield! groups |> List.map (renderGroup state dispatch)
                ]
            | Resolved (Error x) -> Html.none

        let renderForm (state: State) dispatch =
            Bulma.section[
                prop.style[style.paddingTop 32]
                prop.children[
                    renderGroups state dispatch
                ]
            ]

        let renderToolbar (state: State) dispatch =
            Bulma.section[
                prop.style[ style.paddingBottom 0 ]
                prop.children[
                    Bulma.container[
                        Bulma.level[
                            Bulma.levelLeft[
                                if state.IsVisible then
                                    Bulma.levelItem[
                                        prop.classes [AppCss.CloseQuickview]
                                        prop.children[
                                            Bulma.button.button[
                                                color.isPrimary
                                                prop.className "button-shadow"
                                                prop.style[
                                                    style.position.absolute
                                                    style.top 500
                                                    style.left 3
                                                ]
                                                prop.onClick( fun x-> x.preventDefault(); dispatch Close)
                                                prop.children[
                                                    Bulma.icon[
                                                        Html.i[
                                                            prop.classes[MdiCss.Mdi; MdiCss.Mdi24Px; MdiCss.MdiArrowRight   ]
                                                        ]
                                                    ]
                                                ]
                                            ]
                                        ]
                                    ]
                                Bulma.levelItem[
                                    prop.classes [BulmaCss.``is-uppercase``]
                                    prop.children [Bulma.title.h3 (localize.translate "title.addressbook")]
                                ]
                            ]
                        ]
                        Bulma.level[
                            Bulma.levelLeft[
                                Bulma.levelItem[
                                    Bulma.field.div [
                                        Bulma.control.p [
                                            Bulma.control.hasIconsRight
                                            prop.children [
                                                Bulma.input.search [
                                                    prop.style[style.width 150]
                                                    prop.placeholder "Sök"
                                                    prop.autoFocus true
                                                ]
                                                Bulma.icon [
                                                    Bulma.icon.isRight
                                                    prop.children [
                                                        Html.i [
                                                            prop.classes [  MdiCss.Mdi; MdiCss.Mdi24Px; MdiCss.MdiMagnify ]
                                                        ]
                                                    ]
                                                ]
                                            ]
                                        ]
                                    ]
                                ]
                            ]
                            Bulma.levelRight[
                                Bulma.levelItem[
                                    prop.children[
                                        Bulma.button.button[
                                            prop.style[ style.backgroundColor "#3572BE"]
                                            prop.className "button-shadow"
                                            prop.children[
                                                Bulma.icon[
                                                    Html.i[
                                                        prop.classes[MdiCss.Mdi; MdiCss.Mdi18Px; MdiCss.MdiPhone]
                                                        prop.style[
                                                            style.color.white
                                                            ]
                                                    ]
                                                ]
                                            ]
                                        ]
                                    ]
                                ]
                                Bulma.levelItem[
                                    Bulma.button.button[
                                        Bulma.color.isBlack
                                        prop.className "button-shadow"
                                        prop.text "+ Ny kontakt"
                                    ]
                                ]
                            ]
                        ]
                    ]
                ]
            ]

        let renderToolbarForDemo (state: State) dispatch =
            Bulma.section[
                prop.style[style.paddingBottom 0]
                prop.children[
                    Bulma.container[
                        Bulma.level[
                            Bulma.levelLeft[
                                if state.IsVisible then
                                    Bulma.levelItem[
                                        prop.classes [ AppCss.CloseQuickview ]
                                        prop.style [style.zIndex 40]
                                        prop.children[
                                            Bulma.button.button[
                                                color.isPrimary
                                                prop.style[
                                                    style.position.fixedRelativeToWindow
                                                    style.bottom 80
                                                    style.left -20
                                                ]
                                                prop.className "button-shadow"
                                                prop.onClick( fun x-> x.preventDefault(); dispatch Close)
                                                prop.children[
                                                    Bulma.icon[
                                                        Html.i[
                                                            prop.classes[MdiCss.Mdi; MdiCss.Mdi24Px; MdiCss.MdiArrowRight   ]
                                                        ]
                                                    ]
                                                ]
                                            ]
                                        ]
                                    ]
                                Bulma.levelItem[
                                    prop.classes [BulmaCss.``is-uppercase``]
                                    prop.children [Bulma.title.h3 (localize.translate "title.addressbook")]
                                ]
                            ]
                            Bulma.levelRight[
                                Bulma.levelItem[
                                    prop.children[
                                        ContextMenuAddMode {|
                                                            addContact = (fun _ -> dispatch AddContact)
                                                            addGroup = (fun _ -> dispatch AddGroup)
                                                        |}
                                    ]
                                ]
                            ]
                        ]
                    ]
                ]
            ]

        let renderConfirmDeleteContact (state: State) dispatch =
            match state.DeleteContactResult with
            | Some x ->
                if x.Result = Requested then
                    let confirmedTrue () = dispatch (DeleteContact (x.Contact, Confirmed))
                    let confirmedFalse () = dispatch (DeleteContact (x.Contact, Canceled))
                    Components.Common.renderDeleteWindow
                        {| message = localize.translate "delete.contact.confirmation.question"
                           confirmed=confirmedTrue
                           notConfirmed=confirmedFalse |}
                else
                    Html.none
            | None -> Html.none

        let renderConfirmDeleteGroup (state: State) dispatch =
            match state.DeleteGroupResult with
            | Some x ->
                if x.Result = Requested then
                    let confirmedTrue () = dispatch (DeleteGroup (x.Group, Confirmed))
                    let confirmedFalse () = dispatch (DeleteGroup (x.Group, Canceled))
                    Components.Common.renderDeleteWindow
                        {| message = localize.translate "delete.group.confirmation.question"
                           confirmed=confirmedTrue
                           notConfirmed=confirmedFalse |}
                else
                    Html.none
            | None -> Html.none

        let render (state: State) (dispatch: Msg -> unit) =
            if state.IsVisible then
                Html.div [
                    prop.children [
                        Html.div [
                            prop.className "Dark-screen"
                        ]
                        QuickView.quickview [
                            quickview.isActive
                            prop.style[
                                style.width (length.percent 33)
                                style.zIndex 50
                            ]
                            prop.children [
                                QuickView.body [
                                    prop.classes [AppCss.Scrollbar]
                                    prop.children[
                                        match state.OpenedEditGroup with
                                        | Some group -> EditGroup.editGroup group (fun _ -> dispatch CloseEditWindow)
                                        | None ->
                                            match state.OpenedEditContact with
                                            | Some contact -> EditContact.editContact contact (fun _ -> dispatch CloseEditWindow)
                                            | None ->
                                                if state.OpenedAddGroup then
                                                    EditGroup.addGroup (fun _ -> dispatch CloseEditWindow)
                                                elif state.OpenedAddContact then
                                                    EditContact.addContact (fun _ -> dispatch CloseEditWindow)
                                                else
                                                    renderCallSection state dispatch
                                                    renderToolbarForDemo state dispatch
                                                    QuickView.block [ renderForm state dispatch ]
                                    ]
                                ]
                            ]
                        ]
                        renderConfirmDeleteContact state dispatch
                        renderConfirmDeleteGroup state dispatch
                    ]
                ]
            else
                Html.none

        type HiddenModeProps={
            HiddenMode: unit -> unit
        }

        let showAddrBook (props: HiddenModeProps) =
            React.elmishComponent("EditPosition", init(), update props.HiddenMode, render)

module AddrBookButton =

    open Elmish
    open Feliz
    open Feliz.ElmishComponents
    open Feliz.Bulma

    type AddrBookState =
    | Hidden
    | Shown

    type State ={
        AddrBook: AddrBookState
    }

    type Msg=
    | ShowAddrBook
    | MakeHidden

    let init() = {AddrBook = Hidden}, Cmd.none

    let update (msg: Msg) (state: State) =
        match msg with
        | ShowAddrBook ->
            {state with AddrBook=Shown}, Cmd.none
        | MakeHidden ->
            {state with AddrBook=Hidden}, Cmd.none

    let renderAddrBookButton (state: State) dispatch =
        Bulma.button.a[
            prop.className "button-shadow"
            color.isPrimary
            prop.children[
                Bulma.icon[
                    Html.i[
                        prop.classes[MdiCss.Mdi; MdiCss.Mdi24Px; MdiCss.MdiContactsOutline]
                    ]
                ]
            ]
            prop.onClick (fun x-> x.preventDefault(); dispatch ShowAddrBook)
        ]

    let renderAddrBook (state: State) dispatch =
        match state.AddrBook with
        | Hidden -> Html.none
        | Shown ->
            AddrBookShow.View.showAddrBook {
                HiddenMode = fun _ -> dispatch MakeHidden
            }

    let render (state: State) dispatch =
        React.fragment [
            renderAddrBookButton state dispatch
            renderAddrBook state dispatch
        ]

    let showAddressBook()=
        React.elmishComponent("ShowAddressBook", init(), update, render)