module ResetPassword

open Common
open Feliz
open Feliz.Bulma
open Router
open Elmish


let p = Localization.ns("changePassword")

module Types =
    type Form =
        { Password: string
          PasswordConfirmation: string }

    type State = {
        Code: System.Guid
        Form: Form
        Changing: Deferred<Result<unit, string>>
        FormErrors: Map<string, string list >
        IsNeedValidation: bool
    }

    type Msg =
        | PasswordChanged of string
        | PasswordConfirmationChanged of string
        | ResetPassword of AsyncOperationStatus<Result<unit, string>>

module Cmd =
    open Types
    open Communication

    let change (state: State) =
        async {
            try
                match! authApi().resetPassword  { Code = state.Code; Password = state.Form.Password} with
                | Ok _ -> return ResetPassword (Finished (Ok ()))
                | Error (er) ->
                    return ResetPassword (Finished(Error (Components.Common.TranslatedErrors.ServerError.explainTranslation er)))
            with
                ex -> return ResetPassword (Finished (Error ex.Message))
        }


module Validation =
    open System
    open Fable.Validation.Core
    open Types

    let validate (formInfo: Form) =
        let testerNewPassword value =
            (value = "")
            ||
            (String.IsNullOrWhiteSpace value |> not)
            && ((value.Length >= 8)
            && (value |> Seq.exists Char.IsDigit)
            && (value |> Seq.exists Char.IsLetter))

        let testerPasswordConfirmation confirmPassword =
            ((confirmPassword = "") && (formInfo.Password = ""))
            || formInfo.Password = confirmPassword

        all <| fun t ->
            { Password = t.Test "NewPassword" formInfo.Password
                    |> t.IsValid testerNewPassword (p "error.password")
                    |> t.End
              PasswordConfirmation = t.Test "RepeatNewPassword" formInfo.PasswordConfirmation
                    |> t.IsValid testerPasswordConfirmation (p "error.passwordConfirmation")
                    |> t.End
            }

    let validateIf needValidate (x: Form)  =
        if needValidate then
            match validate x with
            | Ok x -> x, Map.empty
            | Error errors -> x, errors
        else x, Map.empty


module State =
    open Types
    open Extensions.View

    let init id : State * Cmd<Msg> =
        { Code = id
          Form = { Password = "";  PasswordConfirmation = "" }
          Changing = HasNotStartedYet
          FormErrors = Map.empty
          IsNeedValidation = false }, Cmd.none

    let update (msg: Msg) (state: State)  =
        match msg with
        | PasswordChanged x ->
            let newForm, errors =
                { state.Form with Password = x }
                |> Validation.validateIf state.IsNeedValidation
            { state with Form = newForm; FormErrors = errors }, Cmd.none
        | PasswordConfirmationChanged x ->
            let newForm, errors =
                { state.Form with PasswordConfirmation = x }
                |> Validation.validateIf state.IsNeedValidation
            { state with Form = newForm; FormErrors = errors }, Cmd.none
        | ResetPassword Started ->
            match Validation.validate state.Form with
            | Ok form ->
                { state with Changing = InProgress; Form = form; FormErrors = Map.empty; IsNeedValidation = true }, Cmd.fromAsync (Cmd.change state)
            | Error errors ->
                { state with FormErrors = errors; IsNeedValidation = true }, Cmd.none
        | ResetPassword (Finished (Ok user)) ->
            { state with Changing = Resolved (Ok user); Form = { Password = "";  PasswordConfirmation = "" }},
                Cmd.batch [
                    Cmd.toastSuccess (p "toast.success.msg")
                    navigateTo Router.Route.SignIn
                ]
        | ResetPassword (Finished (Error e)) ->
            { state with Changing = Resolved (Error e) }, Cmd.toastServerError (p "title") e


module View =
    open Types
    open Fable.Core.JsInterop



    let logo: string = importAll "./public/img/logo.svg"
    let private column (state : State) (dispatch : Msg -> unit) =
        Bulma.column [
            prop.classes [BulmaCss.``is-4-desktop``; BulmaCss.``is-6-tablet``]
            prop.style [ style.margin.auto ]
            prop.children [
                Bulma.field.div [
                    Bulma.image [
                        prop.style [
                            style.width 250
                            style.margin.auto
                            style.fontSize 12
                        ]
                        prop.children [
                            Html.img [ prop.src logo ]
                            Html.p "Skadebesiktningshjälpen"
                            Html.p "Smarta besiktningar i mobilen"
                        ]
                    ]
                ]
                Html.form [
                    Bulma.field.div [
                        prop.style[
                            style.marginTop 40
                            style.display.flex
                            style.justifyContent.center
                        ]
                        prop.text (p "title")
                    ]
                    Bulma.field.div [
                        Bulma.control.div [
                            Bulma.input.password [
                                prop.placeholder (p "placeholder.newPassword")
                                // Added according to recommendations
                                // https://www.chromium.org/developers/design-documents/create-amazing-password-forms
                                // https://www.chromium.org/developers/design-documents/form-styles-that-chromium-understands
                                prop.autoComplete "current-password"
                                prop.onChange (PasswordChanged >> dispatch)
                            ]
                            Bulma.help [
                                prop.style [style.color.red]
                                prop.text (state.FormErrors |> Form.Field.errorsAsString "NewPassword")
                            ]
                        ]
                    ]
                    Bulma.field.div [
                        Bulma.control.div [
                            prop.children [
                                Bulma.input.password [
                                    prop.placeholder (p "placeholder.repeatPassword")
                                    // Added according to recommendations
                                    // https://www.chromium.org/developers/design-documents/create-amazing-password-forms
                                    // https://www.chromium.org/developers/design-documents/form-styles-that-chromium-understands
                                    prop.autoComplete "current-password"
                                    prop.onChange (PasswordConfirmationChanged >> dispatch)
                                ]
                                Bulma.help [
                                    prop.style [style.color.red]
                                    prop.text (state.FormErrors |> Form.Field.errorsAsString "RepeatNewPassword")
                                ]
                            ]
                        ]
                    ]
                    Html.a[
                        prop.style[
                            style.fontSize 16
                            style.fontWeight 400
                            style.color "#3572BE"
                            style.display.flex
                            style.justifyContent.center
                        ]
                        prop.text (p "signIn.link")
                        prop.href (Router.toPath Router.Route.SignIn)
                    ]
                    Bulma.field.div [
                        Bulma.control.div [
                            Bulma.button.button [
                                Bulma.button.isFullWidth
                                color.isBlack
                                if state.Changing = InProgress then button.isLoading
                                prop.text (p "button.save")
                                prop.style[
                                    style.marginTop 40;
                                ]
                                prop.onClick (fun e -> e.preventDefault(); dispatch (ResetPassword Started))
                            ]
                        ]
                    ]
                ]
            ]
        ]

    let view state dispatch =
        Bulma.hero [
            Bulma.hero.isFullHeight
            prop.classes [ AppCss.LoginScreen ]
            prop.children [
                Bulma.heroBody [
                    Bulma.container [
                        column state dispatch
                    ]
                ]
            ]
        ]
module Component =
    open State
    open View
    open Feliz.ElmishComponents

    let resetPassword (code: System.Guid) =
        React.elmishComponent("ForgotPassword", init(code), update, view)

