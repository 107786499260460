module Cases

open Components
open Feliz.Bulma
open Shared
open Shared.Case
open Communication
open Elmish
open Extensions.View

let t = Localization.ns("case")

type State = {
    LoadedCases : Deferred<Result<Queries.Case list, string>>
    SortBy: (string * Sorting) option
}

type Msg =
    | LoadCases of AsyncOperationStatus<Result<Queries.Case list, string>>
    | GoToDetails of string
    | OpenCalendar
    | RegisterCase
    | OnColumnClicked of string

let damageType = function
    | Construction -> t "damage.construction"
    | Motor x -> t "damage.motor"
    | Prevention -> t "damage.prevention"
    | Other -> t "damage.other"

let toStatus = function
    | New -> t "caseStatus.new"
    | Emergency -> t "caseStatus.emergency"
    | Ongoing -> t "caseStatus.inProgress"
    | Closed -> t "caseStatus.Closed"

let sort (sortBy: (string * Sorting) option) (x: Queries.Case list) =
    match sortBy with
    | Some ("caseNumber", Ascending) -> x |> List.sortBy(fun s -> s.CaseNumber.ToLowerInvariant())
    | Some ("caseNumber", Descending) -> x |> List.sortByDescending (fun s -> s.CaseNumber.ToLowerInvariant())
    | Some ("damageCause", Ascending) -> x |> List.sortBy (fun s -> damageType s.DamageType)
    | Some ("damageCause", Descending) -> x |> List.sortByDescending (fun s -> damageType s.DamageType)
    | Some ("inspector", Ascending) -> x |> List.sortBy (fun s -> s.Inspector)
    | Some ("inspector", Descending) -> x |> List.sortByDescending (fun s -> s.Inspector)
    | Some ("status", Ascending) -> x |> List.sortBy (fun s -> toStatus s.Status)
    | Some ("status", Descending) -> x |> List.sortByDescending (fun s -> toStatus s.Status)
    | Some ("date", Ascending) -> x |> List.sortBy (fun s -> s.VideoCallDate)
    | Some ("date", Descending) -> x |> List.sortByDescending (fun s -> s.VideoCallDate)
    | Some ("createdDate", Ascending) -> x |> List.sortBy (fun s -> s.CreatedDate)
    | Some ("createdDate", Descending) -> x |> List.sortByDescending (fun s -> s.CreatedDate)
    | Some ("modifiedDate", Ascending) -> x |> List.sortBy (fun s -> s.ModifiedDate)
    | Some ("modifiedDate", Descending) -> x |> List.sortByDescending (fun s -> s.ModifiedDate)
    | _ -> x
            |> List.sortBy (fun x -> x.CaseNumber)
            |> List.sortByDescending (fun x -> x.VideoCallDate)


let init () =
    let load : Async<Msg> =
        async {
            try
                let! x = caseApi().currentCases () |> Remoting.handleNonAuth
                return LoadCases (Finished (Ok x))
            with
                ex -> return LoadCases (Finished (Error ex.Message))
        }
    { LoadedCases = HasNotStartedYet; SortBy = None }, Cmd.fromAsync load

let update (state: State) (msg: Msg) =
    match msg with
    | LoadCases Started -> { state with LoadedCases = InProgress }, Cmd.none
    | LoadCases (Finished (Ok cases)) ->
        let sorted = cases |> sort state.SortBy
        { state with LoadedCases = Resolved (Ok sorted) }, Cmd.none
    | LoadCases (Finished (Error e)) ->
        { state with LoadedCases = Resolved (Error e) }, Cmd.toastServerError "Load cases" e
    | GoToDetails id -> state, Router.navigateTo (Router.CaseDetails id)
    | OpenCalendar -> state, Router.navigateTo(Router.CallCalendar)
    | RegisterCase -> state, Router.navigateTo (Router.RegisterCase)
    | OnColumnClicked oldX ->
        let sortBy =
            match state.SortBy with
            | Some (newX, s) when oldX = newX -> Some (oldX, Sorting.toggle s)
            | _ -> Some (oldX, Sorting.Ascending)
        match state.LoadedCases with
        | Resolved( Ok cases ) ->
            let sorted = cases |> sort sortBy
            { state with LoadedCases = Resolved (Ok sorted); SortBy = sortBy }, Cmd.none
        | x -> { state with LoadedCases = x; SortBy = sortBy }, Cmd.none

open Feliz

let renderToolbar (state: State) dispatch =
    Bulma.level [
        Bulma.levelLeft []
        Bulma.levelRight [
            Bulma.levelItem [
                Bulma.buttons[
                    Bulma.button.button[
                        prop.style[style.marginRight 20]
                        prop.children[
                            Bulma.icon[
                                Html.i[
                                    prop.style[style.color "#3572BE"]
                                    prop.classes [MdiCss.Mdi; MdiCss.Mdi24Px; MdiCss.MdiCalendarBlankOutline]
                                ]
                            ]
                        ]
                        prop.onClick (fun x -> x.preventDefault(); dispatch OpenCalendar)
                    ]
                    Bulma.button.button [
                        prop.classes [AppCss.ButtonShadow]
                        Bulma.color.isPrimary
                        prop.text (t "button.addingCase")
                        prop.onClick (fun x -> x.preventDefault(); dispatch RegisterCase)
                    ]
                ]
            ]
        ]
    ]

let statusColor = function
    | CaseStatus.New -> BulmaCss.``has-text-primary``
    | CaseStatus.Emergency -> BulmaCss.``has-text-danger``
    | CaseStatus.Ongoing -> BulmaCss.``has-text-success``
    | CaseStatus.Closed -> BulmaCss.``has-text-black``

let headerTable state dispatch =

    let sortingColumnButton (columnCaption:string) columnName =
        Html.a [
            prop.onClick (fun x -> OnColumnClicked columnName |> dispatch; x.preventDefault())
            prop.children [
                match state.SortBy with
                | Some (newX, Ascending) when columnName = newX ->
                    Html.div [
                        color.hasTextPrimary
                        prop.children [
                            Html.span [
                                prop.text columnCaption
                            ]
                            Html.i[
                                prop.style [style.marginLeft (length.rem 0.25); style.fontSize 16]
                                prop.classes [MdiCss.Mdi; MdiCss.MdiChevronDown]
                            ]
                        ]
                    ]
                | Some (newX, Descending) when columnName = newX ->
                    Html.div [
                        color.hasTextPrimary
                        prop.children [
                            Html.span [
                                prop.text columnCaption
                            ]
                            Html.i[
                                prop.style [style.marginLeft (length.rem 0.25); style.fontSize 16]
                                prop.classes [MdiCss.Mdi; MdiCss.MdiChevronUp]
                            ]
                        ]
                    ]
                | _ ->
                    Html.div [
                        Html.span [
                            color.hasTextGreyDark
                            prop.text columnCaption
                        ]
                    ]
            ]
        ]
    Bulma.columns[
        columns.isVCentered
        prop.classes [AppCss.Headers]
        prop.children[
            Bulma.column (sortingColumnButton (t "title.caseNumber") "caseNumber")
            Bulma.column (sortingColumnButton (t "title.damageCause") "damageCause")
            Bulma.column (sortingColumnButton (t "title.inspector") "inspector")
            Bulma.column[
                prop.style [ style.textAlign.center ]
                prop.children [ (sortingColumnButton (t "title.status") "status") ]
            ]
            Bulma.column[
                prop.style [ style.textAlign.center ]
                prop.children [ (sortingColumnButton (t "title.date") "date") ]
            ]
            Bulma.column[
                prop.style [ style.textAlign.center ]
                prop.children [(sortingColumnButton (t "title.created.date") "createdDate")]
            ]
            Bulma.column[
                prop.style [ style.textAlign.right ]
                prop.children [(sortingColumnButton (t "title.modified.date") "modifiedDate")]
            ]
        ]
    ]

let renderTable (cases: Shared.Case.Queries.Case list) state dispatch =
    Html.div [
        headerTable state dispatch
        for case in cases do
            Bulma.box[
                prop.classes [AppCss.BoxOnHover; AppCss.BoxStyle]
                prop.children[
                    Bulma.columns [
                        columns.isVCentered
                        prop.onClick (fun x -> x.preventDefault(); GoToDetails (case.Id.ToString()) |> dispatch)
                        prop.children[
                            Bulma.column[prop.text (case.CaseNumber.ToString())]
                            Bulma.column[prop.text (damageType case.DamageType)]
                            Bulma.column[
                                prop.style [style.paddingTop 0; style.paddingBottom 0]
                                prop.text (case.Inspector |> Option.map (fun x -> x.Name) |>  Option.defaultValue "")
                            ]
                            Bulma.column[
                                prop.style [ style.textAlign.center ]
                                prop.text (toStatus case.Status)
                                prop.classes [ statusColor case.Status ]
                            ]
                            Bulma.column[
                                prop.style [ style.textAlign.center ]
                                prop.children[
                                    Common.renderDateOption case.VideoCallDate
                                ]
                            ]
                            Bulma.column[
                                prop.style [ style.textAlign.center ]
                                prop.children[
                                    Common.renderDate case.CreatedDate
                                ]
                            ]
                            Bulma.column[
                                prop.style [ style.textAlign.right ]
                                prop.children[
                                    Common.renderDate case.ModifiedDate
                                ]
                            ]
                        ]
                    ]
                ]
            ]
    ]

let render (state: State) (dispatch: Msg -> unit) =
    match state.LoadedCases with
    | HasNotStartedYet
    | InProgress ->
        Components.Loader.fullScreenMedium (t "loading.cases")
    | Resolved (Ok cases) ->
        Html.div [
            Html.div[
                prop.classes [ AppCss.Toolbar ]
                prop.children [
                    renderToolbar state dispatch
                ]
            ]
            renderTable cases state dispatch
        ]
    | Resolved (Error ex) -> Html.none
