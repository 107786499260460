﻿module ForgotPassword

open System
open Feliz
open Feliz.Bulma
open Elmish
open Common
open Communication

let t = Localization.ns("signin")
let p = Localization.ns("forgotPassword")

module Types =
    type State = {
        Email: string
        Recovery: Deferred<Result<unit, string>>
        FormErrors: Map<string, string list >
        FormValidation: bool
    }
    type Msg =
        | EmailChanged of string
        | RecoverAccount of AsyncOperationStatus<Result<unit, string>>
        | UserSaved

module Cmd =
    open Types

    let recover (state: State) =
        async {
            try
                match! authApi().requestResetPassword state.Email with
                | Ok id ->
                    return RecoverAccount (Finished (Ok id))
                | Error (er) ->
                    return RecoverAccount (Finished (Error (Components.Common.TranslatedErrors.ServerError.explainTranslation er)))
            with
                ex -> return RecoverAccount (Finished (Error ex.Message))
        }

module State =
    open Types
    open Extensions.View

    let init () : State * Cmd<Msg> =
        { Email = ""; Recovery = HasNotStartedYet; FormValidation = false; FormErrors = Map.empty}, Cmd.none

    let update (msg: Msg) (state: State)  =
        match msg with
        | EmailChanged x ->
            let errors = Form.validationEmail x state.FormValidation
            { state with Email = x; FormErrors = errors.Value }, Cmd.none
        | RecoverAccount Started ->
            let newState = {state with FormValidation = true}
            let errors = Common.Form.validationEmail state.Email newState.FormValidation
            if errors.Value=Map.empty then
                { state with Recovery = InProgress; FormErrors = errors.Value; FormValidation = true }, Cmd.fromAsync (Cmd.recover state)
            else
                { state with Recovery = HasNotStartedYet; FormErrors = errors.Value ; FormValidation = true}, Cmd.none
        | RecoverAccount (Finished (Ok id)) ->
            { state with Recovery = Resolved (Ok id)}, Cmd.toastSuccess (p "toast.success.msg")
        | RecoverAccount (Finished (Error e)) ->
            { state with Recovery = Resolved (Error e) }, Cmd.toastServerError (p "toast.error.title") e
        | UserSaved -> state, Cmd.none

module View =
    open Types
    open Fable.Core.JsInterop



    let logo: string = importAll "./public/img/logo.svg"
    let private column (state : State) (dispatch : Msg -> unit) =
        Bulma.column [
            prop.classes [BulmaCss.``is-4-desktop``; BulmaCss.``is-6-tablet``]
            prop.style [ style.margin.auto ]
            prop.children [
                Bulma.field.div [
                    Bulma.image [
                        prop.style [
                            style.width 250
                            style.margin.auto
                            style.fontSize 12
                        ]
                        prop.children [
                            Html.img [ prop.src logo ]
                            Html.p "Skadebesiktningshjälpen"
                            Html.p "Smarta besiktningar i mobilen"
                        ]
                    ]
                ]
                Html.form [
                    Bulma.field.div [
                        prop.style[
                            style.marginTop 40
                            style.display.flex
                            style.justifyContent.center
                        ]
                        prop.text (p "title")
                    ]
                    Bulma.field.div [
                        Bulma.control.div [
                            let errors = Form.getFieldError state.FormErrors "Email"
                            Bulma.input.email [
                                prop.placeholder (t "email.placeholder")
                                // Added according to recommendations
                                // https://www.chromium.org/developers/design-documents/create-amazing-password-forms
                                // https://www.chromium.org/developers/design-documents/form-styles-that-chromium-understands
                                prop.autoComplete "username"
                                prop.autoFocus true
                                prop.onChange (EmailChanged >> dispatch)
                            ]
                            Bulma.help [
                                prop.style [
                                    style.color.red
                                    style.textAlign.left
                                ]
                                prop.text (errors |> List.fold (fun s x -> sprintf "%s %s" s x) String.Empty)
                            ]
                        ]
                    ]
                    Html.a[
                        prop.style[
                            style.fontSize 16
                            style.fontWeight 400
                            style.color "#3572BE"
                            style.display.flex
                            style.justifyContent.center
                        ]
                        prop.text (p "back.link")
                        prop.href (Router.toPath Router.Route.SignIn)
                    ]
                    Bulma.field.div [
                        Bulma.control.div [
                            Bulma.button.button [
                                Bulma.button.isFullWidth
                                color.isBlack
                                if state.Recovery = InProgress then button.isLoading
                                prop.text (p "button.send")
                                prop.style[
                                    style.marginTop 40;
                                ]
                                prop.onClick (fun e -> e.preventDefault(); dispatch (RecoverAccount Started))
                            ]
                        ]
                    ]
                ]
            ]
        ]

    let view state dispatch =
        Bulma.hero [
            Bulma.hero.isFullHeight
            prop.classes [ AppCss.LoginScreen ]
            prop.children [
                Bulma.heroBody [
                    Bulma.container [
                        column state dispatch
                    ]
                ]
            ]
        ]
module Component =
    open State
    open View
    open Feliz.ElmishComponents

    let recoverPassword () =
        React.elmishComponent("ForgotPassword", init(), update, view)

