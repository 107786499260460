module Client

open Elmish
open Elmish.HMR
open Elmish.React
open Feliz
open Feliz.Bulma
open Router
open Fable.Core.JsInterop
open System
open Shared.Auth
open Shared.PrimitiveTypes

let t = Localization.ns ("client")

type Page =
    | Login
    | ForgotPassword
    | ResetPassword of Guid
    | Home of Shared.Auth.SignedInUser
    | Cases of Cases.State
    | RegisterCase
    | CaseDetail of Guid
    | CaseEdit of Guid
    | VideoCall4Version of caseId: Guid * deviceId: string option
    | Users of Users.Types.State
    | RegisterUser
    | ProfileSettings
    | UserDetails of UserId
    | UserEdit of int
    | Positions
    | Calendar
    | ExportData
    | VerifyEmail of string
    | Developer
    | NotFound

type User =
    | Anonymous
    | Logged of Shared.Auth.SignedInUser

type State = {
    CurrentPage: Page
    User: User
}

type Msg =
    | SignedIn of Shared.Auth.SignedInUser
    | GoToCases
    | GoToUsers
    | GoToCase of string
    | CasesMsg of Cases.Msg
    | UsersMsg of Users.Types.Msg
    | Logout
    | Unauthorized

let urlUpdate (nextPage : Route option) (state: State) =
    match nextPage, state.User with
    | Some Route.Home, Logged user -> { state with CurrentPage = Home user }, Cmd.none
    | Some Route.Cases, Logged _ ->
        let s, c = Cases.init()
        { state with CurrentPage = Cases s }, Cmd.map CasesMsg c
    | Some Route.RegisterCase, Logged _ ->
        { state with CurrentPage = RegisterCase }, Cmd.none
    | Some (Route.CaseDetails id), Logged _ ->
        { state with CurrentPage = CaseDetail (Guid.Parse(id)) }, Cmd.none
    | Some (Route.CaseEdit id), Logged _ ->
        { state with CurrentPage = CaseEdit (Guid.Parse(id)) }, Cmd.none
    | Some Route.CallCalendar, Logged _ ->
        {state with CurrentPage=Calendar}, Cmd.none
    | Some (Route.VideoCall4Version (caseId, deviceId)), Logged _ -> { state with CurrentPage = VideoCall4Version (Guid.Parse(caseId), deviceId) }, Cmd.none
    | Some Route.Users, Logged _ ->
        let s, c = Users.State.init()
        { state with CurrentPage = Users s }, Cmd.map UsersMsg c
    | Some (Route.UserDetails id), Logged _ ->
        match id |> int |> UserId.create with
        | Ok userId -> { state with CurrentPage = UserDetails userId }, Cmd.none
        | Error e -> state, Router.navigateTo Route.Users
    | Some Route.RegisterUser, Logged _ ->
        { state with CurrentPage = RegisterUser }, Cmd.none
    | Some Route.ProfileSettings, Logged _ ->
        { state with CurrentPage = ProfileSettings }, Cmd.none
    | Some (Route.UserEdit id), Logged _ ->
        { state with CurrentPage = UserEdit id }, Cmd.none
    | Some Route.Positions, Logged _ ->
        { state with CurrentPage = Positions }, Cmd.none
    | Some Route.SignIn, _ -> { state with CurrentPage = Login; User = Anonymous }, Cmd.none
    | Some Route.ForgotPassword, _ -> { state with CurrentPage = ForgotPassword; User = Anonymous }, Cmd.none
    | Some (Route.ResetPassword code), _ -> { state with CurrentPage = (ResetPassword code); User = Anonymous }, Cmd.none
    | Some Route.Export, Logged _->
        {state with CurrentPage = ExportData}, Cmd.none
    | Some (Route.VerifyEmail code), _ ->
        { state with CurrentPage = VerifyEmail code }, Cmd.none
    | Some Route.DeveloperPage, _ ->
        { state with CurrentPage = Developer }, Cmd.none
    | _, _ -> state, Router.modifyUrl Route.SignIn

let init page : State * Cmd<Msg> =
    let user = LocalStorage.getUser() |> Option.map Logged |> Option.defaultValue Anonymous
    Browser.WebStorage.localStorage.setItem("lng", "se")
    urlUpdate page { CurrentPage = Login; User = user}

let update (msg : Msg) (state : State) : State * Cmd<Msg> =
    match msg, state.CurrentPage with
    | SignedIn user, _ ->
        { state with User = Logged user }, navigateTo Route.Cases
    | GoToCases, _ -> state, Router.navigateTo Router.Cases
    | GoToCase caseId, _ -> state, Router.navigateTo (Router.CaseDetails caseId)
    | CasesMsg msg, Cases casesState ->
        let s, c = Cases.update casesState msg
        { state with CurrentPage = Cases s }, Cmd.map CasesMsg c
    | UsersMsg msg, Users usersState ->
        let s, c = Users.State.update usersState msg
        { state with CurrentPage = Users s }, Cmd.map UsersMsg c
    | GoToUsers, _ -> state, Router.navigateTo Router.Users
    | Logout, _ -> init (Some Route.SignIn)
    | Unauthorized, _ -> init (Some Route.SignIn)
    | _, _ -> state, Cmd.none

let private logo : string = importAll "./public/img/logo-white.svg"

let navbar (state: State) dispatch =
    let isActive (route: Route) =
        match route, state.CurrentPage with
        | Router.Cases, Cases _ -> true
        | Router.Users, Users _ -> true
        | Router.Positions, Positions -> true
        | Router.Export, ExportData -> true
        | _, _ -> false

    let userName = function
        | Anonymous -> t "user.anonymous"
        | Logged user -> user.FullName

    Bulma.navbar [
        Bulma.navbar.isTransparent
        Bulma.color.isPrimary
        Bulma.text.isUppercase
        prop.classes [ BulmaCss.``is-fixed-top`` ]
        prop.children [
            Bulma.container [
                Bulma.navbarBrand.div [
                    Bulma.navbarItem.a [
                        Html.img [ prop.src logo ]
                    ]
                ]
                Bulma.navbarMenu [
                    Bulma.navbarStart.div [
                        Bulma.navbarItem.a [
                            if isActive (Router.Cases) then Bulma.navbarItem.isActive
                            prop.style [
                                style.marginRight 16
                            ]

                            Bulma.navbarItem.isTab
                            prop.href (toPath Router.Cases)
                            prop.text (t "menu.cases")

                        ]
                        Bulma.navbarItem.a [
                            if isActive (Router.Users) then Bulma.navbarItem.isActive
                            prop.style [
                                style.marginRight 16
                            ]
                            Bulma.navbarItem.isTab
                            prop.href (toPath Router.Users)
                            prop.text (t "menu.staff")
                        ]
//                        Bulma.navbarItem.a [
//                            if isActive (Router.Positions) then Bulma.navbarItem.isActive
//                            prop.style [
//                                style.marginRight 16
//                            ]
//                            Bulma.navbarItem.isTab
//                            prop.href (toPath Router.Positions)
//                            prop.text (t "menu.positions")
//                        ]
//                        Bulma.navbarItem.a [
//                            if isActive (Router.Export) then Bulma.navbarItem.isActive
//                            prop.style [
//                                style.marginRight 16
//                            ]
//                            Bulma.navbarItem.isTab
//                            prop.href (toPath Router.Export)
//                            prop.text (t "menu.export")
//                        ]
                    ]
                    Bulma.navbarEnd.div [
                        Bulma.navbarItem.a [
                            prop.text (userName state.User)
                            prop.href (toPath Router.ProfileSettings)
                            prop.style [
                                style.marginRight 16
                                style.cursor.pointer
                            ]

                        ]
                        Bulma.navbarItem.a [
                            prop.classes ["logout"]
                            prop.href (toPath Router.SignIn)
                            prop.text (t "menu.logOut")
                        ]
                    ]
                ]
            ]
        ]
    ]

let toolBar (state: State) dispatch =
    Bulma.buttons [
        prop.style [
            style.position.fixedRelativeToWindow
            style.bottom 80
            style.right 87
            style.zIndex 35
        ]
        prop.children [
            Feedback.View.showFeedbackButton()
            Sms.SmsButton.View.showSmsButton()
            AddressBook.AddrBookButton.showAddressBook()
        ]
    ]

let renderProfileSettings (state: State) (dispatch : Msg -> unit) =
    let page =
        match state.User with
        | Anonymous -> User.Register.Component.registerUser { UserId = 1 }
        | Logged u -> ProfileSettings.Component.userDetails()
    Html.div [
        navbar state dispatch
        toolBar state dispatch
        Bulma.container [
            prop.children [
                page
            ]
        ]
    ]
let view (model : State) (dispatch : Msg -> unit) =
    match model.CurrentPage with
    | Login -> SignIn.Component.login { signedIn = SignedIn >> dispatch }
    | ForgotPassword -> ForgotPassword.Component.recoverPassword()
    | ResetPassword code -> ResetPassword.Component.resetPassword code
    | Cases s ->
        Html.div [
            navbar model dispatch
            toolBar model dispatch
            Bulma.container [
                prop.children [
                    Cases.render s (CasesMsg >> dispatch)
                ]
            ]
        ]
    | RegisterCase ->
        Html.div [
            navbar model dispatch
            toolBar model dispatch
            Bulma.container [
                prop.children [
                    Case.Register.View.registerCase ()
                ]
            ]
        ]
    | CaseDetail caseId ->
        Html.div [
            navbar model dispatch
            toolBar model dispatch
            Bulma.container [
                prop.children [
                    CaseDetail.Component.caseDetails { caseId = caseId; goToCases = fun () -> dispatch GoToCases }
                ]
            ]
        ]
    | CaseEdit caseId ->
        Html.div [
            navbar model dispatch
            toolBar model dispatch
            Bulma.container [
                prop.children [Case.Edit.View.editCase caseId]
            ]
        ]
    | Calendar ->
        Html.div [
            navbar model dispatch
            toolBar model dispatch
            Calendar.openCalendar()
        ]
    | VideoCall4Version (caseId, deviceId) ->
        let props: VideoCall4Version.Component.Props = { CaseId = caseId; DeviceId = deviceId }
        Html.div [
            VideoCall4Version.Component.videoCall props
        ]
    | Users s ->
        Html.div [
            navbar model dispatch
            toolBar model dispatch
            Bulma.container [
                prop.children [
                    Users.View.render s (UsersMsg >> dispatch)
                ]
            ]
        ]
    | UserDetails id ->
        Html.div [
            navbar model dispatch
            toolBar model dispatch
            Bulma.container [
                prop.children [
                    UserDetails.Component.userDetails { userId = id }
                ]
            ]
        ]
    | ProfileSettings -> renderProfileSettings model dispatch
    | RegisterUser ->
        Html.div [
            navbar model dispatch
            toolBar model dispatch
            Bulma.container [
                prop.children [
                    User.Register.Component.registerUser { UserId = 1 }
                ]
            ]
        ]
    | UserEdit id ->
        Html.div [
            navbar model dispatch
            toolBar model dispatch
            Bulma.container [
                prop.children [
                    User.Edit.Component.editUser { UserId = id }
                ]
            ]
        ]
    | Positions ->
        Html.div [
            navbar model dispatch
            toolBar model dispatch
            Bulma.container [
                prop.children [
                    Positions.Component.userDetails ()
                ]
            ]
        ]
    | Home user ->
        Html.div [
            Html.h1 (t "title.home")
            Html.h2 user.Username
            Html.button [
                prop.onClick (fun e -> e.preventDefault(); dispatch Logout)
                prop.text (t "menu.logOut")
            ]
        ]
    | ExportData ->
        Html.div[
            navbar model dispatch
            toolBar model dispatch
            Data.Export.View.exportdata()
        ]
    | VerifyEmail code ->
        Html.div [
            ActivateAccount.Component.activateAccount { Code = code }
        ]
    | Developer ->
#if DEBUG
        Bulma.section [
            TestSignalR.render()
        ]
#else
        Html.div "Developer page disabled in production mode"
#endif
    | NotFound ->
        Html.div [
            Html.h1 (t "error.pageNotFound")
        ]

#if DEBUG
open Elmish.Debug
#endif

open Elmish.Navigation
open Elmish.UrlParser
open Elmish.HMR

Program.mkProgram init update view
|> Program.withSubscription (Remoting.subscribe Unauthorized)
|> Program.toNavigable (parseHash Router.routeParser) urlUpdate
#if DEBUG
|> Program.withConsoleTrace
#endif
|> Program.withReactBatched "elmish-app"
#if DEBUG
|> Program.withDebugger
#endif
|> Program.run
