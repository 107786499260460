module Photo

open Elmish
open Shared.Case
open Shared.Case.Queries
open Shared.Errors

let t = Localization.ns("photo")

module Types =
    type ChannelState = Channel

    type Permission = Allowed | Denied

    type Photo =
        | RequestPhoto of Deferred<Result<Permission, string>>
        | TakePhoto of Deferred<Result<unit, string>>

    type Msg =
        | RequestPhotoPermission of AsyncOperationStatus<Result<unit, string>>
        | ResetPhoto
        | OnMessageReceived of string
        | MakePhoto of AsyncOperationStatus<Result<unit, string>>
        | NotifyFileUploaded of AsyncOperationStatus<Result<unit, string>>

    type State = {
        Context: Shared.VideoCall.Context
        Channel: Rtm.Chat
        Photo: Photo
    }

    [<AutoOpen>]
    module Utils =
        let (|MakePhotoAllowed|_|) (state: State) =
            match state.Photo with
            | RequestPhoto (Resolved (Ok Allowed))
            | TakePhoto (Resolved (Error _)) -> Some state
            | _ -> None

        let (|PhotoInProgress|_|) (state: State) =
            match state.Photo with
            | TakePhoto InProgress -> Some state
            | _ -> None

    [<AutoOpen>]
    module MessageParser =
        let (|PhotoAllowed|_|) (msg: string) =
            match msg |> String.trim |> String.toUpperInvariant with
            | "PHOTO_ALLOWED" -> Some PhotoAllowed
            | _ -> None

        let (|PhotoDenied|_|) (msg: string) =
            match msg |> String.trim |> String.toUpperInvariant with
            | "PHOTO_DENIED" -> Some PhotoDenied
            | _ -> None

        let (|PhotoTaken|_|) (msg: string) =
            match String.tokenize msg with
            | [|cmd; fileId|] when String.toUpperInvariant cmd = "PHOTO_TAKEN" -> Some (PhotoTaken (Shared.Case.FileId fileId))
            | _ -> None

        let (|Coordinates_Reported|_|) (msg: string) : Shared.VideoCall.Coordinates option =
            match String.tokenize msg with
            | [|cmd; lat; long|] when String.toUpperInvariant cmd = "COORDINATES_REPORTED" -> Some (Coordinates_Reported ({Latitude = decimal lat; Longitude = decimal long}))
            | _ -> None

module Cmd =
    open Types
    open Communication

    let requestPhoto (x: State): Cmd<Msg> =
        async {
            try
                x.Channel.SendMessage("REQUEST_PHOTO")
                return RequestPhotoPermission (Finished (Ok ()))
            with
                ex -> return RequestPhotoPermission (Finished (Error ex.Message))
        }
        |> Cmd.fromAsync

    let takePhoto (s: State): Cmd<Msg> =
        async {
            try
                match! caseApi().getUploadFileUrl() with
                | Ok uploadInfo ->
                    s.Channel.SendMessage(sprintf "TAKE_PHOTO %s %s" uploadInfo.FileId.Value uploadInfo.UploadUrl)
                    return MakePhoto (Finished (Ok ()))
                | Error ex ->
                    return MakePhoto (Finished (Error (ServerError.explain ex)))
            with
                ex -> return MakePhoto (Finished (Error ex.Message))
        }
        |> Cmd.fromAsync

    let notifyServerFileUploaded (state: State) (fileId: Shared.Case.FileId) : Cmd<Msg> =
        async {
            try
                let args: Shared.Case.TakePhoto = { CaseId = state.Context.CaseId; FileId = fileId; Coordinates = state.Context.GpsCoordinates }
                match! caseApi().takePhoto(TakeMediaArgs.FromVideoCall args) with
                | Ok () -> return NotifyFileUploaded (Finished (Ok ()))
                | Error x -> return NotifyFileUploaded (Finished (Error (ServerError.explain x)))
            with
                ex -> return NotifyFileUploaded (Finished (Error ex.Message))
        }
        |> Cmd.fromAsync

module State =
    open Types
    open Extensions.View

    let init context rtmChannel =
        let state = {
            Context = context
            Channel = rtmChannel
            Photo = RequestPhoto HasNotStartedYet
        }
        let onMessageReceived dispatch = rtmChannel.OnMessage.Add(OnMessageReceived >> dispatch)
        state, Cmd.ofSub onMessageReceived

    let update (msg: Msg) (state: State) =
        match msg, state with
        | RequestPhotoPermission Started, x ->
            { x with Photo = RequestPhoto InProgress }, Cmd.requestPhoto x
        | RequestPhotoPermission (Finished (Ok _)), s ->
            { s with Photo = RequestPhoto InProgress }, Cmd.none
        | RequestPhotoPermission (Finished (Error ex)), s ->
            { s with Photo = RequestPhoto (Resolved (Error ex))}, Cmd.none
        | ResetPhoto, s ->
            { s with Photo = RequestPhoto HasNotStartedYet }, Cmd.none
        | OnMessageReceived message, s ->
            let newState, cmd =
                match message with
                | PhotoAllowed ->
                    { s with Photo = RequestPhoto (Resolved (Ok Allowed)) }, Cmd.toastSuccess (t "photo.allowed")
                | PhotoDenied ->
                    { s with Photo = RequestPhoto (Resolved (Ok Denied)) }, Cmd.toastError (t "photo.denied")
                | PhotoTaken fileId ->
                    { s with Photo = TakePhoto (Resolved (Ok ()))}, Cmd.batch [
                        Cmd.notifyServerFileUploaded state fileId
                        Cmd.toastSuccess (t "photo.taken")
                    ]
                | Coordinates_Reported coordinates ->
                    let coordinates : Shared.VideoCall.Coordinates = {Latitude = coordinates.Latitude; Longitude = coordinates.Longitude }
                    {s with Context = {CaseId = state.Context.CaseId; ChannelId = state.Context.ChannelId; AgentUID = state.Context.AgentUID; GpsCoordinates = Some coordinates}}, Cmd.none
                | _ ->
                    Browser.Dom.console.warn ("Unknown message arrived. Will be ignored.", message)
                    s, Cmd.none
            newState, cmd
        | MakePhoto Started, MakePhotoAllowed s  ->
            { s with Photo = TakePhoto InProgress }, Cmd.takePhoto s
        | MakePhoto (Finished (Ok _)), PhotoInProgress s ->
            { s with Photo = TakePhoto InProgress }, Cmd.none
        | MakePhoto (Finished (Error ex)), PhotoInProgress s ->
            { s with Photo = TakePhoto (Resolved (Error ex)) }, Cmd.none
        | _, _ ->
            Browser.Dom.console.warn ("The combination of state and message is not expected", state, msg)
            state, Cmd.none

module View =
    open Types
    open Feliz
    open Feliz.Bulma

    let cameraIcon = Bulma.icon [ Html.i [ prop.className [ MdiCss.Mdi; MdiCss.Mdi24Px; MdiCss.MdiCamera ] ] ]
    let view (state: State) dispatch =
        match state.Photo with
        | RequestPhoto HasNotStartedYet ->
            Bulma.button.button [
                color.isPrimary
                prop.onClick (fun x -> x.preventDefault(); dispatch (RequestPhotoPermission Started))
                prop.children [
                    cameraIcon
                    Html.span (t "photo.request.permission")
                ]
            ]
        | RequestPhoto InProgress ->
            Bulma.field.div [
                field.hasAddons
                prop.children [
                    Bulma.control.p [
                        Bulma.button.button [
                            color.isDanger
                            prop.onClick (fun x -> x.preventDefault(); dispatch ResetPhoto)
                            prop.children [
                                Bulma.icon [ Html.i [ prop.className [ MdiCss.Mdi; MdiCss.Mdi24Px; MdiCss.MdiCancel ] ] ]
                            ]
                        ]
                    ]
                    Bulma.control.p [
                        Bulma.button.button [
                            prop.text (t "photo.request.permission")
                            color.isPrimary
                            button.isLoading
                        ]
                    ]
                ]
            ]
        | RequestPhoto (Resolved (Ok Allowed)) ->
            Bulma.button.button [
                color.isSuccess
                prop.onClick (fun x -> x.preventDefault(); dispatch (MakePhoto Started))
                prop.children [
                    cameraIcon
                    Html.span (t "photo.take")
                ]
            ]
        | RequestPhoto (Resolved (Ok Denied)) ->
            Bulma.button.button [
                color.isPrimary
                prop.onClick (fun x -> x.preventDefault(); dispatch (RequestPhotoPermission Started))
                prop.children [
                    cameraIcon
                    Html.span (t "photo.denied.request.again")
                ]
            ]
        | RequestPhoto (Resolved (Error _)) ->
            Bulma.button.button [
                prop.text (t "photo.failed.request.again")
                color.isWarning
                prop.onClick (fun x -> x.preventDefault(); dispatch (RequestPhotoPermission Started))
            ]
        | TakePhoto HasNotStartedYet ->
            Bulma.button.button [
                color.isSuccess
                prop.onClick (fun x -> x.preventDefault(); dispatch (MakePhoto Started))
                prop.children [
                    cameraIcon
                    Html.span (t "photo.take")
                ]
            ]
        | TakePhoto InProgress ->
            Bulma.field.div [
                field.hasAddons
                prop.children [
                    Bulma.control.p [
                        Bulma.button.button [
                            color.isDanger
                            prop.onClick (fun x -> x.preventDefault(); dispatch ResetPhoto)
                            prop.children [
                                Bulma.icon [ Html.i [ prop.className [ MdiCss.Mdi; MdiCss.Mdi24Px; MdiCss.MdiCancel ] ] ]
                            ]
                        ]
                    ]
                    Bulma.control.p [
                        Bulma.button.button [
                            prop.text (t "photo.taking")
                            color.isPrimary
                            button.isLoading
                        ]
                    ]
                ]
            ]
        | TakePhoto (Resolved (Ok _)) ->
            Bulma.button.button [
                color.isPrimary
                prop.onClick (fun x -> x.preventDefault(); dispatch (RequestPhotoPermission Started))
                prop.children [
                    cameraIcon
                    Html.span (t "photo.request.permission")
                ]
            ]
        | TakePhoto (Resolved (Error _)) ->
            Bulma.button.button [
                color.isWarning
                prop.onClick (fun x -> x.preventDefault(); dispatch (MakePhoto Started))
                prop.children [
                    cameraIcon
                    Html.span (t "photo.failed.take.again")
                ]
            ]

module Component =
    open Feliz
    open Feliz.UseElmish
    open State
    open View

    type PhotoProps = {
        Context: Shared.VideoCall.Context
        RtmChat: Rtm.Chat
    }

    let photo = React.functionComponent(fun (props: PhotoProps) ->
        let state, dispatch = React.useElmish(init props.Context props.RtmChat, update, [||])
        view state dispatch
    )