module Localization

open Fable.Core
open Fable.Core.JS

[<Erase>]
type Translate() =
    [<Emit("$0($1);")>]
    member _.translate(key:string): string = jsNative
    [<Emit("$0($1, $2);")>]
    member _.translate(key: string, ?options: obj): string = jsNative

[<Erase>]
type I18 =
    [<Emit("$0.getFixedT(null, $1);")>]
    abstract ns: ns: string -> Translate
    abstract changeLanguage: lng: string -> unit
    abstract init: obj -> Promise<unit>

[<ImportDefault("i18next")>]
let i18n : I18 = jsNative

let private init lng : Promise<unit> =
    i18n.init({|
                resources = Resources.resources
                lng = lng
                keySeparator = false
#if DEBUG
                debug = true
#endif
                |})
let currentLanguage () =
    match Browser.WebStorage.localStorage.getItem "lng" with
    | "en" -> "en"
    | "se" -> "se"
    | _ -> "se"

let initparams = {|
    resources = Resources.resources
    lng = "sv"
    keySeparator = false
#if DEBUG
    debug = true
#endif
|}

let initialize = async {
    try
        let init = i18n.init(initparams)
        return! init |> Async.AwaitPromise
    with
        | ex -> Browser.Dom.console.log("Error while initializing i18next.", ex.Message)
}

initialize |> Async.StartImmediate

let ns (s: string) = i18n.ns(s).translate
