﻿module ProfileSettings

open System
open Shared

let t = Localization.ns("users")

module Types =
    open Shared.Users.Queries

    type State = {
        User: Deferred<Result<UserDetail, string>>
    }

    type Msg =
        | LoadUser of AsyncOperationStatus<Result<UserDetail, string>>

    let roleToString = function
        | Agent -> t "role.agent"
        | SuperVisor -> t "role.supervisor"

module Cmd =
    open Types
    open Elmish
    let loadUser() : Cmd<Msg> =
        async {
            try
                let! userDetails = Communication.usersApi().profileSettings()
                return LoadUser (Finished (Ok userDetails))
            with ex ->
                return LoadUser (Finished (Error ex.Message))
        }
        |> Cmd.fromAsync

module State =
    open Types
    open Elmish

    let withUser user (state:State) =
        { state with User = Resolved (Ok user) }

    let init()  =
        {
            User = HasNotStartedYet
        }, Cmd.ofMsg (LoadUser Started)

    let update (msg: Msg) (state: State) =
        match msg, state.User with
        | LoadUser (Started), HasNotStartedYet ->
            { state with User = InProgress}, Cmd.loadUser()
        | LoadUser (Finished x), InProgress ->
            { state with User = Resolved x}, Cmd.none
        | _, _ ->
            Browser.Dom.console.warn (sprintf "The combination of state and message is not expected", state, msg)
            state, Cmd.none

module View =
    open Types
    open Feliz
    open Feliz.Bulma
    open Shared.Users.Queries
    open Shared.PrimitiveTypes

    let row (label: string) (input: ReactElement list) =
        Bulma.columns [
            prop.style [
                style.marginBottom (length.rem 0.75)
            ]
            Bulma.columns.isGapless
            prop.children [
                Bulma.column [
                    Bulma.column.is2
                    prop.classes [ BulmaCss.``has-text-grey-light`` ]
                    prop.children [
                        Bulma.text.span label
                    ]
                ]
                Bulma.column [
                    prop.children input
                ]
            ]
        ]

    let renderToolbar (user: UserDetail) (state: State) (dispatch: Msg -> unit) =
        Bulma.level [
            prop.style[style.marginBottom 0]
            prop.children [
                Bulma.levelLeft [
                    Bulma.levelItem [
                        Bulma.title.h3 [
                            Bulma.text.isUppercase
                            prop.text (sprintf "%s %s" user.FirstName user.LastName)
                        ]
                    ]
                ]
            ]
        ]

    let renderImage (state: State) dispatch =
        let random = Random()
        Bulma.image [
            Bulma.image.is1by1
            prop.style [
                style.margin.auto
            ]
            prop.children [
                Html.img [
                    prop.style [
                        style.borderRadius (length.px 20)
                    ]
                    prop.src (sprintf "https://picsum.photos/seed/%d/180" (random.Next()))
                ]
            ]
        ]

    let renderUserInfo (user: UserDetail) dispatch =
        let classesPerStatus = function
            | Free -> [ BulmaCss.``has-text-success``; BulmaCss.``has-text-weight-bold`` ]
            | Busy -> [ BulmaCss.``has-text-danger``; BulmaCss.``has-text-weight-bold`` ]
        Html.div [
            prop.style [style.marginBottom (length.em 2)]
            prop.children[
                row (t "title.role") [ Html.b (roleToString user.Role) ]
                row (t "title.title") [ Html.b user.Title ]
                row (t "title.email") [ Html.a [ prop.text user.Email] ]
                row (t "title.created") [ Html.b (Components.Common.renderDate user.Created) ]
            ]
        ]

    let renderInfo (user: UserDetail) dispatch =
        Html.div [
            prop.children [
                Bulma.columns [
                    prop.children [
                        Bulma.column [
                            renderUserInfo user dispatch
                        ]
                    ]
                ]
            ]
        ]
    let changePasswordComponent (userId: UserId) =
        printfn "in changePasswordComponent"
        React.fragment [
            Bulma.title.h6 [
                //Bulma.text.isUppercase
                prop.text (t "change.password")
            ]
            Components.ChangePassword.ChangePasswordComponent(userId)
        ]
    let renderChangePasswordSection (user: UserDetail) dispatch =
        Html.div [
            prop.children [
                Bulma.columns [
                    prop.children [
                        Bulma.column [
                            changePasswordComponent user.Id
                        ]
                    ]
                ]
            ]
        ]

    let render (state: State) (dispatch: Msg -> unit) =
        match state.User with
        | HasNotStartedYet
        | InProgress ->
            Bulma.hero [
                Bulma.hero.isFullHeightWithNavbar
                prop.children [
                    Bulma.heroBody [
                        Components.Loader.medium (t "user.loading")
                    ]
                ]
            ]
        | Resolved (Ok user) ->
            Html.div [
                Html.div [
                    prop.classes[AppCss.Toolbar]
                    prop.children[renderToolbar user state dispatch]
                ]
                renderInfo user dispatch
                renderChangePasswordSection user dispatch
            ]
        | Resolved (Error e) ->
            Bulma.section [
                Bulma.container [
                    Bulma.notification [
                        Bulma.color.isDanger
                        prop.text e
                    ]
                ]
            ]

module Component =
    open Feliz
    open Feliz.ElmishComponents
    open State
    open View

    let userDetails () =
        React.elmishComponent("ProfileSettings", init(), update, render)