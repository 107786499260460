namespace Data.Export

open System
open Feliz.Bulma
open Elmish
type Inspector={
    Name: string
    Count: int
    Distance: int
    Time: TimeSpan
}

type State = {
    DatePeriod: DateTime list option
    ExpData: Deferred<Result<Inspector list, string>>
}

type Msg =
    | ShowData of AsyncOperationStatus<Result<Inspector list, string>>
    | DatePeriodChanged of DateTime list option

module State =
    let t = Localization.ns ("exportData")
    let init () =
        {DatePeriod = None; ExpData = HasNotStartedYet }, Cmd.none

    let update (msg: Msg) (state: State)  =
        match msg with
        | ShowData Started ->
            let load: Async<Msg> = async {
                try
                    do! Async.Sleep 500
                    let inspectors = [
                        for i in [1..3] |> List.map string do
                            { Name = "Name"+i; Count = 2 ; Distance= 1 ; Time = TimeSpan(2,14,15) }
                    ]
                    return ShowData (Finished (Ok inspectors))
                with
                    ex -> return ShowData (Finished(Error ex.Message))
            }
            { state with ExpData = InProgress }, Cmd.fromAsync load
        | ShowData (Finished inspectors) ->
            {state with ExpData = Resolved inspectors}, Cmd.none
        | DatePeriodChanged period ->
            { state with DatePeriod = period}, Cmd.none

module View =
    open Feliz
    open Feliz.UseElmish
    open State

    // for input line, where are choosing dates
    let renderToolbar(state: State) (dispatch:Msg -> unit) =
        Bulma.level[
            prop.classes [BulmaCss.``is-4-desktop``; BulmaCss.``is-6-tablet``]
            prop.style [ style.margin.auto ]
            prop.children [
                Bulma.levelLeft[
                    Bulma.levelItem[
                        Bulma.field.div[
                            field.hasAddons
                            prop.style [style.justifyContent.center]
                            prop.children [
                                Bulma.control.div[
                                    Bulma.control.hasIconsLeft
                                    prop.classes [BulmaCss.``is-expanded``]
                                    prop.children[
                                        Flatpickr.flatpickr [
                                            Flatpickr.Placeholder (t "placeholder.selectDate")
                                            Flatpickr.SelectionMode Flatpickr.Mode.Range
                                            Flatpickr.DateFormat("M d Y")
                                            Flatpickr.custom "value" (state.DatePeriod |> Option.map (fun p -> p |> List.map(fun x -> x.ToLocalTime()) |>List.toArray)) false
                                            Flatpickr.custom "locale" {|firstDayOfWeek= 1|} true
                                            Flatpickr.ClassName "input"
                                            Flatpickr.OnManyChanged (fun period ->
                                                match period with
                                                | []|[_] -> ()
                                                | _ -> dispatch (DatePeriodChanged (Some period))
                                            )
                                            if Localization.currentLanguage () = "se" then
                                                Flatpickr.Locale FlatpickrExtensions.Locales.swedish
                                        ]
                                        Bulma.icon[
                                            Bulma.icon.isLeft
                                            color.hasTextPrimary
                                            prop.children[
                                                Html.i[
                                                    prop.classes[MdiCss.Mdi; MdiCss.Mdi24Px; MdiCss.MdiCalendarOutline]
                                                ]
                                            ]
                                        ]
                                    ]
                                ]

                                Bulma.control.div [
                                    Bulma.button.button [
                                        Bulma.color.hasTextGrey
                                        button.isOutlined
                                        prop.text "Rensa"
                                        prop.onClick(fun x ->
                                            x.preventDefault();
                                            dispatch (DatePeriodChanged None)
                                        )
                                    ]
                                ]
                            ]
                        ]
                    ]
                ]

                Bulma.levelRight[
                    Bulma.levelItem[
                        if state.ExpData=HasNotStartedYet then
                            Bulma.field.div [
                                Bulma.control.div [
                                    Bulma.button.a [
                                        color.isPrimary
                                        prop.className "button-shadow"
                                        Bulma.button.isFullWidth
                                        prop.text (t "button.result")
                                        prop.onClick(fun x -> x.preventDefault();dispatch (ShowData Started))
                                    ]
                                ]
                            ]
                        else
                            Bulma.field.div [
                                Bulma.field.isGrouped
                                prop.children[
                                    Bulma.control.p [
                                        Bulma.button.button [
                                            color.isBlack
                                            prop.className "button-shadow"
                                            Bulma.button.isFullWidth
                                            prop.text (t "button.update")
                                            prop.onClick(fun x -> x.preventDefault();dispatch (ShowData Started))
                                        ]
                                    ]
                                    Bulma.control.p [
                                            Bulma.button.button [
                                                color.isPrimary
                                                prop.className "button-shadow"
                                                Bulma.button.isFullWidth
                                                prop.text (t "button.savePdf")
                                            ]
                                    ]
                                ]
                            ]
                    ]
                ]
            ]
        ]

    // for data about inspector
    let renderTable (inspectors:Inspector list) dispatch =
        Bulma.table [
            Bulma.table.isFullWidth
            Bulma.table.isHoverable
            prop.style[
                style.marginTop 30
            ]
            prop.children[
                Html.thead[
                    prop.style[
                        style.borderBottomWidth 2
                        style.borderBottomStyle borderStyle.solid
                        style.verticalAlign.middle
                    ]
                    prop.children[
                        Html.tr[
                            Html.th (t "title.inspector")
                            Html.th (t "title.numberOfJob")
                            Html.th [
                                prop.classes[BulmaCss.``has-text-right``]
                                prop.text (t "title.mileage")
                            ]
                            Html.th [
                                prop.classes[BulmaCss.``has-text-right``]
                                prop.text (t "title.time")
                            ]
                        ]
                    ]
                ]
                Html.tfoot[
                    prop.style [
                        style.borderColor "#E5E5E5"
                        style.borderTopWidth 2
                        style.borderTopStyle borderStyle.solid
                        style.verticalAlign.middle
                    ]
                    prop.children[
                        Html.tr[
                            prop.children[
                                Html.td[
                                    prop.text (t "table.total")
                                ]
                                Html.td [
                                    prop.text("6")
                                ]
                                Html.td [
                                    prop.classes [BulmaCss.``has-text-right``]
                                    prop.text("3 km")
                                ]
                                Html.td [
                                    prop.text("6 timmar 42 min")
                                ]
                            ]
                        ]
                    ]
                ]
                Html.tbody [
                    prop.children[
                        for inspector in inspectors do
                            Html.tr[
                                prop.children [
                                    Html.td inspector.Name
                                    Html.td inspector.Count
                                    Html.td [
                                        prop.classes [BulmaCss.``has-text-right``]
                                        prop.text(sprintf "%A km" inspector.Distance)
                                    ]
                                    Html.td [
                                        prop.text(string inspector.Time.Hours + " " + t "table.time.hours" + string inspector.Time.Minutes + " " + t "table.time.minutes")
                                    ]
                                ]
                            ]
                    ]
                ]
            ]
        ]

    let renderHead (state:State) dispatch =
        Bulma.level[
            prop.classes [BulmaCss.``is-4-desktop``; BulmaCss.``is-6-tablet``]
            prop.style [ style.margin.auto ]
            prop.children[
                Bulma.levelItem[
                    Bulma.container[
                        prop.children[
                            Bulma.table[
                                Bulma.table.isFullWidth
                                prop.style[
                                    style.marginTop 30
                                ]
                                prop.children[
                                    Html.thead[
                                        prop.children[
                                            Html.tr[
                                                Html.th (t "title.inspector")
                                                Html.th (t "title.numberOfJob")
                                                Html.th [
                                                    prop.classes[BulmaCss.``has-text-right``]
                                                    prop.text (t "title.mileage")
                                                    ]
                                                Html.th [
                                                    prop.classes[BulmaCss.``has-text-right``]
                                                    prop.text (t "title.time")
                                                ]
                                            ]
                                        ]
                                    ]
                                ]
                            ]
                            Html.div[
                                prop.style[
                                    style.color "#bfbfbf"
                                    style.textAlign.center
                                    style.fontWeight 500
                                ]
                                prop.text(t "page.text")
                            ]
                        ]
                    ]
                ]
            ]
        ]

    let render (state: State) dispatch =
        match state.ExpData with
            | HasNotStartedYet ->
                Bulma.container [
                    Html.div[
                        prop.classes [AppCss.Toolbar]
                        prop.children[ renderToolbar state dispatch]
                    ]
                    renderHead state dispatch
                ]
            | InProgress ->Components.Loader.medium (t "loading")
            | Resolved (Ok inspector) ->
                Bulma.container [
                    Html.div[
                        prop.classes [AppCss.Toolbar]
                        prop.children[ renderToolbar state dispatch]
                    ]
                    renderTable inspector dispatch
                ]
            | Resolved (Error error) ->
                Bulma.notification [
                    Bulma.color.isDanger
                    prop.text "ex.Message"
                ]

    let exportdata =
        React.functionComponent (fun () ->
        let state, dispatch = React.useElmish(init (), update, [| |])
        render state dispatch
    )