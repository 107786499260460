module User.Register

open Shared.PrimitiveTypes

module Types =

    let t = Localization.ns ("registerUser")

    type Form = {
        FirstName: string
        LastName: string
        Title: string
        Email: string
    }

    type State = {
        User: Form
        SaveResult: Deferred<Result<unit, string>>
    }

    type Msg =
        | FirstNameChanged of string
        | LastNameChanged of string
        | TitleChanged of string
        | EmailChanged of string
        | Register of AsyncOperationStatus<Result<UserId, string>>

module Cmd =
    open Elmish
    open Types
    open Communication

    let registerUser (form: Form): Cmd<Msg> =
        async {
            try
                let user : Shared.Users.RegisterUser = {
                    FirstName = form.FirstName
                    LastName = form.LastName
                    Title = form.Title
                    Email = form.Email
                }
                let! userId = usersApi().register user |> Remoting.handleNonAuth
                return Register (Finished (userId |> Result.mapError Components.Common.TranslatedErrors.ServerError.explainTranslation))
            with
                ex -> return Register (Finished (Error ex.Message))
        }
        |> Cmd.fromAsync

module State =
    open Types
    open Elmish
    open Extensions.View

    let defaultUser = { FirstName = ""; LastName = ""; Title = ""; Email = "" }

    let init () =
        { User = defaultUser; SaveResult = HasNotStartedYet}, Cmd.none

    let withUser (user: Form) (state: State) =
        { state with User = user }

    let update (msg: Msg) (state: State)  =
        match msg with
        | FirstNameChanged s ->
            state |> withUser { state.User with FirstName = s }, Cmd.none
        | LastNameChanged s ->
            state |> withUser { state.User with LastName = s }, Cmd.none
        | TitleChanged s ->
            state |> withUser { state.User with Title = s }, Cmd.none
        | EmailChanged s ->
            state |> withUser { state.User with Email = s }, Cmd.none
        | Register (Started) ->
            { state with SaveResult = InProgress }, Cmd.registerUser state.User
        | Register (Finished (Ok userId)) ->
            { state with SaveResult = Resolved (Ok ()) }, Router.navigateTo (Router.Route.UserDetails (UserId.value userId))
        | Register (Finished (Error error)) ->
            { state with SaveResult = Resolved (Error error) }, Cmd.toastServerError "Register user" error

module View =
    open Types
    open Feliz
    open Feliz.Bulma

    let renderToolbar (state: State) (dispatch: Msg -> unit) =
        let isSaving = Deferred.inProgress state.SaveResult
        Bulma.level [
            prop.children [
                Bulma.levelLeft [
                    Bulma.levelItem [
                        Bulma.title.h3 [
                            Bulma.text.isUppercase
                            prop.text (t "title")
                        ]
                    ]
                ]
                Bulma.levelRight[
                    Bulma.levelItem [
                        Bulma.button.a [
                            color.isPrimary
                            prop.className "button-shadow"
                            if isSaving then Bulma.button.isLoading
                            prop.children [
                                Bulma.text.span (t "button.register")
                            ]
                            prop.onClick (fun x -> x.preventDefault(); dispatch (Register Started))
                        ]
                    ]
                ]
            ]
        ]

    let row (label: string) (input: ReactElement list) =
        Bulma.columns [
            prop.style [
                style.marginBottom (length.rem 0.75)
            ]
            Bulma.columns.isGapless
            Bulma.columns.isVCentered
            prop.children [
                Bulma.column [
                    Bulma.column.is4
                    Bulma.color.hasTextGrey
                    prop.text label
                ]
                Bulma.column [
                    prop.children input
                ]
            ]
        ]

    let renderForm (state: State) dispatch =
        let inProgress = Deferred.inProgress state.SaveResult
        Bulma.column [
            Bulma.column.is6
            prop.style[style.paddingLeft 0]
            prop.children [
                Html.fieldSet [
                    prop.children [
                        row (t "user.firstName") [
                            Bulma.input.text [
                                prop.autoFocus true
                                prop.valueOrDefault state.User.FirstName
                                prop.onChange (FirstNameChanged >> dispatch)
                                prop.disabled inProgress
                            ]
                        ]
                        row (t "user.lastName") [
                            Bulma.input.text [
                                prop.valueOrDefault state.User.LastName
                                prop.onChange (LastNameChanged >> dispatch)
                                prop.disabled inProgress
                            ]
                        ]
                        row (t "user.title") [
                            Bulma.input.text [
                                prop.valueOrDefault state.User.Title
                                prop.onChange (TitleChanged >> dispatch)
                                prop.disabled inProgress
                            ]
                        ]
                        row (t "title.email") [
                            Bulma.input.email [
                                prop.valueOrDefault state.User.Email
                                prop.onChange (EmailChanged >> dispatch)
                                prop.disabled inProgress
                            ]
                        ]
                    ]
                ]
            ]
        ]

    let render (state: State) dispatch =
        Html.div [
            Html.div [
                prop.classes[AppCss.Toolbar]
                prop.children[
                    Bulma.column [
                        column.is6
                        prop.style[style.paddingLeft 0]
                        prop.children [ renderToolbar state dispatch ]
                    ]
                ]
            ]
            renderForm state dispatch
        ]

module Component =
    open Feliz
    open Feliz.UseElmish

    type AddInspectorProps = {
        UserId: int
    }

    let registerUser = React.functionComponent(fun (props: AddInspectorProps) ->
        let state, dispatch = React.useElmish(State.init, State.update, [||])

        Html.div [
            View.render state dispatch
        ]
    )


