module Components.Autocomplete

open System.Collections.Generic
open System.Threading
open Elmish
open Extensions
open Feliz

type TextTranslations = {
    Placeholder: string
    StartTypeToSearch: unit -> string
    NoItemsFound: unit -> string
    FoundItems: int -> int -> string
}

type FoundResult = {
    Total: int
    Items: KeyValuePair<string, string> list
}

type Props = {
    DebounceTimeout: int
    Search: string -> Async<Result<FoundResult, string>>
    OnSelect: string option -> unit
    SearchText: string
    Localization: TextTranslations
}
with static member Default : Props = {
        DebounceTimeout = 500
        Search = fun _ -> async { return Ok { Total = 0; Items = []}}
        OnSelect = fun _ -> ()
        SearchText = ""
        Localization = {
            Placeholder = "Search case"
            StartTypeToSearch = fun () -> "Start type to search"
            NoItemsFound = fun () -> "No items found"
            FoundItems = fun found total -> sprintf "Shown %d out of %d" found total
        }
    }

type Model = {
    SearchResult: Deferred<Result<FoundResult, string>>
    SearchString: string
    DebounceHandler: CancellationTokenSource option
    SelectedIndex: int
    IsActive: bool
}

type Msg =
    | KeyboardEvent of int
    | QueryResult of AsyncOperationStatus<Result<FoundResult, string>>
    | SearchStringChanged of string
    | ChaneSelectedIndex of int
    | SelectItem

let inline private nextIndex (l: _ list) (current: int)  =
    l |> List.tryItem (current + 1) |> Option.map (fun _ -> current + 1) |> Option.defaultValue current
let inline private previousIndex (l: _ list) (current: int)  =
    l |> List.tryItem (current - 1) |> Option.map (fun _ -> current - 1)  |> Option.defaultValue current

let private searchResult (state: Model) =
    match state.SearchResult with
    | Resolved (Ok xs) -> xs.Items
    | _ -> []

let private selectedItem (state: Model) =
    state.SearchResult
    |> DeferredResult.fold (fun x -> x.Items |> List.tryItem state.SelectedIndex) (fun _ -> None) (fun _ -> None)

let init searchString =
    {
        SearchResult = HasNotStartedYet
        SearchString = searchString
        DebounceHandler = None
        SelectedIndex = -1
        IsActive = false
    }, Cmd.none

let update (props: Props) msg (state: Model)  =
    match msg with
    | QueryResult Started ->
        let cmd: Async<_> = async {
            try
                let! result = props.Search state.SearchString
                return QueryResult (Finished result)
            with
                ex -> return QueryResult (Finished (Error ex.Message))
        }
        { state with SearchResult = InProgress; SelectedIndex = -1; IsActive = true },
        Cmd.batch [Cmd.fromAsync cmd]
    | QueryResult (Finished (Ok entries)) ->
        { state with SearchResult = Resolved (Ok entries); DebounceHandler = None }, Cmd.none
    | QueryResult (Finished (Error error)) ->
        { state with SearchResult = Resolved (Error error); DebounceHandler = None }, Cmd.none
    | SearchStringChanged s ->
        { state with SearchString = s }, Cmd.none
    | ChaneSelectedIndex index ->
        { state with SelectedIndex = index }, Cmd.none
    | SelectItem ->
        let selected = selectedItem state
        props.OnSelect (selected |> Option.map KeyValuePair.key)
        let selectedText =
            selected
            |> Option.map KeyValuePair.value
            |> Option.defaultValue ""
        { state with IsActive = false; SearchString = selectedText }, Cmd.none
    | KeyboardEvent code ->
        state.DebounceHandler |> Option.iter (fun x -> x.Cancel())
        match code with
        | 13 -> // enter
            state, Cmd.ofMsg SelectItem
        | 38 -> // up
            { state with SelectedIndex = previousIndex (searchResult state) state.SelectedIndex }, Cmd.none
        | 40 -> // down
            { state with SelectedIndex = nextIndex (searchResult state) state.SelectedIndex }, Cmd.none
        | 9  -> // tab
            state, Cmd.none
        | 27 -> // escape
            { state with SearchString = ""; SelectedIndex = -1}, Cmd.ofMsg SelectItem
        | _ ->
            let x = async {
                do! Async.Sleep(props.DebounceTimeout)
                return QueryResult Started
            }
            let cmd, cs = Cmd.fromAsyncCsp x
            { state with DebounceHandler = Some cs }, cmd

open Feliz.Bulma
open Feliz.UseElmish

let private renderExtraInfo (props: Props) (model: Model) =
    let infoText (s: string) =
        Bulma.text.div [
            size.isSize7
            color.hasTextGrey
            text.hasTextCentered
            prop.text s
        ]
    match model.SearchResult with
    | HasNotStartedYet ->
        Bulma.dropdownItem.div [
            infoText (props.Localization.StartTypeToSearch())
        ]
    | Resolved (Ok xs) when xs.Items.Length = 0 ->
        Bulma.dropdownItem.div [
            infoText (props.Localization.NoItemsFound())
        ]
    | Resolved (Ok xs) when xs.Items.Length > 0 ->
        Bulma.dropdownItem.div [
            infoText (props.Localization.FoundItems xs.Items.Length xs.Total)
        ]
    | Resolved (Error e) ->
        Bulma.dropdownItem.div [
            Bulma.text.span [
                color.hasTextDanger
                prop.text e
            ]
        ]
    | _ -> Html.none

let private renderItems (model: Model) dispatch =
    match model.SearchResult with
    | Resolved (Ok xs) ->
        [
            for index, KeyValue (_, item) in xs.Items |> List.indexed do
                Bulma.dropdownItem.a [
                    if index = model.SelectedIndex then dropdown.isActive
                    prop.children [
                        Bulma.text.span item
                    ]
                    prop.onMouseOver (fun x -> x.preventDefault(); dispatch (ChaneSelectedIndex index))
                ]
        ]
    | _ -> [Html.none]

let autocomplete = React.functionComponent<Props>(fun props ->
    let model, dispatch = React.useElmish(init props.SearchText, update props, [||])

    Bulma.dropdown [
        if model.IsActive then dropdown.isActive
        prop.style [ style.width (length.percent 100) ]
        prop.children [
            Bulma.dropdownTrigger [
                prop.style [ style.width (length.percent 100) ]
                prop.children [
                    Bulma.field.div [
                        Bulma.control.div [
                            if Deferred.inProgress model.SearchResult then control.isLoading
                            prop.children [
                                Bulma.input.text [
                                    prop.placeholder props.Localization.Placeholder
                                    prop.valueOrDefault model.SearchString
                                    prop.onChange (SearchStringChanged >> dispatch)
                                    prop.onKeyUp (fun ev -> dispatch (ev.keyCode |> int |> KeyboardEvent))
                                    prop.onFocus (fun _ -> dispatch (QueryResult Started))
                                    prop.onBlur (fun _ -> dispatch SelectItem)
                                ]
                            ]
                        ]
                    ]
                ]
            ]
            Bulma.dropdownMenu [
                Bulma.dropdownContent [
                    yield! renderItems model dispatch
                    Bulma.dropdownDivider []
                    renderExtraInfo props model
                ]
            ]
        ]
    ]
)
