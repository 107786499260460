﻿module Components.ChangePassword

open Common
open Shared.Users
open Shared.PrimitiveTypes

let u = Localization.ns("users")

type Form = {
    CurrentPassword: string
    NewPassword: string
    RepeatNewPassword: string
}

type State = {
    UserId: UserId
    Form: Form
    FormErrors: Map<string, string list >
    IsNeedValidation: bool
    ChangePasswordOperation: Deferred<Result<unit, string>>
}

type Msg =
    | CurrentPasswordChanged of string
    | NewPasswordChanged of string
    | RepeatNewPasswordChanged of string
    | ChangePassword of AsyncOperationStatus<Result<unit, string>>

module Validation =
    open System
    open Fable.Validation.Core

    let validate (formInfo: Form) =
        let testerCurrentPassword pass =
            (pass <> "")

        let testerNewPassword value =
            ((value = "") && (formInfo.CurrentPassword = "") && (formInfo.RepeatNewPassword = ""))
            ||
            (String.IsNullOrWhiteSpace value |> not)
            && ((value.Length >= 8)
            && (value |> Seq.exists Char.IsDigit)
            && (value |> Seq.exists Char.IsLetter)
            && (value <> formInfo.CurrentPassword))

        let testerPasswordConfirmation confirmPassword =
            ((confirmPassword = "") && (formInfo.CurrentPassword = "") && (formInfo.NewPassword = ""))
            || formInfo.NewPassword = confirmPassword

        all <| fun t ->
            { CurrentPassword = t.Test "CurrentPassword" formInfo.CurrentPassword
                    |> t.IsValid testerCurrentPassword (u "error.password.required")
                    |> t.End
              NewPassword = t.Test "NewPassword" formInfo.NewPassword
                    |> t.IsValid testerNewPassword (u "error.password")
                    |> t.End
              RepeatNewPassword = t.Test "RepeatNewPassword" formInfo.RepeatNewPassword
                    |> t.IsValid testerPasswordConfirmation (u "error.passwordConfirmation")
                    |> t.End
            }

    let validateIf (x: Form) needValidate =
        if needValidate then
            match validate x with
            | Ok x -> Some Map.empty
            | Error errors -> Some errors
        else Some Map.empty

open Elmish
open Extensions.View

let changePassword (state: State) =
    async {
        try
            let request: ChangePassword =
                { CurrentPassword = state.Form.CurrentPassword
                  NewPassword = state.Form.NewPassword }
            match! Communication.usersApi().changePassword request with
            | Ok () -> return ChangePassword (Finished (Ok ()))
            | Error error ->
                let er = Components.Common.TranslatedErrors.ServerError.explainTranslation error
                return ChangePassword (Finished (Error er))
        with
            ex -> return ChangePassword (Finished (Error ex.Message))
    }

let init userId () =
    { UserId = userId
      FormErrors = Map.empty
      IsNeedValidation = false
      Form =
          { CurrentPassword = ""
            NewPassword = ""
            RepeatNewPassword = "" }
      ChangePasswordOperation = HasNotStartedYet }, Cmd.none

let update (msg: Msg) (state: State) =
    match msg with
    | CurrentPasswordChanged x ->
        let newForm = { state.Form with CurrentPassword = x}
        let errors = Validation.validateIf newForm state.IsNeedValidation
        {state with Form = newForm; FormErrors = errors.Value} , Cmd.none
    | NewPasswordChanged x ->
        let newForm = { state.Form with NewPassword = x}
        let errors = Validation.validateIf newForm state.IsNeedValidation
        {state with Form = newForm; FormErrors = errors.Value} , Cmd.none
    | RepeatNewPasswordChanged x ->
        let newForm = { state.Form with RepeatNewPassword = x}
        let errors = Validation.validateIf newForm state.IsNeedValidation
        {state with Form = newForm; FormErrors = errors.Value} , Cmd.none
    | ChangePassword Started ->
        match Validation.validate state.Form with
        | Ok form ->
            { state with
                ChangePasswordOperation = InProgress
                FormErrors = Map.empty;
                IsNeedValidation = true }, Cmd.fromAsync (changePassword state)
        | Error errors ->
            { state with
                ChangePasswordOperation = HasNotStartedYet
                FormErrors = errors
                IsNeedValidation = true}, Cmd.none
    | ChangePassword (Finished (Ok x)) ->
        let newForm = { state.Form with
                            CurrentPassword = ""
                            NewPassword = ""
                            RepeatNewPassword = "" }
        { state with Form = newForm; ChangePasswordOperation = Resolved (Ok ()) }, Cmd.toastSuccess "Password was changed successfully"
    | ChangePassword (Finished (Error error)) ->
        { state with ChangePasswordOperation = Resolved (Error error) }, Cmd.toastServerError "Password changing" error

open Feliz
open Feliz.Bulma
open Feliz.UseElmish
open Common.Form
open Form

let p = Localization.i18n.ns("profileSettings")

let row (label: string) (input: ReactElement list) =
    Bulma.columns [
        prop.style [
            style.marginBottom (length.rem 0.75)
        ]
        Bulma.columns.isGapless
        prop.children [
            Bulma.column [
                Bulma.column.is2
                prop.classes [ BulmaCss.``has-text-grey-light`` ]
                prop.children [
                    Bulma.text.span label
                ]
            ]
            Bulma.column [
                Bulma.column.is3
                prop.children input
            ]
        ]
    ]
let ChangePasswordComponent = React.functionComponent(fun (userId: UserId) ->
    let state, dispatch = React.useElmish(init userId, update, [| box userId|])

    let fieldStyleOnError =
        [ style.borderColor.red
          style.boxShadow.none
          style.borderRadius 4 ]

    let serverError =
        match state.ChangePasswordOperation with
        | Resolved (Error error) -> error
        | _ -> ""

    let oldPassword =
        Bulma.field.div [
            Bulma.control.div [
                prop.className BulmaCss.``has-icons-left``
                prop.children [
                    Html.input [
                        prop.style [style.borderRadius 4]
                        if state.FormErrors |> Field.hasErrors "CurrentPassword" then prop.style fieldStyleOnError
                        prop.className [BulmaCss.input;  BulmaCss.``is-small``]
                        prop.type'.password
                        prop.valueOrDefault state.Form.CurrentPassword
                        prop.onChange (CurrentPasswordChanged >> dispatch)
                        //prop.placeholder (u "currentPassword.placeholder")
                    ]
                    Bulma.help [
                        prop.style [style.color.red]
                        prop.text (state.FormErrors |> Field.errorsAsString "CurrentPassword")
                    ]
                    Html.span [
                        prop.className [BulmaCss.icon; BulmaCss.``is-small``; BulmaCss.``is-left``]
                        prop.children [
                            Html.i [
                               prop.className [MdiCss.Mdi; MdiCss.MdiLock]
                            ]
                        ]
                    ]
                ]
            ]
        ]
    let newPassword =
        Bulma.field.div [
            Bulma.control.div [
                prop.className BulmaCss.``has-icons-left``
                prop.children [
                    Html.input [
                        prop.style [style.borderRadius 4]
                        if state.FormErrors |> Field.hasErrors "NewPassword" then prop.style fieldStyleOnError
                        prop.className [BulmaCss.input;  BulmaCss.``is-small``]
                        prop.type'.password
                        prop.valueOrDefault state.Form.NewPassword
                        prop.onChange (NewPasswordChanged >> dispatch)
                        //prop.placeholder (u "newPassword.placeholder")
                    ]
                    Bulma.help [
                        prop.style [style.color.red]
                        prop.text (state.FormErrors |> Field.errorsAsString "NewPassword")
                    ]
                    Html.span [
                        prop.className [BulmaCss.icon; BulmaCss.``is-small``; BulmaCss.``is-left``]
                        prop.children [
                            Html.i [
                               prop.className [MdiCss.Mdi; MdiCss.MdiLock]
                            ]
                        ]
                    ]
                ]
            ]
        ]
    let repeatedNewPassword =
        Bulma.field.div [
            Bulma.control.div [
                prop.className BulmaCss.``has-icons-left``
                prop.children [
                    Html.input [
                        prop.style [style.borderRadius 4]
                        if state.FormErrors |> Field.hasErrors "RepeatNewPassword" then prop.style fieldStyleOnError
                        prop.className [BulmaCss.input;  BulmaCss.``is-small``]
                        prop.type'.password
                        prop.valueOrDefault state.Form.RepeatNewPassword
                        prop.onChange (RepeatNewPasswordChanged >> dispatch)
                        //prop.placeholder (u "repeatPassword.placeholder")
                    ]
                    Bulma.help [
                        prop.style [style.color.red]
                        prop.text (state.FormErrors |> Field.errorsAsString "RepeatNewPassword")
                    ]
                    Html.span [
                        prop.className [BulmaCss.icon; BulmaCss.``is-small``; BulmaCss.``is-left``]
                        prop.children [
                            Html.i [
                               prop.className [MdiCss.Mdi; MdiCss.MdiLock]
                            ]
                        ]
                    ]
                ]
            ]
        ]
    let saveChangesButton =
        Bulma.control.div [
            prop.classes [BulmaCss.``is-pulled-right``]
            prop.children [
                Bulma.button.button [
                    //button.isOutlined
                    if state.ChangePasswordOperation = Deferred.InProgress then button.isLoading
                    color.isPrimary
                    prop.text (u "button.change.password")
                    prop.onClick (fun x -> x.preventDefault(); dispatch (ChangePassword Started) )
                ]
            ]
        ]
    React.fragment [
        row (u "currentPassword.placeholder") [ oldPassword ]
        row (u "newPassword.placeholder") [ newPassword ]
        row (u "repeatPassword.placeholder") [ repeatedNewPassword ]
        Bulma.control.div [
            Bulma.help [
                color.isDanger
                prop.text serverError
            ]
        ]
        row "" [ saveChangesButton ]
    ]
)