module Components.ImageEditor

open Feliz
open Feliz.Bulma
open Components.TuiImageEditor
open FsToolkit.ErrorHandling
open Browser.Types

let imageEditor =
    React.functionComponent(fun (props: {| imageUrl: string; onSave: Blob -> Async<Result<unit, string>>; onCancel: unit -> unit |}) ->
        let editor = React.useRef<ITuiReactElement option>(None)
        let isSaving, setSaving = React.useState(false)
        let error, setError = React.useState(None)
        let save (file: Blob) =
            asyncResult {
                try
                    do setSaving true
                    do setError None
                    do! props.onSave file
                    return ()
                finally
                    do setSaving false
            }
            |> AsyncResult.foldResult id (fun x -> setError(Some x))

        let saveImage () =
            editor.current
            |> Option.map (fun x -> x.getInstance().toDataUrlAsJpeg())
            |> Option.bind Blob.ofDataUrl
            |> Option.map save
            |> Option.defaultValue (async { return () })

        let saveImage = React.useCallback(saveImage >> Async.StartImmediate)

        Html.div [
            prop.style [
                style.position.fixedRelativeToWindow
                style.top (length.px 0)
                style.left (length.px 0)
                style.zIndex 100
                style.width (length.vw 100)
                style.height (length.vh 100)
            ]
            prop.children [
                ImageEditor.imageEditor [
                    options.ref editor
                    options.cssMaxHeight 800
                    options.cssMaxWidth 1920
                    options.includeUI [
                        ui.loadImage props.imageUrl
                        ui.initMenu Menu.Icon
                        ui.menuBarPosition MenuBarPosition.Left
                        ui.uiSize (length.vw 100, length.vh 100)
                        ui.theme [
                            theme.logoHeight (length.px 0)
                            theme.logoWidth (length.px 0)
                            theme.loadButton style.display.none
                            theme.downloadButton style.display.none
                        ]
                        ui.locale Locale.``sv-SE``
                    ]
                ]
                Html.div [
                    prop.style [
                        style.position.fixedRelativeToWindow
                        style.right (length.px 26)
                        style.top (length.px 10)
                        style.zIndex 100
                    ]
                    prop.children [
                        Bulma.control.div [
                            Bulma.buttons [
                                buttons.isRight
                                prop.children [
                                    match error with
                                    | Some error ->
                                        Bulma.help [
                                            color.isDanger
                                            prop.text error
                                        ]
                                    | None -> Html.none
                                    Bulma.button.button [
                                        prop.text "Avbryt"
                                        prop.onClick (fun _ -> props.onCancel())
                                    ]
                                    Bulma.button.button [
                                        prop.text "Spara"
                                        color.isPrimary
                                        if isSaving then button.isLoading
                                        prop.onClick (fun _ -> saveImage())
                                    ]
                                ]
                            ]
                        ]
                    ]
                ]
            ]
        ]
    )
