namespace Position.Edit

module Types =

    let t = Localization.ns ("editPosition")

    type Position = {
        Id: string
        Place: string
        Address: string
    }

    type State = {
        Position: Position
        IsVisible: bool
        SaveResult: Deferred<Result<unit, exn>>
        DeleteResult: ConfirmedOperation<Result<unit, exn>>
    }

    type Msg =
        | Close
        | PlaceChanged of string
        | AddressChanged of string
        | Save of AsyncOperationStatus<Result<unit, exn>>
        | Delete of AsyncConfirmOperationStatus<Result<unit, exn>>

module State =
    open Types
    open Elmish

    let withForm address (state: State) = { state with Position = address }

    let init (address: Position) =
        { Position = address
          IsVisible = true
          SaveResult = HasNotStartedYet
          DeleteResult = NotStarted
        }, Cmd.none

    let update (onSave: Position -> unit) (onDelete: unit -> unit) (onClose: unit -> unit) (msg: Msg) (state: State) =
        match msg with
        | PlaceChanged s ->
            state |> withForm { state.Position with Place = s }, Cmd.none
        | AddressChanged s ->
            state |> withForm { state.Position with Address = s }, Cmd.none
        | Close ->
            onClose ()
            { state with IsVisible = false }, Cmd.none
        | Save Started ->
            let save: Async<Msg> = async {
                do! Async.Sleep 1000
                if (state.Position.Place.StartsWith("E")) then
                    return Save (Finished (Error (exn state.Position.Place)))
                else
                    return Save (Finished (Ok ()))
            }
            { state with SaveResult = InProgress }, Cmd.fromAsync save
        | Save (Finished (Ok _)) ->
            onSave state.Position
            { state with SaveResult = Resolved (Ok ()) }, Cmd.ofMsg Close
        | Save (Finished (Error ex)) ->
            { state with SaveResult = Resolved (Error ex) }, Cmd.none
        | Delete ConfirmationStarted ->
            { state with DeleteResult = Requested }, Cmd.none
        | Delete Canceled ->
            {state with DeleteResult = NotStarted}, Cmd.none
        | Delete Confirmed ->
            let delete: Async<Msg> = async {
                do! Async.Sleep 1000
                if (state.Position.Place.StartsWith("E")) then
                    return Delete (ConfirmationFinished (Error (exn state.Position.Place)))
                else
                    return Delete (ConfirmationFinished (Ok ()))
            }
            { state with DeleteResult = ConfirmationInProgress }, Cmd.fromAsync delete
        | Delete (ConfirmationFinished (Ok ())) ->
            let c = Cmd.OfFunc.attempt onDelete () (fun ex -> Delete (ConfirmationFinished (Error ex)))
            { state with DeleteResult = Done (Ok ()) }, Cmd.batch [c; Cmd.ofMsg Close]
        | Delete (ConfirmationFinished (Error ex)) ->
            { state with DeleteResult = Done (Error ex) }, Cmd.none

module View =
    open Types
    open Feliz
    open Feliz.Bulma
    open Feliz.Bulma.QuickView

    let renderError (state: State) dispatch =
        match state.SaveResult, state.DeleteResult with
        | Resolved (Error ex), _ ->
            Bulma.notification [
                color.isDanger
                prop.text (sprintf "Error while saving: %s" ex.Message)
            ]
        | _, Done (Error ex) ->
            Bulma.notification [
                color.isDanger
                prop.text (sprintf "Error while delete: %s" ex.Message)
            ]
        | _, _ -> Html.none

    let renderForm (state: State) dispatch =
        Bulma.section [
            prop.children [
                renderError state dispatch
                Html.fieldSet [
                    prop.disabled (Deferred.inProgress state.SaveResult || ConfirmedOperation.confirmationInProgress state.DeleteResult)
                    prop.children [
                        Bulma.field.div [
                            Bulma.label (t "label.place")
                            Bulma.control.div [
                                Bulma.input.text [
                                    prop.autoFocus true
                                    prop.valueOrDefault state.Position.Place
                                    prop.onChange (PlaceChanged >> dispatch)
                                ]
                            ]
                        ]
                        Bulma.field.div [
                            Bulma.label (t "label.address")
                            Bulma.control.div [
                                Bulma.input.text [
                                    prop.valueOrDefault state.Position.Address
                                    prop.onChange (AddressChanged >> dispatch)
                                ]
                            ]
                        ]
                    ]
                ]
            ]
        ]

    let renderToolbar (state: State) dispatch =
        let isSaving = Deferred.inProgress state.SaveResult
        let isDeleting = ConfirmedOperation.confirmationInProgress state.DeleteResult
        Bulma.section [
            Bulma.container [
                Bulma.level [
                    Bulma.levelLeft [
                        if state.IsVisible then
                            Bulma.levelItem [
                                prop.classes [ AppCss.CloseQuickview ]
                                prop.children [
                                    Bulma.button.button [
                                        color.isPrimary
                                        prop.className "button-shadow"
                                        prop.onClick (fun x -> x.preventDefault(); dispatch Close)
                                        prop.children [
                                            Bulma.icon [
                                                Html.i [
                                                    prop.classes [ MdiCss.Mdi; MdiCss.MdiArrowRight; MdiCss.Mdi24Px ]
                                                ]
                                            ]
                                        ]
                                        if isSaving || isDeleting then Bulma.button.isLoading
                                    ]
                                ]
                            ]
                        Bulma.levelItem [
                            Bulma.title.h3 (t "title")
                        ]
                    ]
                    Bulma.levelRight [
                        Bulma.levelItem [
                            Bulma.button.a [
                                prop.classes [BulmaCss.``has-text-primary``]
                                prop.children [
                                    Bulma.icon [
                                        Html.i [
                                            prop.classes [ MdiCss.Mdi; MdiCss.MdiContentSaveOutline; MdiCss.Mdi24Px ]
                                        ]
                                    ]
                                ]
                                prop.onClick (fun x -> x.preventDefault(); dispatch (Save Started))
                                if isSaving then Bulma.button.isLoading
                                prop.disabled isDeleting
                            ]
                        ]
                        Bulma.levelItem [
                            Bulma.button.button [
                                prop.classes [BulmaCss.``has-text-danger``]
                                prop.children [
                                    Bulma.icon [
                                        Html.i [
                                            prop.classes [ MdiCss.Mdi; MdiCss.MdiDeleteOutline; MdiCss.Mdi24Px ]
                                        ]
                                    ]
                                ]
                                prop.onClick (fun x -> x.preventDefault(); dispatch (Delete ConfirmationStarted))
                                if isDeleting then Bulma.button.isLoading
                                prop.disabled isSaving
                            ]
                        ]
                    ]
                ]
            ]
        ]

    let render (state: State) (dispatch: Msg -> unit) =
        if state.IsVisible then
            Html.div [
                prop.children [
                    Html.div [
                        prop.className "Dark-screen"
                    ]
                    QuickView.quickview [
                        quickview.isActive
                        prop.children [
                            QuickView.body [
                                renderToolbar state dispatch
                                QuickView.block [ renderForm state dispatch ]
                            ]
                        ]
                    ]
                    if state.DeleteResult=Requested then
                        let confirmedDel() =dispatch (Delete Confirmed)
                        let notConfirmedDel() = dispatch (Delete Canceled)
                        Components.Common.renderDeleteWindow
                            {| message = t "delete.position.confirmation.question"
                               confirmed=confirmedDel
                               notConfirmed=notConfirmedDel|}
                ]
            ]
        else
            Html.none

module Component =
    open Feliz
    open Feliz.ElmishComponents
    open State
    open View

    type EditUserPositionProps = {
        Position: Types.Position
        OnDelete: unit -> unit
        OnSave: Types.Position -> unit
        OnClose: unit -> unit
    }

    let editPosition (props: EditUserPositionProps) =
        React.elmishComponent("EditPosition", init props.Position, update props.OnSave props.OnDelete props.OnClose, render)