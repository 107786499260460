[<AutoOpen>]
module Extensions

open System.Threading
open Elmish

module Cmd =
    let fromAsync (operation: Async<'msg>) : Cmd<'msg> =
        let delayedCmd (dispatch: 'msg -> unit) : unit =
            let delayedDispatch = async {
                let! msg = operation
                dispatch msg
            }

            Async.StartImmediate delayedDispatch

        Cmd.ofSub delayedCmd

    let fromAsyncCsp (operation: Async<'msg>) : Cmd<'msg> * CancellationTokenSource =
        let cs = new CancellationTokenSource()
        let delayedCmd (dispatch: 'msg -> unit) : unit =
            let delayedDispatch = async {
                let! msg = operation
                dispatch msg
            }

            Async.StartImmediate(delayedDispatch, cs.Token)

        Cmd.ofSub delayedCmd, cs

module Async =
    let map f (computation: Async<'t>) =
        async {
            let! x = computation
            return f x
        }

    let tee f (computation: Async<'t>) =
        async {
            let! x = computation
            do f(x)
            return x
        }

module Remoting =
    open Fable.Remoting.Client
    open Fable.Core

    [<Emit("document.dispatchEvent(new Event('Fable.Remoting.Unauthorized'))")>]
    let private raiseUnauthorizedEvent(): unit = jsNative

    [<Emit("document.addEventListener('Fable.Remoting.Unauthorized', function (e) {$0() })")>]
    let private attachEvent (f: unit -> unit): unit = jsNative

    let handleNonAuth (computation: Async<'t>) =
        async {
            try
                return! computation
            with
            | :? ProxyRequestException as ex when ex.StatusCode = 401 ->
                raiseUnauthorizedEvent()
                return raise ex
        }

    let subscribe msg model  =
        let sub dispatch =
            attachEvent (fun () -> dispatch msg)
        Cmd.ofSub sub

module Events =
    open Fable.Core

    module AddressBook =
        [<Emit("document.dispatchEvent(new CustomEvent('AddressBook.Contact.Selected', { detail: {deviceId: $0}}))")>]
        let private raiseDeviceIdSelected(deviceId: string): unit = jsNative

        [<Emit("document.addEventListener('AddressBook.Contact.Selected', function (e) {$0(e.detail.deviceId) })")>]
        let private onDeviceIdSelected (f: string -> unit): unit = jsNative

        let notifyDeviceIdSelected (deviceId: string) =
            raiseDeviceIdSelected deviceId

        let subscribeToDeviceIdSelected msg =
            let sub dispatch =
                onDeviceIdSelected (fun x -> dispatch (msg x))
            Cmd.ofSub sub

type Deferred<'t> =
    | HasNotStartedYet
    | InProgress
    | Resolved of 't

type ConfirmedOperation<'t> =
    | NotStarted
    | Requested
    | ConfirmationInProgress
    | Done of 't

type AsyncOperationStatus<'t> =
    | Started
    | Finished of 't

type AsyncConfirmOperationStatus<'t> =
    | ConfirmationStarted // press delete button
    | Canceled // canceled delete operation
    | Confirmed // confirmed delete operation
    | ConfirmationFinished of 't

[<RequireQualifiedAccess>]
/// Contains utility functions to work with value of the type `Deferred<'T>`.
module Deferred =

    /// Returns whether the `Deferred<'T>` value has been resolved or not.
    let resolved = function
        | HasNotStartedYet -> false
        | InProgress -> false
        | Resolved _ -> true

    /// Returns whether the `Deferred<'T>` value has been resolved or not.
    let notStartedYet = function
        | HasNotStartedYet -> true
        | InProgress -> false
        | Resolved _ -> false

    /// Returns whether the `Deferred<'T>` value is in progress or not.
    let inProgress = function
        | HasNotStartedYet -> false
        | InProgress -> true
        | Resolved _ -> false

    /// Transforms the underlying value of the input deferred value when it exists from type to another
    let map (transform: 'T -> 'U) (deferred: Deferred<'T>) : Deferred<'U> =
        match deferred with
        | HasNotStartedYet -> HasNotStartedYet
        | InProgress -> InProgress
        | Resolved value -> Resolved (transform value)

    /// Transforms the underlying value of the input deferred value when it exists from type to another
    let iter (f: 'T -> unit) (deferred: Deferred<'T>) : unit =
        match deferred with
        | HasNotStartedYet
        | InProgress -> ()
        | Resolved value -> f value

    let fold (fDeferred: 'T -> 'U) (fOther: unit -> 'U) (deferred: Deferred<'T>) : 'U =
        match deferred with
        | HasNotStartedYet
        | InProgress -> fOther ()
        | Resolved value -> fDeferred value

    /// Verifies that a `Deferred<'T>` value is resolved and the resolved data satisfies a given requirement.
    let exists (predicate: 'T -> bool) = function
        | HasNotStartedYet -> false
        | InProgress -> false
        | Resolved value -> predicate value

    /// Like `map` but instead of transforming just the value into another type in the `Resolved` case, it will transform the value into potentially a different case of the the `Deferred<'T>` type.
    let bind (transform: 'T -> Deferred<'U>) (deferred: Deferred<'T>) : Deferred<'U> =
        match deferred with
        | HasNotStartedYet -> HasNotStartedYet
        | InProgress -> InProgress
        | Resolved value -> transform value

[<RequireQualifiedAccess>]
module ConfirmedOperation =

    /// Returns whether the `ConfirmedOperation<'T>` value is in progress or not.
    let confirmationInProgress = function
        | NotStarted -> false
        | Requested -> false
        | ConfirmationInProgress -> true
        | Done _ -> false

    let confirmationRequested = function
        | NotStarted -> false
        | Requested -> true
        | ConfirmationInProgress -> false
        | Done _ -> false

    /// Transforms the underlying value of the input deferred value when it exists from type to another
    let map (transform: 'T -> 'U) (deferred: ConfirmedOperation<'T>) : ConfirmedOperation<'U> =
        match deferred with
        | NotStarted -> NotStarted
        | Requested -> Requested
        | ConfirmationInProgress -> ConfirmationInProgress
        | Done value -> Done (transform value)

[<RequireQualifiedAccess>]
module DeferredResult =
    let map (f: 't -> 'v)
            (x: Deferred<Result<'t, 'r>>): Deferred<Result<'v, 'r>> =
        match x with
        | HasNotStartedYet -> HasNotStartedYet
        | InProgress -> InProgress
        | Resolved x -> Resolved (Result.map f x)

    let mapError (f: 'r -> 'rt)
            (x: Deferred<Result<'t, 'r>>): Deferred<Result<'t, 'rt>> =
        match x with
        | HasNotStartedYet -> HasNotStartedYet
        | InProgress -> InProgress
        | Resolved x -> Resolved (Result.mapError f x)

    let fold (fOk: 't -> 'rt) (fError: 'error -> 'rt) (fDefault: unit -> 'rt)
            (x: Deferred<Result<'t, 'error>>): 'rt =
        match x with
        | HasNotStartedYet
        | InProgress -> fDefault()
        | Resolved (Ok x) -> fOk x
        | Resolved (Error error) -> fError error

    let tee (f: 't -> unit) (x: Deferred<Result<'t, 'error>>): Deferred<Result<'t, 'error>> =
        match x with
        | HasNotStartedYet
        | InProgress -> x
        | Resolved (Ok item) -> f item; x
        | Resolved (Error _) -> x

    let isOk (x: Deferred<Result<'t, 'error>>) : bool =
        x |> fold (fun _ -> true) (fun _ -> false) (fun _ -> false)

    let isError (x: Deferred<Result<'t, 'error>>) : bool =
        x |> fold (fun _ -> false) (fun _ -> true) (fun _ -> false)

module View =
    module Cmd =
        open Elmish.Toastr

        let toastSuccess msg =
            Toastr.message msg
            |> Toastr.position TopCenter
            |> Toastr.success

        let toastWarning msg =
            Toastr.message msg
            |> Toastr.position TopCenter
            |> Toastr.warning

        let toastError msg =
            Toastr.message msg
            |> Toastr.position TopCenter
            |> Toastr.error

        let toastServerError title msg =
            Toastr.message msg
            |> Toastr.title title
            |> Toastr.position TopCenter
            |> Toastr.tapToDismiss
            |> Toastr.extendedTimout 0
            |> Toastr.timeout 0
            |> Toastr.showCloseButton
            |> Toastr.error

/// Functional approach for KeyValuePair.
[<RequireQualifiedAccess>]
module KeyValuePair =
    open System.Collections.Generic

    /// Gets key from KeyValuePair
    let key (x: KeyValuePair<_, _>) = x.Key

    /// Gets value from KeyValuePair
    let value (x: KeyValuePair<_, _>) = x.Value

    /// Creates KeyValuePair
    let create key value = KeyValuePair(key, value)

[<RequireQualifiedAccess>]
module Files =
    open Browser
    open Fable.Core.JsInterop

    let downloadFile (uri: string, name: string) =
        let link = Dom.document.createElement("a")
        link?download <- name
        link?href <- uri
        document.body.appendChild(link) |> ignore
        link.click();
        document.body.removeChild(link) |> ignore


[<RequireQualifiedAccess>]
module String =
    let trim (s: string) =
        match s with
        | null -> ""
        | x -> x.Trim()

    let toUpperInvariant (s: string) =
        match s with
        | null -> ""
        | x -> x.ToUpperInvariant()

    let tokenize (s: string) =
        s |> trim |> (fun x -> x.Split(' ')) |> Array.filter (System.String.IsNullOrWhiteSpace >> not)
module FlatpickrExtensions =
    open Fable.Core

    [<Emit("document.getElementById($0).flatpickr().clear()")>]
    let clear (id : string) : unit = jsNative

    type Locales =
        [<Import("Swedish", from="flatpickr/dist/l10n/sv.js")>]
        static member inline swedish : Flatpickr.IFlatpickrLocale = jsNative

/// Utility functions to work with blob APIs.
module Blob =
    open Fable.Core
    open Fable.Core.JsInterop
    open Browser.Types
    open Browser.Dom

    [<Emit("new Uint8Array($0)")>]
    let createUInt8Array(_: 'a) : byte[]  = jsNative

    [<RequireQualifiedAccess>]
    module String =
        [<Emit("$1.charCodeAt($0)")>]
        let inline getCharCodeAt (_: int) (_: string) : byte  = jsNative

    /// Asynchronously reads the blob data content as byte array
    let readAsByteArray (blob: Blob) : Async<byte array> =
        Async.FromContinuations <| fun (resolve, _, _) ->
            let reader = FileReader.Create()
            reader.onload <- fun _ ->
                if reader.readyState = FileReaderState.DONE
                then resolve (createUInt8Array(reader.result))

            reader.readAsArrayBuffer(blob)

    let ofDataUrl (url: string) : Blob option =
        match url.Split([|':'; ';';','|]) with
        | [|"data"; mimeType; "base64"; bytes|] ->
            let byteString = window.atob(bytes)
            let buffer = createUInt8Array(byteString.Length)
            [0..byteString.Length] |> Seq.iter (fun i -> buffer.[i] <- byteString |> String.getCharCodeAt i)
            Some (Browser.Blob.Blob.Create([|buffer|], !!{| ``type`` = mimeType |}))
        | _ -> None