module Components.Common

open System
open Feliz
open Feliz.Bulma
open Fable.DateFunctions

let toGoogleMaps (latitude: decimal) (longitude: decimal) =
    String.Format ("http://www.google.com/maps/place/{0},{1}",latitude, longitude)

let renderDuration (duration: TimeSpan) =
    Html.text (sprintf "%02i:%02i:%02i" duration.Hours duration.Minutes duration.Seconds)

let renderDurationOption (duration: TimeSpan option) =
    duration |> Option.map id |> Option.defaultValue TimeSpan.Zero |> renderDuration

let renderDate (date: DateTimeOffset) =
    Html.text (date.ToLocalTime().DateTime.Format("MMM dd yyyy, HH:mm"))

let renderDateOption (date: DateTimeOffset option) =
    date |> Option.map renderDate |> Option.defaultValue Html.none

let renderDeleteWindow = React.functionComponent( fun (props: {|message: string; confirmed: unit -> unit; notConfirmed: unit -> unit|}) ->
    let showDelWindow, setShowDelWindow = React.useState(true)
    let t = Localization.ns("common")
    Bulma.modal[
        if showDelWindow then Bulma.modal.isActive
        prop.children[
            Bulma.modalBackground[]
            Bulma.modalContent[
                prop.style[style.width 400]
                prop.children[
                    Bulma.box[
                        Bulma.mediaContent[
                            Bulma.content[
                                Html.p [
                                    prop.style[style.textAlign.center]
                                    prop.text props.message
                                ]
                            ]
                            Bulma.buttons[
                                Bulma.buttons.isCentered
                                prop.children[
                                    Bulma.button.a[
                                        prop.text (t "delete.confirm")
                                        prop.classes[BulmaCss.``is-success``]
                                        prop.onClick( fun x -> x.preventDefault(); setShowDelWindow false; props.confirmed())
                                    ]
                                    Bulma.button.button[
                                        prop.text (t "delete.cancel")
                                        prop.onClick( fun x -> x.preventDefault(); setShowDelWindow false; props.notConfirmed())
                                    ]
                                ]
                            ]
                        ]
                    ]
                ]
            ]
            Bulma.modalClose[
                prop.classes[BulmaCss.``is-large``]
                prop.onClick(fun x -> x.preventDefault(); setShowDelWindow false; props.notConfirmed())
            ]
        ]
    ]
)
open Feliz.UseDeferred

let renderConfirmationWindow = React.functionComponent( fun (props: {|title: string; confirmedButton: string; confirmed: unit -> Async<unit>; notConfirmed: unit -> unit|}) ->
    let showWindow, toggleShowWindowState = React.useState(true)

    let data, setData = React.useState(Deferred.HasNotStartedYet)

    let start = React.useDeferredCallback((fun () -> props.confirmed()), setData)

    let isLoading, error =
        match data with
        | Deferred.HasNotStartedYet -> false, ""
        | Deferred.InProgress -> true, ""
        | Deferred.Failed error -> false, error.Message
        | Deferred.Resolved _ -> false, ""

    let t = Localization.ns("common")

    Bulma.modal[
        if showWindow then Bulma.modal.isActive
        prop.children[
            Bulma.modalBackground[]
            Bulma.modalContent[
                prop.style[style.width 400]
                prop.children[
                    Bulma.box[
                        Bulma.mediaContent[
                            Bulma.content[
                                Html.p [
                                    prop.style[style.textAlign.center]
                                    prop.text (props.title)
                                ]
                                Bulma.help [
                                    color.isDanger
                                    prop.text error
                                ]
                            ]
                            Bulma.buttons[
                                Bulma.buttons.isCentered
                                prop.children[
                                    Bulma.button.a[
                                        if isLoading then button.isLoading
                                        prop.text props.confirmedButton
                                        prop.classes[BulmaCss.``is-success``]
                                        prop.onClick( fun x -> x.preventDefault(); toggleShowWindowState false; start())
                                    ]
                                    Bulma.button.button[
                                        prop.disabled isLoading
                                        prop.text (t "delete.cancel")
                                        prop.onClick( fun x -> x.preventDefault(); toggleShowWindowState false; props.notConfirmed())
                                    ]
                                ]
                            ]
                        ]
                    ]
                ]
            ]
            Bulma.modalClose[
                prop.classes[BulmaCss.``is-large``]
                prop.onClick(fun x -> x.preventDefault(); toggleShowWindowState false; props.notConfirmed())
            ]
        ]
    ]
)

module TranslatedErrors =
    open Shared.PrimitiveTypes
    open Shared.Errors

    let t = Localization.ns "errors"

    module ServerError =

        let domainErrorExplain = function
            | CaseAlreadyRegistered _ ->
                t "domain.caseRegistered" //+caseNumber
            | VideoProcessingHasNotStartedYet _ ->
                t "domain.videoNotStarted" //recordingId
            | VideoCaseFileShouldBeCreated _ ->
                t "domain.videoFileShouldBeCreated"
            | PasswordOnUserInvalid -> t "domain.passwordInvalid"
            | AccountLocked -> t "domain.AccountLocked"  // recordingId
            | AccountEmailAlreadyRegistered _ -> t "domain.AccountEmailAlreadyRegistered"
            | OldPasswordNotMatchToDbPassword -> t "domain.OldPasswordNotMatchToDbPassword"

        let explainType = function
            | IsEmpty -> t "validation.isEmpty"
            | IsTooLong x -> t "validation.tooLong"+ x.ToString()
            | IsTooSmall x -> t "validation.tooSmall" + x.ToString()
            | IsNotUnique -> t "validation.notUnique"
            | IsRequired -> t "validation.isRequired"
            | InvalidFormat -> t "validation.invalidFormat"

        let validationErrorExplain error =
            t "server.validation" + error.Field + " " + (explainType error.Type)

        let explainTranslation = function
            | Exception s -> t "server.exception"+s
            | Validation errors -> errors |> List.map validationErrorExplain |> String.concat ", "
            | DatabaseItemNotFound _ -> t "server.itemNotFound"
            | Domain error-> domainErrorExplain error
            | RemoteServiceError _ -> t "server.remoteService"
            | RemoteServiceErrorSms _ -> t "server.remoteService"