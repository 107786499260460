module LocalStorage

open Browser.WebStorage
open Shared.Auth

[<Literal>]
let TokenKey = "token"
[<Literal>]
let LoggedUser = "loggedUser"
[<Literal>]
let LoggedUserFullName = "loggedUserFullName"


let saveUser (user: SignedInUser) =
    localStorage.setItem(TokenKey, user.AccessToken)
    localStorage.setItem(LoggedUser, user.Username)
    localStorage.setItem(LoggedUserFullName, user.FullName)

let removeUser() =
    localStorage.removeItem(TokenKey)
    localStorage.removeItem(LoggedUser)
    localStorage.removeItem(LoggedUserFullName)

let getUser(): SignedInUser option =
    let token = localStorage.getItem TokenKey
    let userName = localStorage.getItem LoggedUser
    let fullName = localStorage.getItem LoggedUserFullName
    match isNull token, isNull userName with
    | false, false ->
        Some { Username = userName; AccessToken = token; FullName = fullName }
    | _, _ -> None

let getToken () =
    let token = localStorage.getItem TokenKey
    if isNull token then ""
    else token