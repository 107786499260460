module Positions

let t = Localization.ns ("position")

module Types =
    type PositionId = string

    type Position = {
        Id: PositionId
        Place: string
        Address: string
    }

    type EditPositionState =
        | New
        | Edit of Position
        | Hidden

    type State = {
        Positions: Deferred<Result<Position list, exn>>
        DeletePosition: Map<PositionId, ConfirmedOperation<Result<unit, exn>>>
        EditPosition: EditPositionState
    }
    type Msg =
        | Load of AsyncOperationStatus<Result<Position list, exn>>
        | ShowEditPosition of Position
        | ShowAddNewPosition
        | PositionChanged of Position
        | PositionDeleted of PositionId
        | PositionAdded of Position
        | DeletePosition of Position * AsyncConfirmOperationStatus<Result<unit, exn>>
        | PositionClosed

module State =
    open Types
    open Elmish

    let init () =
        { Positions = HasNotStartedYet; EditPosition = Hidden; DeletePosition = Map.empty }, Cmd.ofMsg (Load Started)

    let update (msg: Msg) (state: State) =
        match msg with
        | Load Started ->
            let load: Async<Msg> = async {
                do! Async.Sleep 500
                let positions = [
                    for i in [1..3] |> List.map string do
                        { Id = i; Place = t "column.place" + " " + i; Address = t "column.address" + " " + i  }
                ]
                return Load (Finished (Ok positions))
            }
            { state with Positions = InProgress }, Cmd.fromAsync load
        | Load (Finished (Ok positions)) ->
            { state with Positions = Resolved (Ok positions) }, Cmd.none
        | Load (Finished (Error ex)) ->
            { state with Positions = Resolved (Error ex) }, Cmd.none
        | ShowEditPosition position ->
            { state with EditPosition = Edit position }, Cmd.none
        | ShowAddNewPosition ->
            { state with EditPosition = New }, Cmd.none
        | DeletePosition (position, ConfirmationStarted) ->
            {state with DeletePosition = state.DeletePosition |> Map.add position.Id Requested}, Cmd.none
        | DeletePosition (position, Canceled) ->
            {state with DeletePosition = state.DeletePosition |> Map.add position.Id NotStarted}, Cmd.none
        | DeletePosition (position, Confirmed) ->
            let delete : Async<Msg> = async {
                do! Async.Sleep 1000
                return DeletePosition (position, ConfirmationFinished (Ok ()))
            }
            { state with DeletePosition = state.DeletePosition |> Map.add position.Id ConfirmationInProgress }, Cmd.fromAsync delete
        | DeletePosition (position, ConfirmationFinished (Ok ())) ->
            let newPositions = state.Positions |> DeferredResult.map (fun ps -> ps |> List.filter (fun x -> x.Id <> position.Id))
            { state with Positions = newPositions }, Cmd.none
        | DeletePosition (position, ConfirmationFinished (Error ex)) ->
            { state with DeletePosition = state.DeletePosition |> Map.add position.Id (Done (Error ex)) }, Cmd.none
        | PositionChanged position ->
            let newPositions = state.Positions |> DeferredResult.map (fun ps -> ps |> List.map (fun x -> if x.Id = position.Id then position else x))
            { state with Positions = newPositions; EditPosition = Hidden }, Cmd.none
        | PositionDeleted positionId ->
            let newPositions = state.Positions |> DeferredResult.map (fun ps -> ps |> List.filter (fun x -> x.Id <> positionId))
            { state with Positions = newPositions; EditPosition = Hidden }, Cmd.none
        | PositionAdded position ->
            let newPositions = state.Positions |> DeferredResult.map (fun ps -> ps @ [position])
            { state with Positions = newPositions; EditPosition = Hidden }, Cmd.none
        | PositionClosed ->
            { state with EditPosition = Hidden }, Cmd.none

module View =
    open Types
    open Feliz
    open Feliz.Bulma

    let renderToolbar (state: State) (dispatch: Msg -> unit) =
        Bulma.level [
            prop.children [
                Bulma.levelLeft [
                    Bulma.levelItem [
                        Bulma.title.h3 [
                            Bulma.text.isUppercase
                            prop.text (t "title")
                        ]
                    ]
                ]
                Bulma.levelRight[
                    Bulma.levelItem [
                        Bulma.button.a [
                            color.isPrimary
                            prop.className "button-shadow"
                            prop.text (t "button.addPosition")
                            prop.onClick (fun x -> x.preventDefault(); dispatch ShowAddNewPosition)
                        ]
                    ]
                ]
            ]
        ]

    let renderPositions (positions: Position list) (state: State) dispatch =
        Html.div[
            Bulma.columns [
                prop.classes [AppCss.Headers]
                prop.children[
                    Bulma.column[
                        prop.text (t "column.title.place")
                    ]
                    Bulma.column[
                        prop.text (t "column.title.address")
                    ]
                    Bulma.column[
                        prop.style [ style.textAlign.right ]
                    ]
                ]
            ]
            for position in positions do
                let isDeleting =
                        state.DeletePosition
                        |> Map.tryFind position.Id
                        |> Option.map ConfirmedOperation.confirmationInProgress
                        |> Option.defaultValue false
                Bulma.box[
                    prop.classes [AppCss.BoxStyle]
                    prop.children[
                        Bulma.columns[
                            Bulma.column[
                                prop.text(position.Place)
                            ]
                            Bulma.column[
                                prop.style[
                                    style.opacity 0.5
                                ]
                                prop.text(position.Address)
                            ]
                            Bulma.column[
                                prop.style[
                                    style.paddingTop 4
                                    style.paddingBottom 4
                                    style.textAlign.right
                                ]
                                prop.children[
                                    Bulma.buttons [
                                        Bulma.buttons.isRight
                                        prop.children [
                                            Bulma.button.a [
                                                prop.classes [BulmaCss.``has-text-primary``]
                                                prop.style[
                                                    style.backgroundColor.transparent
                                                ]
                                                prop.children [
                                                    Bulma.icon [
                                                        Html.i [
                                                            prop.classes [ MdiCss.Mdi; MdiCss.MdiPencilOutline; MdiCss.Mdi24Px ]
                                                        ]
                                                    ]
                                                ]
                                                prop.onClick (fun x -> x.preventDefault(); dispatch (ShowEditPosition position))
                                                prop.disabled isDeleting
                                            ]
                                            Bulma.button.button [
                                                prop.classes [BulmaCss.``has-text-danger``]
                                                prop.style[
                                                    style.backgroundColor.transparent
                                                ]
                                                prop.children [
                                                    Bulma.icon [
                                                        Html.i [
                                                            prop.classes [ MdiCss.Mdi; MdiCss.MdiDeleteOutline; MdiCss.Mdi24Px ]
                                                        ]
                                                    ]
                                                ]
                                                prop.onClick (fun x -> x.preventDefault(); dispatch (DeletePosition (position, ConfirmationStarted)))
                                                if isDeleting then Bulma.button.isLoading
                                            ]
                                        ]
                                    ]
                                ]
                            ]
                        ]
                    ]
                ]
                if (state.DeletePosition|> Map.tryFind position.Id|> Option.map ConfirmedOperation.confirmationRequested|> Option.defaultValue false) then
                    let confirmedDelPosition() = dispatch (DeletePosition (position, Confirmed))
                    let notConfirmedDelPosition() = dispatch (DeletePosition (position, Canceled))
                    Components.Common.renderDeleteWindow
                        {| message = t "delete.position.confirmation.question"
                           confirmed=confirmedDelPosition
                           notConfirmed=notConfirmedDelPosition |}
        ]

    let renderAddOrEditPosition (state: State) dispatch =
        match state.EditPosition with
        | Hidden -> Html.none
        | Edit position ->
            Position.Edit.Component.editPosition
                {
                    Position = { Id = position.Id; Place = position.Place; Address = position.Address }
                    OnSave = fun x -> dispatch (PositionChanged { Id = x.Id; Address = x.Address; Place = x.Place })
                    OnDelete = fun _ -> dispatch (PositionDeleted position.Id)
                    OnClose = fun _ -> dispatch (PositionClosed)
                }
        | New ->
            Position.Add.Component.addPosition
                {
                    OnSave = fun x -> dispatch (PositionAdded { Id = x.Id; Address = x.Position.Address; Place = x.Position.Place })
                }

    let render (state: State) dispatch =
        match state.Positions with
        | HasNotStartedYet
        | InProgress ->
            Components.Loader.fullScreenMedium (t "loading.positions")
        | Resolved (Ok positions) ->
            Html.div [
                Html.div[
                    prop.classes [ AppCss.Toolbar ]
                    prop.children[
                        renderToolbar state dispatch
                    ]
                ]
                renderPositions positions state dispatch
                renderAddOrEditPosition state dispatch
            ]
        | Resolved (Error ex) ->
            Bulma.notification [
                color.isDanger
                prop.text ex.Message
            ]

module Component =
    open Feliz
    open Feliz.ElmishComponents
    open State
    open View

    type Props = string
    let userDetails () =
        React.elmishComponent("Positions", init (), update, render)