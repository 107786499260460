﻿module SelectMedia4Version

open Elmish
open Fable.Core
open Agora4Version.RTC
open Fable.Import
open Feliz.Bulma
open Recording

let t = Localization.ns("selectMedia")
let vt = Localization.ns("videoCall")

type SelectedMedia = {
    Camera: MediaDeviceInfo option
    Mic: MediaDeviceInfo option
    AudioOutput: MediaDeviceInfo option
}

type State = {
    CheckingAccessToDevices: Deferred<Result<unit, string>>
    Cameras: Deferred<Result<MediaDeviceInfo [], string>>
    Microphones: Deferred<Result<MediaDeviceInfo [], string>>
    AudioOutputs: Deferred<Result<MediaDeviceInfo [], string>>
    SelectedMedia: SelectedMedia
}

type Msg =
    | TryAccessToDevices of AsyncOperationStatus<Result<unit, string>>
    | LoadSavedSettings of AsyncOperationStatus<SelectedMedia option>
    | ChangeCamera of MediaDeviceInfo option
    | ChangeMicrophone of MediaDeviceInfo option
    | ChangeAudioOutput of MediaDeviceInfo option
    | LoadAvailableCameras of AsyncOperationStatus<Result<MediaDeviceInfo[], string>>
    | LoadAvailableMicrophones of AsyncOperationStatus<Result<MediaDeviceInfo[], string>>
    | LoadAvailableAudioOutputs of AsyncOperationStatus<Result<MediaDeviceInfo[], string>>
    | ProceedCallClicked
    | ProceedCallWithSharedLinkClicked
    | CancelCallClicked

module Cmd =
    let loadDevices (f: unit -> JS.Promise<MediaDeviceInfo[]>) =
        promise {
            try
                let! devices = f()
                return Finished (Ok devices)
            with
                ex ->
                    return Finished (Error ex.Message)
        }
        |> Async.AwaitPromise

    let loadCameras() =
        loadDevices sdk.getCameras
        |> Async.map LoadAvailableCameras
        |> Cmd.fromAsync

    let loadMicrophones() =
        loadDevices sdk.getMicrophones
        |> Async.map LoadAvailableMicrophones
        |> Cmd.fromAsync

    let loadAudioOutput() =
        loadDevices sdk.getPlaybackDevices
        |> Async.map LoadAvailableAudioOutputs
        |> Cmd.fromAsync

    [<Emit("new Object({ video: $0, audio: $1 })")>]
    let createConstraint (_video: bool, _audio: bool) : Browser.Types.MediaStreamConstraints = jsNative

    let checkAccessToDevices : Cmd<Msg> =
        async {
            try
                let constraints = createConstraint (true, true)
                let! _ = Browser.MediaStreams.mediaDevices.getUserMedia(constraints)
                         |> Async.AwaitPromise
                return TryAccessToDevices (Finished (Ok ()))
            with
                | ex ->
                    let message = sprintf "%s %s" (t "failed.access.to.devices") ex.Message
                    Browser.Dom.console.warn("Failed to get access to device", ex)
                    return TryAccessToDevices (Finished (Error message))
        }
        |> Cmd.fromAsync

    module Storage =
        open Browser.WebStorage
        open Fable.SimpleJson

        [<Literal>]
        let SelectedMediaKey = "selected.media"

        let loadSnapshot: Cmd<Msg> =
            async {
                try
                    return
                        localStorage.getItem(SelectedMediaKey)
                        |> Json.parseAs<SelectedMedia>
                        |> Some
                with
                    _ -> return None
            }
            |> Async.map (Finished >> LoadSavedSettings)
            |> Cmd.fromAsync

        let saveSnapshot (x: SelectedMedia): Cmd<Msg> =
            Cmd.ofSub <| fun _ ->
                localStorage.setItem(SelectedMediaKey, Json.stringify x)

let init() =
    {
        Cameras = HasNotStartedYet; Microphones = HasNotStartedYet; AudioOutputs = HasNotStartedYet
        SelectedMedia = { Camera = None; Mic = None; AudioOutput = None }
        CheckingAccessToDevices = HasNotStartedYet
    }, Cmd.ofMsg (TryAccessToDevices Started)

let update (onProceed: SelectedMedia -> unit) (onProceedWithSharedLink: SelectedMedia -> unit) (onCancel: unit->unit)(msg: Msg) (state: State) : State * Cmd<Msg> =
    match msg with
    | TryAccessToDevices Started ->
        { state with CheckingAccessToDevices = InProgress }, Cmd.checkAccessToDevices
    | TryAccessToDevices (Finished (Ok ())) ->
        { state with CheckingAccessToDevices = Resolved (Ok ()) },
        Cmd.batch [
            Cmd.ofMsg (LoadAvailableCameras Started)
            Cmd.ofMsg (LoadAvailableMicrophones Started)
            Cmd.ofMsg (LoadAvailableAudioOutputs Started)
            Cmd.ofMsg (LoadSavedSettings Started)
        ]
    | TryAccessToDevices (Finished (Error x)) ->
        { state with CheckingAccessToDevices = Resolved (Error x) }, Cmd.none
    | LoadSavedSettings Started -> state, Cmd.Storage.loadSnapshot
    | LoadSavedSettings (Finished settings) ->
        match settings with
        | Some x ->
            { state with SelectedMedia = x }, Cmd.ofSub (fun _ -> Browser.Dom.console.log("LoadSavedSettings", x))
        | None -> state, Cmd.none
    | ChangeCamera x ->
        let selectedMedia = { state.SelectedMedia with Camera = x }
        { state with SelectedMedia = selectedMedia }, Cmd.Storage.saveSnapshot selectedMedia
    | ChangeMicrophone x ->
        let selectedMedia = { state.SelectedMedia with Mic = x }
        { state with SelectedMedia = selectedMedia }, Cmd.Storage.saveSnapshot selectedMedia
    | ChangeAudioOutput x ->
        let selectedMedia = { state.SelectedMedia with AudioOutput = x }
        { state with SelectedMedia = selectedMedia }, Cmd.Storage.saveSnapshot selectedMedia
    | LoadAvailableCameras Started -> { state with Cameras = InProgress }, Cmd.loadCameras()
    | LoadAvailableCameras (Finished (Ok x)) ->
        let camera = state.SelectedMedia.Camera |> Option.orElse (Array.tryHead x)
        { state with
            Cameras = Resolved (Ok x)
            SelectedMedia = { state.SelectedMedia with Camera = camera } }, Cmd.none
    | LoadAvailableCameras (Finished (Error e)) -> { state with Cameras = Resolved (Error e) }, Cmd.none
    | LoadAvailableMicrophones Started -> { state with Microphones = InProgress }, Cmd.loadMicrophones()
    | LoadAvailableMicrophones (Finished (Ok x)) ->
        let mic = state.SelectedMedia.Mic |> Option.orElse (Array.tryHead x)
        { state with
            Microphones = Resolved (Ok x)
            SelectedMedia = { state.SelectedMedia with Mic = mic } }, Cmd.none
    | LoadAvailableMicrophones (Finished (Error e)) -> { state with Microphones = Resolved (Error e) }, Cmd.none
    | LoadAvailableAudioOutputs Started -> { state with AudioOutputs = InProgress }, Cmd.loadAudioOutput()
    | LoadAvailableAudioOutputs (Finished (Ok x)) ->
        let audioOutput = state.SelectedMedia.AudioOutput |> Option.orElse (Array.tryHead x)
        { state with
            AudioOutputs = Resolved (Ok x)
            SelectedMedia = { state.SelectedMedia with AudioOutput = audioOutput } }, Cmd.none
    | LoadAvailableAudioOutputs (Finished (Error e)) -> { state with AudioOutputs = Resolved (Error e) }, Cmd.none
    | ProceedCallClicked ->
        state, Cmd.ofSub (fun _ -> onProceed state.SelectedMedia)
    | ProceedCallWithSharedLinkClicked ->
        state, Cmd.ofSub (fun _ -> onProceedWithSharedLink state.SelectedMedia)
    | CancelCallClicked ->
        state, Cmd.ofSub (fun _ -> onCancel())

open Feliz
open Feliz.UseElmish

let htmlIgnore _ = Html.none

let renderError (msg: string) =
    Bulma.help [
        Bulma.color.isDanger
        prop.text msg
    ]

let inline renderLoadError t  = t |> DeferredResult.fold htmlIgnore renderError htmlIgnore

let renderDevices
        (selected: MediaDeviceInfo option)
        (devices: Deferred<Result<MediaDeviceInfo[], string>>)
        (onChange: MediaDeviceInfo option -> unit) =
    let renderOption (label: string) (value: string) =
        Html.option [
            prop.text label
            prop.value value
        ]

    let noDeviceOption = renderOption (t "no.device") ""
    let options =
        devices
        |> DeferredResult.fold
               (Array.map (fun x -> renderOption x.label x.deviceId))
               (fun _ -> [|noDeviceOption|]) (fun _ -> [|noDeviceOption|])

    let onChange (deviceId: string) =
        devices
        |> DeferredResult.fold (Array.tryFind (fun x -> x.deviceId = deviceId)) (fun _ -> None) (fun _ -> None)
        |> onChange

    Bulma.select[
        select.isFullWidth
        prop.classes [
            if Deferred.inProgress devices then "is-loading"
            if DeferredResult.isError devices then "is-danger"
        ]
        prop.onChange onChange
        prop.children options
        prop.value (selected |> Option.map (fun x -> x.deviceId) |> Option.defaultValue "")
    ]

let private renderVideoPreview = React.functionComponent(fun (device: MediaDeviceInfo option) ->
    let error, setError = React.useState(None)
    let uid = "video-preview"
    let initStream() = async {
        try
            let! localAudioTrack = sdk.createMicrophoneAudioTrack() |> Async.AwaitPromise
            let! localVideoTrack = sdk.createCameraVideoTrack() |> Async.AwaitPromise

            let localUser = {
                id = uid
                hasAudio= localAudioTrack.enabled
                hasVideo = localVideoTrack.enabled
                audioTrack = localAudioTrack
                videoTrack = localVideoTrack
            }
            if localUser.hasVideo then localUser.videoTrack.play(uid)
        with
            ex ->
                setError(Some ex.Message)
                Browser.Dom.console.error ("Failed initialize stream", ex)
    }

    match device with
    | Some _ -> React.useEffectOnce (initStream >> Async.StartImmediate)
    | None -> ()

    Html.div [
        Html.div [
            prop.id uid
            prop.classes [ AppCss.CallPreview ]
        ]
        Bulma.control.div [
            Bulma.help [
                color.isDanger
                prop.text (error |> Option.defaultValue "")
            ]
        ]
    ]
)

let renderSelectDevices (state: State) dispatch isWithBooking =
    React.fragment [
        Bulma.columns [
            columns.isCentered
            prop.children [
                Bulma.column [
                    renderVideoPreview state.SelectedMedia.Camera
                ]
                Bulma.column [
                    Bulma.field.div [
                        Bulma.label (t "camera")
                        Bulma.control.div [
                            renderDevices state.SelectedMedia.Camera state.Cameras (ChangeCamera >> dispatch)
                        ]
                        renderLoadError state.Cameras
                    ]
                    Bulma.field.div [
                        Bulma.label (t "microphone")
                        Bulma.control.div [
                            renderDevices state.SelectedMedia.Mic state.Microphones (ChangeMicrophone >> dispatch)
                        ]
                        renderLoadError state.Microphones
                    ]
                    Bulma.field.div [
                        Bulma.label (t "audio.output")
                        Bulma.control.div [
                            renderDevices state.SelectedMedia.AudioOutput state.AudioOutputs (ChangeAudioOutput >> dispatch)
                        ]
                        renderLoadError state.AudioOutputs
                    ]
                ]
            ]
        ]
        Bulma.buttons [
            buttons.isCentered
            prop.children [
                Bulma.button.button [
                    prop.disabled (not isWithBooking)
                    prop.onClick (fun _ -> dispatch ProceedCallClicked)
                    prop.text (t "proceed.call")
                    Bulma.color.isPrimary
                ]
                Bulma.button.button [
                    prop.onClick (fun _ -> dispatch ProceedCallWithSharedLinkClicked)
                    prop.text (t "proceed.call.with.sharing.link")
                    Bulma.color.isPrimary
                ]
                Bulma.button.button [
                    prop.onClick (fun _ -> dispatch CancelCallClicked)
                    prop.text (t "cancel.call")
                    Bulma.color.isDanger
                ]
            ]
        ]
    ]

let loader (text : string) =
     Bulma.columns [
        Bulma.column [
            column.isNarrow
            prop.children [
                Bulma.icon [
                    Html.i [
                        prop.className [MdiCss.Mdi;  MdiCss.MdiLoading; MdiCss.MdiSpin; MdiCss.Mdi18Px ]
                    ]
                ]
            ]
        ]
        Bulma.column text
    ]

let render = React.functionComponent(fun (props: {| OnProceedCall: SelectedMedia -> unit; OnProceedCallWithSharedLink: SelectedMedia -> unit; OnCancel: unit -> unit; IsWithBooking: bool |}) ->
    let state, dispatch = React.useElmish(init, update props.OnProceedCall props.OnProceedCallWithSharedLink props.OnCancel , [||])

    Html.div [
        prop.classes ["vertical-center"]
        prop.children [
            Bulma.section [
                match state.CheckingAccessToDevices with
                | HasNotStartedYet | InProgress -> loader (t "verifying.access.to.devices")
                | Resolved (Ok ()) -> renderSelectDevices state dispatch props.IsWithBooking
                | Resolved (Error e) ->
                    React.fragment [
                        Bulma.notification [
                            color.isWarning
                            prop.text e
                        ]
                        Bulma.buttons [
                            buttons.isCentered
                            prop.children [
                                Bulma.button.button [
                                    prop.onClick (fun _ -> dispatch CancelCallClicked)
                                    prop.text (vt "call.closeTab")
                                    Bulma.color.isDanger
                                ]
                            ]
                        ]
                    ]
            ]
        ]
    ]
)