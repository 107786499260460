module AddUserPosition

open Shared.PrimitiveTypes
open Shared.Users.Queries

let t = Localization.ns ("addUserPosition")
module Types =
    type NewPosition = {
        Place: string
        Address: string
    }

//    type SavedPosition = {
//        Id: string
//        Position: Position
//    }

//    type UserId = string
//    type PositionId = string
    type State = {
        UserId: UserId
        Position: NewPosition
        IsVisible: bool
        SavedPosition: Deferred<Result<Position, exn>>
    }

    type Msg =
        | Close
        | PlaceChanged of string
        | AddressChanged of string
        | Save of AsyncOperationStatus<Result<PositionId, exn>>

module State =
    open Types
    open Elmish

    let withPosition address (state: State) = { state with Position = address }

    let init userId =
        {
            Position = { Address = ""; Place = "" }
            IsVisible = true
            UserId = userId
            SavedPosition = HasNotStartedYet
        }, Cmd.none

    let update (onSave: Position -> unit) (msg: Msg) (state: State) =
        match msg with
        | PlaceChanged s ->
            state |> withPosition { state.Position with Place = s }, Cmd.none
        | AddressChanged s ->
            state |> withPosition { state.Position with Address = s }, Cmd.none
        | Close ->
            { state with IsVisible = false }, Cmd.none
        | Save Started ->
            let save: Async<Msg> = async {
                do! Async.Sleep 1000
                if (state.Position.Place.StartsWith("E")) then
                    return Save (Finished (Error (exn state.Position.Place)))
                else
                    return Save (Finished (PositionId.create 1000))
            }
            { state with SavedPosition = InProgress }, Cmd.fromAsync save
        | Save (Finished (Ok positionId)) ->
            let savedPosition : Position = { Id = positionId; Place = state.Position.Place; Address = state.Position.Address }
            let c = Cmd.OfFunc.attempt onSave savedPosition (fun ex -> Save (Finished (Error ex)))
            { state with SavedPosition = Resolved (Ok savedPosition) }, Cmd.batch [ Cmd.ofMsg Close; c]
        | Save (Finished (Error ex)) ->
            { state with SavedPosition = Resolved (Error ex) }, Cmd.none

module View =
    open Types
    open Feliz
    open Feliz.Bulma
    open Feliz.Bulma.QuickView

    let renderError (state: State) dispatch =
        match state.SavedPosition with
        | Resolved (Error ex) ->
            Bulma.notification [
                color.isDanger
                prop.text (sprintf "Error while saving: %s" ex.Message)
            ]
        | _ -> Html.none

    let renderForm (state: State) dispatch =
        Bulma.section [
            prop.children [
                renderError state dispatch
                Html.fieldSet [
                    prop.disabled (Deferred.inProgress state.SavedPosition)
                    prop.children [
                        Bulma.field.div [
                            Bulma.label (t "label.place")
                            Bulma.control.div [
                                Bulma.input.text [
                                    prop.autoFocus true
                                    prop.valueOrDefault state.Position.Place
                                    prop.onChange (PlaceChanged >> dispatch)
                                ]
                            ]
                        ]
                        Bulma.field.div [
                            Bulma.label (t "label.address")
                            Bulma.control.div [
                                Bulma.input.text [
                                    prop.valueOrDefault state.Position.Address
                                    prop.onChange (AddressChanged >> dispatch)
                                ]
                            ]
                        ]
                    ]
                ]
            ]

        ]

    let renderToolbar (state: State) dispatch =
        Bulma.section [
            Bulma.container [
                Bulma.level [
                    Bulma.levelLeft [
                        if state.IsVisible then
                            Bulma.levelItem [
                                prop.classes [ AppCss.CloseQuickview ]
                                prop.children [
                                    Bulma.button.button [
                                        color.isPrimary
                                        prop.className "button-shadow"
                                        prop.onClick (fun x -> x.preventDefault(); dispatch Close)
                                        prop.children [
                                            Bulma.icon [
                                                Html.i [
                                                    prop.classes [ MdiCss.Mdi; MdiCss.MdiArrowRight; MdiCss.Mdi24Px ]
                                                ]
                                            ]
                                        ]
                                        if Deferred.inProgress state.SavedPosition then Bulma.button.isLoading
                                    ]
                                ]
                            ]
                        Bulma.levelItem [
                            Bulma.title.h3 (t "title")
                        ]
                    ]
                    Bulma.levelRight [
                        Bulma.levelItem [
                            Bulma.button.a [
                                prop.classes [BulmaCss.``has-text-primary``]
                                prop.children [
                                    Bulma.icon [
                                        Html.i [
                                            prop.classes [ MdiCss.Mdi; MdiCss.MdiContentSaveOutline; MdiCss.Mdi24Px ]
                                        ]
                                    ]
                                ]
                                if Deferred.inProgress state.SavedPosition then Bulma.button.isLoading
                                prop.onClick (fun x -> x.preventDefault(); dispatch (Save Started))
                            ]
                        ]
                    ]
                ]
            ]
        ]

    let render (state: State) (dispatch: Msg -> unit) =
        if state.IsVisible then
            Html.div [
                prop.children [
                    Html.div [
                        prop.className "Dark-screen"
                    ]
                    QuickView.quickview [
                        quickview.isActive
                        prop.children [
                            QuickView.body [
                                renderToolbar state dispatch
                                QuickView.block [ renderForm state dispatch ]
                            ]
                        ]
                    ]
                ]
            ]
        else
            Html.none

module Component =
    open Feliz
    open Feliz.ElmishComponents
    open State
    open View

    type Props = {
        UserId: UserId
        OnSave: Position -> unit
    }

    let addUserPosition (props: Props) =
        React.elmishComponent("AddUserPosition", init props.UserId, update props.OnSave, render)