module Hooks

open Browser.Types

module Utils =
    let attachEvent (f: Event->unit) (node: Browser.Types.Window) (eventType: string) =
        node.addEventListener(eventType, f)
        { new System.IDisposable with member __.Dispose() = node.removeEventListener(eventType, f) }

module UseSize =
    open Feliz
    open Browser.Dom
    open Utils

    let getSize(node: HTMLElement) =
        let rect = node.getBoundingClientRect()
        {| Width = rect.width; Height = rect.height |}

    let hook () =
        let size, setSize = React.useState None
        let ref = React.useRef None

        let measure node =
            window.requestAnimationFrame(fun _ -> node |> getSize |> Some |> setSize) |> ignore

        let handle () =
            match ref.current with
            | Some node ->
                measure node
                (window, "resize") ||> attachEvent (fun _ -> measure node)
            | None -> React.createDisposable id

        React.useLayoutEffect(handle, [|box ref.current|])
        (ref, size)

let useSize = UseSize.hook