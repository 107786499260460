namespace Components

[<RequireQualifiedAccess>]
module Loader =
    open Feliz
    open Feliz.Bulma

    type Size = Small | Normal | Medium | Large
    type Props = {
        IconSize: Size
        Text : string
        IconClasses: string list
    }
    with static member Default = {
            IconSize = Small
            Text = "Loading ..."
            IconClasses = [MdiCss.Mdi;  MdiCss.MdiLoading; MdiCss.MdiSpin]
        }

    let size = function
        | Small -> MdiCss.Mdi18Px
        | Normal -> MdiCss.Mdi24Px;
        | Medium -> MdiCss.Mdi36Px;
        | Large -> MdiCss.Mdi48Px;

    let view = React.functionComponent(fun (props: Props) ->
        Html.div [
            prop.style[
                style.position.absolute
                style.top 0
                style.width (length.percent 100)
                style.height (length.percent 100)
                style.display.flex
                style.justifyContent.center
                style.alignItems.center
            ]
            prop.children [
                Bulma.control.div [
                    prop.children [
                        Bulma.columns [
                            Bulma.column [
                                Bulma.column.isNarrow
                                prop.children [
                                    Bulma.icon [
                                        Html.i [
                                            prop.className (props.IconClasses @ [ (size props.IconSize) ])
                                        ]
                                    ]
                                ]
                            ]
                            Bulma.column [
                                Html.text props.Text
                            ]
                        ]
                    ]
                ]
            ]
        ]
    )

    let medium text = view { Props.Default with Text = text; IconSize = Medium }

    let fullScreenMedium text =
        Bulma.hero [
                Bulma.hero.isFullHeightWithNavbar
                prop.style[style.opacity 0.5]
                prop.children [
                    Bulma.heroBody [
                        medium text
                    ]
                ]
            ]