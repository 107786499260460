module ActivateAccount

open Shared.Auth

let t = Localization.ns("activateAccount")

module Types =
    type Form = {
        Password: string
        RepeatPassword: string
    }

    type State = {
        ActivationCode: string
        SetPasswordForm: Form
        ActivateAccountResult: Deferred<Result<unit, string>>
        CodeVerifyResult: Deferred<Result<VerifyActivationCodeResult, string>>
    }

    type Msg =
        | PasswordChanged of string
        | RepeatPasswordChanged of string
        | VerifyCode of AsyncOperationStatus<Result<VerifyActivationCodeResult, string>>
        | ActivateAccount of AsyncOperationStatus<Result<unit, string>>

module Cmd =
    open Elmish
    open Types
    open Communication
    open Components.Common.TranslatedErrors

    let verifyActivationCode (code: string): Cmd<Msg> =
        async {
            try
                let! result = authApi().verifyActivationCode code
                return VerifyCode (Finished (result |> Result.mapError ServerError.explainTranslation))
            with
                ex -> return VerifyCode (Finished (Error ex.Message))
        }
        |> Cmd.fromAsync

    let activateAccount (state: State): Cmd<Msg> =
        async {
            try
                let args: Shared.Auth.ActivateAccount = {
                    Code = state.ActivationCode
                    Password = state.SetPasswordForm.Password
                    PasswordRepeat = state.SetPasswordForm.RepeatPassword
                }

                let! result = authApi().activateAccount args
                return ActivateAccount (Finished (result |> Result.mapError ServerError.explainTranslation))
            with
                ex -> return ActivateAccount (Finished (Error ex.Message))
        }
        |> Cmd.fromAsync

module State =
    open Types
    open Elmish
    open Extensions.View

    let init (code: string) =
        {
            ActivationCode = code
            SetPasswordForm = { Password = ""; RepeatPassword = "" }
            ActivateAccountResult = HasNotStartedYet
            CodeVerifyResult = HasNotStartedYet
        }, Cmd.ofMsg (VerifyCode Started)

    let withForm (form: Form) (state: State) =
        { state with SetPasswordForm = form }

    let update (msg: Msg) (state: State)  =
        match msg with
        | PasswordChanged s ->
            state |> withForm { state.SetPasswordForm with Password = s }, Cmd.none
        | RepeatPasswordChanged s ->
            state |> withForm { state.SetPasswordForm with RepeatPassword = s }, Cmd.none
        | VerifyCode Started ->
            { state with CodeVerifyResult = InProgress }, Cmd.verifyActivationCode state.ActivationCode
        | VerifyCode (Finished (Ok x)) ->
            { state with CodeVerifyResult = Resolved (Ok x) }, Cmd.none
        | VerifyCode (Finished (Error error)) ->
            { state with CodeVerifyResult = Resolved (Error error) }, Cmd.toastServerError (t "error.title") error
        | ActivateAccount Started ->
            if (state.SetPasswordForm.Password <> state.SetPasswordForm.RepeatPassword) then
                state, Cmd.toastError "Passwords should match"
            elif state.SetPasswordForm.Password.Length < 8 then
                state, Cmd.toastError "Password length should be at least 8 characters"
            else
                { state with ActivateAccountResult = InProgress }, Cmd.activateAccount state
        | ActivateAccount (Finished (Ok())) ->
            { state with ActivateAccountResult = Resolved (Ok ()) }, Cmd.none
        | ActivateAccount (Finished (Error error)) ->
            { state with ActivateAccountResult = Resolved (Error error) }, Cmd.toastServerError (t "error.title") error

module View =
    open Types
    open Feliz
    open Feliz.Bulma

    let row (label: string) (input: ReactElement list) =
        Bulma.columns [
            prop.style [
                style.marginBottom (length.rem 0.75)
            ]
            Bulma.columns.isGapless
            Bulma.columns.isVCentered
            prop.children [
                Bulma.column [
                    Bulma.column.is4
                    prop.children [
                        Bulma.fieldLabel [
                            Bulma.fieldLabel.isNormal
                            prop.classes[BulmaCss.``has-text-left``]
                            prop.children [
                                Bulma.label label
                            ]
                        ]
                    ]
                ]
                Bulma.column [
                    prop.children input
                ]
            ]
        ]

    let renderForm (state: State) dispatch =
        let inProgress = Deferred.inProgress state.ActivateAccountResult
        Bulma.column [
            prop.children [
                Html.form [
                    prop.children [
                        Bulma.field.div [
                            Bulma.control.div [
                                Bulma.input.password [
                                    prop.placeholder (t "password.placeholder")
                                    prop.autoFocus true
                                    prop.valueOrDefault state.SetPasswordForm.Password
                                    prop.onChange (PasswordChanged >> dispatch)
                                    prop.disabled inProgress
                                ]
                            ]
                        ]
                        Bulma.field.div [
                            Bulma.control.div [
                                Bulma.input.password [
                                    prop.placeholder (t "password.repeat.placeholder")
                                    prop.valueOrDefault state.SetPasswordForm.RepeatPassword
                                    prop.onChange (RepeatPasswordChanged >> dispatch)
                                    prop.disabled inProgress
                                ]
                            ]
                        ]
                        Bulma.field.div [
                            Bulma.control.div [
                                Bulma.button.button [
                                    Bulma.button.isFullWidth
                                    color.isBlack
                                    prop.disabled inProgress
                                    prop.text (t "button.activate")
                                    prop.style[
                                        style.marginTop 40;
                                    ]
                                    prop.onClick (fun e -> e.preventDefault(); dispatch (ActivateAccount Started))
                                ]
                            ]
                        ]
                    ]
                ]
            ]
        ]

    let private goToSignInButton () =
        Bulma.button.a [
            Bulma.color.isPrimary
            prop.href (Router.toPath Router.SignIn)
            prop.text (t "button.goto.signin")
        ]

    let private fullScreen (children: ReactElement list) =
        Bulma.hero [
            Bulma.hero.isFullHeight
            prop.classes [ AppCss.ActivateAccountScreen ]
            prop.children [
                Bulma.heroBody [
                    Bulma.column [
                        prop.classes [BulmaCss.``is-3-desktop``; BulmaCss.``is-6-tablet``]
                        prop.style [ style.margin.auto ]
                        prop.children children
                    ]
                ]
            ]
        ]

    let private fullScreenWithSignIn (children: ReactElement list) =
        children @ [goToSignInButton ()] |> fullScreen

    let renderActivation (state: State) dispatch =
        match state.ActivateAccountResult with
        | HasNotStartedYet ->
            fullScreen [
                renderForm state dispatch
            ]
        | InProgress -> Components.Loader.fullScreenMedium (t "activation.inProgress")
        | Resolved (Ok ()) ->
            fullScreenWithSignIn [
                Bulma.title.h5 (t "activation.successful")
            ]
        | Resolved (Error _) -> Html.none

    let render (state: State) dispatch =
        match state.CodeVerifyResult with
        | HasNotStartedYet
        | InProgress -> Components.Loader.fullScreenMedium (t "verifying.code")
        | Resolved (Ok Valid) -> renderActivation state dispatch
        | Resolved (Ok Invalid) ->
            fullScreenWithSignIn [
                Bulma.title.h5 (t "invalid.activation.code")
            ]
        | Resolved (Ok AlreadyActivated) ->
            fullScreenWithSignIn [
                Bulma.title.h5 (t "already.activated")
            ]
        | Resolved (Error _) -> Html.none

module Component =
    open Feliz
    open Feliz.UseElmish

    type ActivateAccountProps = {
        Code: string
    }

    let activateAccount = React.functionComponent(fun (props: ActivateAccountProps) ->
        let state, dispatch = React.useElmish(State.init props.Code, State.update, [||])

        Html.div [
            View.render state dispatch
        ]
    )
