namespace Components

open Feliz.Styles

module Video =
    open Fable.Core
    open Fable.React
    open Fable.Core.JsInterop

    type Props =
        /// The url of a video or song to play.
        | Url of string
        /// Set to true or false to display native player controls. Default false.
        | Controls of bool
        /// Set to true or false to pause or play the media. Default false.
        | Playing of bool
        /// Set to true to show just the video thumbnail, which loads the full player on click.
        | Light of bool
        /// Set the width of the player. Default 640px.
        | Width of ICssUnit
        /// Set the height of the player. Default 360px.
        | Height of ICssUnit

    let inline player (props : Props list) : ReactElement =
        ofImport "default" "react-player/lazy" (keyValueList CaseRules.LowerFirst props) []

module TuiImageEditor =
    open Feliz
    open Fable.Core
    open Fable.Core.JsInterop

    importSideEffects "tui-image-editor/dist/tui-image-editor.css"
    importSideEffects "tui-color-picker/dist/tui-color-picker.css"
    type IImageEditorProperty = interface end
    type IUIProperty = interface end
    type IThemeProperty = interface end

    type ITuiImageEditor =
        [<Emit("$0.toDataURL({format: 'jpeg', quality: 1, multiplier: 1})")>]
        abstract toDataUrlAsJpeg: unit -> string
        [<Emit("$0.toDataURL({format: 'png'})")>]
        abstract toDataUrlAsPng: unit -> string

    type ITuiReactElement =
        abstract getInstance: unit -> ITuiImageEditor

    [<RequireQualifiedAccess>]
    module Interop =
        [<Emit("Object.assign({}, $0, $1)")>]
        let inline objectAssign (_: obj) (_: obj) = jsNative

        let inline mkEditorAttr (key: string) (value: obj) = unbox<IImageEditorProperty>(key, value)

    [<Erase>]
    type theme =
        static member inline logoWidth(value: ICssUnit) =
            unbox<IThemeProperty>("common.bisize.width", (unbox<string> value))
        static member inline logoHeight(value: ICssUnit) =
            unbox<IThemeProperty>("common.bisize.height", (unbox<string> value))

        /// <summary>The style of the load button. When you need to specify more than one style,
        /// just place them one next to each other.</summary>
        /// <example>
        /// <code>
        /// theme.loadButton (style.backgroundColor "#FFFFFF")
        /// theme.loadButton (style.fontSize 20)
        /// </code>
        /// </example>
        static member inline loadButton(value: IStyleAttribute) =
            let key, value = !!value
            unbox<IThemeProperty>("loadButton." + key, value)

        /// <summary>The style of the download button. When you need to specify more than one style,
        /// just place them one next to each other.</summary>
        /// <example>
        /// <code>
        /// theme.downloadButton (style.backgroundColor "#FFFFFF")
        /// theme.downloadButton (style.fontSize 20)
        /// </code>
        /// </example>
        static member inline downloadButton(value: IStyleAttribute) =
            let key, value = !!value
            unbox<IThemeProperty>("downloadButton." + key, value)

        /// <summary>
        /// The custom style. This is one of the possible ones, defined in the theme.
        /// See the file "node_modules/tui-image-editor/src/js/ui/theme/standard.js"
        /// </summary>
        /// <param name="key">The key of the theme entry.</param>
        /// <param name="value">The value of the theme entry.</param>
        static member inline custom(key: string, value: string) =
            unbox<IThemeProperty>(key, value)

    [<StringEnum; RequireQualifiedAccess>]
    type Menu = Crop | Flip | Rotate | Draw | Shape | Icon | Text | Mask | Filter

    [<StringEnum; RequireQualifiedAccess>]
    type MenuBarPosition = Top | Bottom | Left | Right

    [<Erase>]
    type ui =
        static member inline loadImage (url: string, name: string) =
            unbox<IUIProperty>("loadImage", createObj [ "path" ==> url; "name" ==> name ])
        static member inline loadImage (url: string) =
            unbox<IUIProperty>("loadImage", createObj [ "path" ==> url; "name" ==> "No name" ])
        static member inline initMenu(menu: Menu) =
            unbox<IUIProperty>("initMenu",  unbox<string> menu)
        static member inline theme(value: IThemeProperty list) =
            unbox<IUIProperty>("theme",  createObj !!value)
        static member inline uiSize(width: int, height: int) =
            unbox<IUIProperty>("uiSize", createObj [ "width" ==> (string width) + "px"; "height" ==> (string height) + "px" ])
        static member inline uiSize(width: ICssUnit, height: ICssUnit) =
            unbox<IUIProperty>("uiSize", createObj [ "width" ==> (unbox<string> width); "height" ==> (unbox<string> height) ])
        static member inline menuBarPosition(position: MenuBarPosition) =
            unbox<IUIProperty>("menuBarPosition",  unbox<string> position)
        static member inline locale(localization: #seq<string*string>) =
            unbox<IUIProperty>("locale", localization |> Seq.map (fun (x, y) -> x, box y) |> createObj)

    [<Erase>]
    type options =
        /// Canvas css-max-width
        static member inline cssMaxWidth(value: int) =
            Interop.mkEditorAttr "cssMaxWidth" value
        /// Canvas css-max-height
        static member inline cssMaxHeight(value: int) =
            Interop.mkEditorAttr "cssMaxHeight" value
        static member inline ref(value: Fable.React.IRefValue<_ option>) =
            Interop.mkEditorAttr "ref" value
        static member inline includeUI(value: IUIProperty list) =
            Interop.mkEditorAttr "includeUI" (createObj !!value)

    [<Erase>]
    type ImageEditor =
        static member inline imageEditor(properties: IImageEditorProperty list) =
            Interop.reactApi.createElement(importDefault "@toast-ui/react-image-editor", createObj !!properties)

    [<RequireQualifiedAccess>]
    module Locale =
        let ``sv-SE`` =
            [ "3:2", "3:2"
              "4:3", "4:3"
              "5:4", "5:4"
              "7:5", "7:5"
              "16:9", "16:9"
              "Apply", "Tillämpa"
              "Arrow", "Pil"
              "Arrow-2", "Pil-2"
              "Arrow-3", "Pil-3"
              "Blend", "Blanda"
              "Blur", "Oskärpa"
              "Bold", "Fet"
              "Brightness", "Ljusstyrka"
              "Bubble", "Bubbla"
              "Cancel", "Annullera"
              "Center", "Centrum"
              "Circle", "Cirkel"
              "Color", "Färg"
              "Color Filter", "Färgfilter"
              "Crop", "Skörda"
              "Custom", "Custom"
              "Custom icon", "Anpassad ikon"
              "Delete", "Ta bort"
              "Delete-all", "Radera allt"
              "Distance", "Distans"
              "Download", "Ladda ner"
              "Draw", "Rita"
              "Emboss", "Relief"
              "Fill", "Fyll"
              "Filter", "Filter"
              "Flip", "Vänd"
              "Flip X", "Vänd X"
              "Flip Y", "Vänd Y"
              "Free", "Fri"
              "Grayscale", "Gråskala"
              "Heart", "Hjärta"
              "Icon", "Ikon"
              "Invert", "Invertera"
              "Italic", "Kursiv"
              "Left", "Vänster"
              "Load", "Ladda"
              "Load Mask Image", "Ladda maskbild"
              "Location", "Plats"
              "Mask", "Mask"
              "Multiply", "Multiplicera"
              "Noise", "Ljud"
              "Pixelate", "Pixelate"
              "Polygon", "Polygon"
              "Range", "Räckvidd"
              "Rectangle", "Rektangel"
              "Redo", "Gör om"
              "Remove White", "Ta bort vit"
              "Reset", "Återställ"
              "Right", "Höger"
              "Rotate", "Rotera"
              "Sepia", "Sepia"
              "Sepia2", "Sepia2"
              "Shape", "Form"
              "Sharpen", "Skärpa"
              "Square", "Fyrkantigt"
              "Star-1", "Stjärna-1"
              "Star-2", "Stjärna-2"
              "Straight", "Hetero"
              "Stroke", "Stroke"
              "Text", "Text"
              "Text size", "Textstorlek"
              "Threshold", "Tröskel"
              "Tint", "Nyans"
              "Triangle", "Triangel"
              "Underline", "Understrykning"
              "Undo", "Ångra"
              "Value", "Värde" ]