namespace User.Edit

module Types =

    let t = Localization.ns("users")

    type UserId = int
    type User = {
        Id: int
        FullName: string
        Title: string
        Email: string
    }

    type State = {
        User: Deferred<Result<User, exn>>
        SaveResult: Deferred<Result<unit, exn>>
    }

    type Msg =
        | FullNameChanged of string
        | TitleChanged of string
        | LoadUser of UserId * AsyncOperationStatus<Result<User, exn>>
        | Save of AsyncOperationStatus<Result<unit, exn>>

module State =
    open Types
    open Elmish

    let init userId =
        { User = HasNotStartedYet; SaveResult = HasNotStartedYet}, Cmd.ofMsg (LoadUser (userId, Started))

    let withUser (user: User) (state: State) =
        { state with User = Resolved (Ok user) }

    let update (msg: Msg) (state: State)  =
        match msg, state.User with
        | FullNameChanged s, Resolved (Ok user) ->
            state |> withUser { user with FullName = s }, Cmd.none
        | TitleChanged s, Resolved (Ok user) ->
            state |> withUser { user with Title = s }, Cmd.none
        | LoadUser (userId, Started), HasNotStartedYet ->
            let load: Async<Msg> = async {
                do! Async.Sleep 1000
                let user = { Id = userId; FullName = "Daniel Sedin"; Title = "Agent"; Email = "daniel.sedin@gmail.com" }
                return LoadUser (userId, Finished (Ok user))
            }
            { state with User = InProgress }, Cmd.fromAsync load
        | LoadUser (userId, Finished (Ok user)), InProgress ->
            { state with User = Resolved (Ok user) }, Cmd.none
        | LoadUser (userId, Finished (Error ex)), InProgress ->
            { state with User = Resolved (Error ex) }, Cmd.none
        | Save (Started), Resolved (Ok user) ->
            let save: Async<Msg> = async {
                do! Async.Sleep 1000
                if user.FullName.StartsWith("E") then
                    return Save (Finished (Error (exn user.FullName)))
                else
                    return Save (Finished (Ok ()))
            }
            { state with SaveResult = InProgress }, Cmd.fromAsync save
        | Save (Finished (Ok ())), Resolved (Ok user) ->
            { state with SaveResult = Resolved (Ok ()) }, Router.navigateTo (Router.Route.UserDetails user.Id)
        | Save (Finished (Error error)), Resolved (Ok user) ->
            { state with SaveResult = Resolved (Error error) }, Cmd.none
        | _, _ ->
            Browser.Dom.console.warn (sprintf "The combination of state and message is not expected", state, msg)
            state, Cmd.none

module View =
    open Types
    open Feliz
    open Feliz.Bulma

    let renderToolbar (state: State) (dispatch: Msg -> unit) =
        let isSaving = Deferred.inProgress state.SaveResult
        Bulma.level [
            prop.children [
                Bulma.levelLeft [
                    Bulma.levelItem [
                        Bulma.title.h3 [
                            Bulma.text.isUppercase
                            prop.text (t "user.edit")
                        ]
                    ]
                ]
                Bulma.levelRight[
                    Bulma.levelItem [
                        Bulma.button.a [
                            color.isPrimary
                            prop.className "button-shadow"
                            if isSaving then Bulma.button.isLoading
                            prop.children [
                                Bulma.text.span (t "user.save")
                            ]
                            prop.onClick (fun x -> x.preventDefault(); dispatch (Save Started))
                        ]
                    ]
                ]
            ]
        ]

    let row (label: string) (input: ReactElement list) =
        Bulma.columns [
            prop.style [
                style.marginBottom (length.rem 0.75)
            ]
            Bulma.columns.isGapless
            Bulma.columns.isVCentered
            prop.children [
                Bulma.column [
                    Bulma.column.is4
                    Bulma.color.hasTextGrey
                    prop.text label
                ]
                Bulma.column [
                    prop.children input
                ]
            ]
        ]

    let renderError (state: State) dispatch =
        match state.SaveResult with
        | Resolved (Error ex) ->
            Bulma.notification [
                color.isDanger
                prop.text (sprintf "Error while save: %s" ex.Message)
            ]
        | _ -> Html.none

    let renderForm (user: User) (state: State) dispatch =
        let inProgress = Deferred.inProgress state.SaveResult
        Bulma.column [
            Bulma.column.is6
            prop.style[style.paddingLeft 0]
            prop.children [
                renderError state dispatch
                Html.fieldSet [
                    prop.children [
                        row (t "user.fullName") [
                            Bulma.input.text [
                                prop.autoFocus true
                                prop.valueOrDefault user.FullName
                                prop.onChange (FullNameChanged >> dispatch)
                                prop.disabled inProgress
                            ]
                        ]
                        row (t "title.title") [
                            Bulma.input.text [
                                prop.valueOrDefault user.Title
                                prop.onChange (TitleChanged >> dispatch)
                                prop.disabled inProgress
                            ]
                        ]
                        row (t "title.email") [
                            Bulma.input.email [
                                prop.valueOrDefault user.Email
                                prop.disabled true
                            ]
                        ]
                    ]
                ]
            ]
        ]

    let render (state: State) dispatch =
        match state.User with
        | HasNotStartedYet
        | InProgress ->
            Bulma.hero [
                Bulma.hero.isFullHeightWithNavbar
                prop.children [
                    Bulma.heroBody [
                        Components.Loader.medium (t "user.loading")
                    ]
                ]
            ]
        | Resolved (Ok user) ->
            Html.div [
                Html.div [
                    prop.classes[AppCss.Toolbar]
                    prop.children[
                        Bulma.column [
                            column.is6
                            prop.style[style.paddingLeft 0]
                            prop.children [ renderToolbar state dispatch ]
                        ]
                    ]
                ]
                renderForm user state dispatch
            ]
        | Resolved (Error ex) ->
            Bulma.notification [
                color.isDanger
                prop.text ex.Message
            ]

module Component =
    open Feliz
    open Feliz.UseElmish

    type AddInspectorProps = {
        UserId: int
    }

    let editUser = React.functionComponent(fun (props: AddInspectorProps) ->
        let state, dispatch = React.useElmish(State.init props.UserId, State.update, [||])

        Html.div [
            View.render state dispatch
        ]
    )
