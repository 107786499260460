module UserDetails

open System
open Shared

let t = Localization.ns("users")

module Types =
    open Shared.PrimitiveTypes
    open Shared.Users.Queries

    type EditPositionState =
        | New
        | Edit of Position
        | Hidden

    type State = {
        User: Deferred<Result<UserDetail, string>>
        EditPosition: EditPositionState
        DeletePosition: Map<PositionId, ConfirmedOperation<Result<unit, exn>>>
        DeleteUserOperation: ConfirmedOperation<Result<unit, exn>>
        DeactivateOperation: Deferred<Result<unit, exn>>
        ActivateOperation: Deferred<Result<unit, exn>>
    }

    type Msg =
        | LoadUser of UserId * AsyncOperationStatus<Result<UserDetail, string>>
        | ShowEditPosition of Position
        | ShowAddNewPosition
        | DeletePosition of Position * AsyncConfirmOperationStatus<Result<unit, exn>>
        | PositionDeleted of PositionId
        | PositionChanged of Position
        | PositionAdded of Position
        | PositionClosed
        | EditUser
        | DeleteUser of AsyncConfirmOperationStatus<Result<unit, exn>>
        | Activate of AsyncOperationStatus<Result<unit, exn>>
        | Deactivate of AsyncOperationStatus<Result<unit, exn>>

    let roleToString = function
        | Agent -> t "role.agent"
        | SuperVisor -> t "role.supervisor"

    let statusToString = function
        | Free -> t "status.free"
        | Busy -> t "status.busy"

module Cmd =
    open Types
    open Elmish
    let loadUser id: Cmd<Msg> =
        async {
            try
                let! userDetails = Communication.usersApi().details id
                return LoadUser (id, Finished (Ok userDetails))
            with ex ->
                return LoadUser (id, Finished (Error ex.Message))
        }
        |> Cmd.fromAsync

module State =
    open PrimitiveTypes
    open Types
    open Elmish

    let withUser user (state:State) =
        { state with User = Resolved (Ok user) }

    let init userId  =
        {
            User = HasNotStartedYet
            EditPosition = Hidden
            DeletePosition = Map.empty
            DeleteUserOperation = NotStarted
            ActivateOperation = HasNotStartedYet
            DeactivateOperation = HasNotStartedYet
        }, Cmd.ofMsg (LoadUser (userId, Started))

    let update (msg: Msg) (state: State) =
        match msg, state.User with
        | LoadUser (id, Started), HasNotStartedYet ->
            { state with User = InProgress}, Cmd.loadUser id
        | LoadUser (id, Finished x), InProgress ->
            { state with User = Resolved x}, Cmd.none
        | ShowEditPosition position, Resolved (Ok user) ->
            { state with EditPosition = Edit position }, Cmd.none
        | ShowAddNewPosition, Resolved (Ok user) ->
            { state with EditPosition = New }, Cmd.none
        | DeletePosition (position, ConfirmationStarted), Resolved (Ok user) ->
            {state with DeletePosition = state.DeletePosition |> Map.add position.Id Requested}, Cmd.none
        | DeletePosition (position, Canceled), Resolved (Ok user) ->
            {state with DeletePosition = state.DeletePosition |> Map.add position.Id NotStarted}, Cmd.none
        | DeletePosition (position, Confirmed), Resolved (Ok user) ->
            let delete : Async<Msg> = async {
                do! Async.Sleep 1000
                return DeletePosition (position, ConfirmationFinished (Ok ()))
            }
            { state with DeletePosition = state.DeletePosition |> Map.add position.Id ConfirmationInProgress }, Cmd.fromAsync delete
        | DeletePosition (position, ConfirmationFinished (Ok ())), Resolved (Ok user) ->
            let newPositions = user.Positions |> List.filter (fun x -> x.Id <> position.Id)
            let newUser = { user with Positions = newPositions }
            { state with
                DeletePosition = state.DeletePosition |> Map.add position.Id (Done (Ok ()))
                User = Resolved (Ok newUser)
            }, Cmd.none
        | DeletePosition (position, ConfirmationFinished (Error ex)), Resolved (Ok user) ->
            { state with DeletePosition = state.DeletePosition |> Map.add position.Id (Done (Error ex)) }, Cmd.none
        | PositionChanged position, Resolved (Ok user) ->
            let newPositions = user.Positions |> List.map (fun x -> if x.Id = position.Id then position else x)
            let newUser = { user with Positions = newPositions }
            { state with User = Resolved (Ok newUser); EditPosition = Hidden }, Cmd.none
        | PositionDeleted positionId, Resolved (Ok user) ->
            let newPositions = user.Positions |> List.filter (fun x -> x.Id <> positionId)
            let newUser = { user with Positions = newPositions }
            { state with User = Resolved (Ok newUser); EditPosition = Hidden }, Cmd.none
        | PositionAdded position, Resolved (Ok user) ->
            let newUser = { user with Positions = user.Positions @ [ position ] }
            { state with User = Resolved (Ok newUser); EditPosition = Hidden }, Cmd.none
        | PositionClosed, Resolved (Ok user) ->
            {state with EditPosition = Hidden}, Cmd.none
        | EditUser, Resolved (Ok user) ->
            state, Router.navigateTo (Router.Route.UserEdit (UserId.value user.Id))
        | DeleteUser ConfirmationStarted, Resolved (Ok user) ->
            {state with DeleteUserOperation = Requested}, Cmd.none
        | DeleteUser Canceled, Resolved (Ok user) ->
            {state with DeleteUserOperation = NotStarted}, Cmd.none
        | DeleteUser Confirmed, Resolved(Ok user) ->
            let delete : Async<Msg> = async {
                do! Async.Sleep 1000
                return DeleteUser (ConfirmationFinished (Ok ()))
            }
            { state with DeleteUserOperation = ConfirmationInProgress }, Cmd.fromAsync delete
        | DeleteUser (ConfirmationFinished (Ok ())), Resolved (Ok user) ->
            { state with DeleteUserOperation = Done (Ok ()) }, Router.navigateTo Router.Route.Users
        | DeleteUser (ConfirmationFinished (Error ex)), Resolved (Ok user) ->
            { state with DeleteUserOperation = Done (Error ex) }, Cmd.none
        | Activate Started, Resolved (Ok user) ->
            let activate : Async<Msg> = async {
                do! Async.Sleep 1000
                return Activate (Finished (Ok ()))
            }
            { state with ActivateOperation = InProgress }, Cmd.fromAsync activate
        | Activate (Finished (Ok ())), Resolved (Ok user) ->
            { state with ActivateOperation = Resolved (Ok ()) } |> withUser { user with IsActive = true }, Cmd.none
        | Activate (Finished (Error ex)), Resolved (Ok user) ->
            { state with ActivateOperation = Resolved (Error ex) }, Cmd.none
        | Deactivate Started, Resolved (Ok user) ->
            let deactivate : Async<Msg> = async {
                do! Async.Sleep 1000
                return Deactivate (Finished (Ok ()))
            }
            { state with DeactivateOperation = InProgress }, Cmd.fromAsync deactivate
        | Deactivate (Finished (Ok ())), Resolved (Ok user) ->
            { state with DeactivateOperation = Resolved (Ok ()) } |> withUser { user with IsActive = false }, Cmd.none
        | Deactivate (Finished (Error ex)), Resolved (Ok user) ->
            { state with DeactivateOperation = Resolved (Error ex) }, Cmd.none
        | _, _ ->
            Browser.Dom.console.warn (sprintf "The combination of state and message is not expected", state, msg)
            state, Cmd.none

module View =
    open Types
    open Feliz
    open Feliz.Bulma
    open Feliz.Bulma.Switch
    open Shared.Users.Queries
    open Shared.PrimitiveTypes

    let renderToolbar (user: UserDetail) (state: State) (dispatch: Msg -> unit) =
        let isBusy = Deferred.inProgress state.ActivateOperation || Deferred.inProgress state.DeactivateOperation
        Bulma.level [
            prop.style[style.marginBottom 0]
            prop.children [
                Bulma.levelLeft [
                    Bulma.levelItem [
                        Bulma.title.h3 [
                            Bulma.text.isUppercase
                            prop.text (sprintf "%s %s" user.FirstName user.LastName)
                        ]
                    ]
                ]
                Bulma.levelRight[
                    Bulma.levelItem [
                        prop.style[style.marginRight 40]
                        prop.children[
                            Bulma.field.div [
                                prop.children[
                                    Bulma.control.div [
                                        prop.children [
                                            if isBusy then
                                                Bulma.icon [
                                                    Bulma.icon.isLeft
                                                    prop.children [
                                                        Html.i [
                                                            prop.classes [MdiCss.Mdi;  MdiCss.MdiLoading; MdiCss.MdiSpin]
                                                        ]
                                                    ]
                                                ]
                                            Switch.checkbox [
                                                switch.isRtl
                                                prop.id "is-active"
                                                color.isSuccess
                                                switch.isRounded
                                                prop.isChecked user.IsActive
                                                prop.onClick (fun x -> x.preventDefault(); dispatch (if user.IsActive then Deactivate Started else Activate Started ))
                                                prop.disabled isBusy
                                            ]
                                            Html.label [
                                                prop.htmlFor "is-active"
                                                prop.text (if user.IsActive then t "checkbox.active" else t "checkbox.inactive")
                                            ]
                                        ]
                                    ]
                                ]
                            ]
                        ]
                    ]
                    Bulma.levelItem [
                        prop.style[style.marginRight 20]
                        prop.children[
                            Bulma.button.a [
                                prop.classes [BulmaCss.``has-text-primary``]
                                prop.children [
                                    Bulma.icon [
                                        Html.i [
                                            prop.classes [ MdiCss.Mdi; MdiCss.MdiPencilOutline; MdiCss.Mdi24Px ]
                                        ]
                                    ]
                                ]
                                prop.onClick (fun x -> x.preventDefault(); dispatch EditUser)
                            ]
                        ]
                    ]
                    Bulma.levelItem [
                        Bulma.button.button [
                            prop.classes [BulmaCss.``has-text-danger``]
                            prop.children [
                                Bulma.icon [
                                    Html.i [
                                        prop.classes [ MdiCss.Mdi; MdiCss.MdiDeleteOutline; MdiCss.Mdi24Px ]
                                    ]
                                ]
                            ]
                            prop.onClick (fun x -> x.preventDefault(); dispatch (DeleteUser ConfirmationStarted))
                            if (state.DeleteUserOperation |> ConfirmedOperation.confirmationInProgress) then Bulma.button.isLoading
                        ]
                    ]
                ]
            ]
        ]

    let renderImage (state: State) dispatch =
        let random = Random()
        Bulma.image [
            Bulma.image.is1by1
            prop.style [
                style.margin.auto
            ]
            prop.children [
                Html.img [
                    prop.style [
                        style.borderRadius (length.px 20)
                    ]
                    prop.src (sprintf "https://picsum.photos/seed/%d/180" (random.Next()))
                ]
            ]
        ]

    let row (label: string) (input: ReactElement list) =
        Bulma.columns [
            prop.style [
                style.marginBottom (length.rem 0.75)
            ]
            Bulma.columns.isGapless
            prop.children [
                Bulma.column [
                    Bulma.column.is2
                    prop.classes [ BulmaCss.``has-text-grey-light`` ]
                    prop.children [
                        Bulma.text.span label
                    ]
                ]
                Bulma.column [
                    prop.children input
                ]
            ]
        ]

    let renderUserInfo (user: UserDetail) dispatch =
        let classesPerStatus = function
            | Free -> [ BulmaCss.``has-text-success``; BulmaCss.``has-text-weight-bold`` ]
            | Busy -> [ BulmaCss.``has-text-danger``; BulmaCss.``has-text-weight-bold`` ]
        Html.div [
            prop.children[
                row (t "title.role") [ Html.b (roleToString user.Role) ]
                row (t "title.title") [ Html.b user.Title ]
                row (t "title.email") [ Html.a [ prop.text user.Email] ]
                // row (t "title.status") [ Bulma.text.span [
                //     prop.classes (user.Status |> classesPerStatus)
                //     prop.text (user.Status |> statusToString)
                //     ]
                // ]
                row (t "title.created") [ Html.b (Components.Common.renderDate user.Created) ]
            ]
        ]

    let renderInfo (user: UserDetail) dispatch =
        Html.div [
            prop.children [
                Bulma.columns [
                    prop.children [
                        Bulma.column [
                            renderUserInfo user dispatch
                        ]
                    ]
                ]
            ]
        ]

    let renderPositions (state: State) (user: UserDetail) dispatch =
        Html.div [
            Bulma.title.h3 [
                prop.classes [ BulmaCss.``is-uppercase``; AppCss.Toolbar]
                prop.style [
                    style.color "#bfbfbf"
                    style.marginTop 68
                ]
                prop.text (t "title.customPositions")
            ]
            Bulma.columns [
                prop.style[
                    style.fontWeight 500
                    style.marginBottom 0
                    style.marginTop -24
                    style.paddingLeft 20
                    style.opacity 0.25
                ]
                prop.children[
                    Bulma.column[
                        prop.style[
                            style.paddingRight 26
                            style.paddingTop 40
                            style.paddingBottom 40
                        ]
                        prop.text (t "title.place")
                    ]
                    Bulma.column[
                        prop.style[
                            style.paddingTop 40
                            style.paddingLeft 0
                            style.paddingBottom 40
                        ]
                        prop.text (t "title.address")
                    ]
                    Bulma.column[
                        prop.style [
                            style.paddingTop 40
                            style.paddingBottom 40
                        ]
                        prop.text ""
                    ]
                ]
            ]
            for position in user.Positions do
                let isDeleting =
                    state.DeletePosition
                    |> Map.tryFind position.Id
                    |> Option.map ConfirmedOperation.confirmationInProgress
                    |> Option.defaultValue false
                Bulma.box[
                    prop.classes [AppCss.BoxStyle]
                    prop.children[
                        Bulma.columns[
                            Bulma.column[
                                prop.text(position.Place)
                            ]
                            Bulma.column[
                                prop.style[
                                    style.opacity 0.5
                                ]
                                prop.text(position.Address)
                            ]
                            Bulma.column[
                                prop.style[style.padding 4]
                                prop.children[
                                    Bulma.buttons [
                                        Bulma.buttons.isRight
                                        prop.children [
                                            Bulma.button.a [
                                                prop.classes [BulmaCss.``has-text-primary``]
                                                prop.style[
                                                    style.backgroundColor.transparent
                                                ]
                                                prop.children [
                                                    Bulma.icon [
                                                        Html.i [
                                                            prop.classes [ MdiCss.Mdi; MdiCss.MdiPencilOutline; MdiCss.Mdi24Px ]
                                                        ]
                                                    ]
                                                ]
                                                prop.onClick (fun x -> x.preventDefault(); dispatch (ShowEditPosition position))
                                                prop.disabled isDeleting
                                            ]
                                            Bulma.button.button [
                                                prop.classes [BulmaCss.``has-text-danger``]
                                                prop.style[
                                                    style.backgroundColor.transparent
                                                ]
                                                prop.children [
                                                    Bulma.icon [
                                                        Html.i [
                                                            prop.classes [ MdiCss.Mdi; MdiCss.MdiDeleteOutline; MdiCss.Mdi24Px ]
                                                        ]
                                                    ]
                                                ]
                                                prop.onClick (fun x -> x.preventDefault(); dispatch (DeletePosition (position, ConfirmationStarted)))
                                                if isDeleting then Bulma.button.isLoading
                                            ]
                                        ]
                                    ]
                                ]
                            ]
                        ]
                    ]
                ]
                if (state.DeletePosition|> Map.tryFind position.Id|> Option.map ConfirmedOperation.confirmationRequested|> Option.defaultValue false) then
                    let confirmedDelPosition() = dispatch (DeletePosition (position, Confirmed))
                    let notConfirmedDelPosition() = dispatch (DeletePosition (position, Canceled))
                    Components.Common.renderDeleteWindow
                        {| message = t "delete.position.confirmation.question"
                           confirmed=confirmedDelPosition
                           notConfirmed=notConfirmedDelPosition |}
            Bulma.button.button [
                color.isPrimary
                prop.className "button-shadow"
                prop.style[style.marginTop 10]
                prop.text (t "button.addPosition")
                prop.onClick (fun x -> x.preventDefault(); dispatch ShowAddNewPosition )
            ]
        ]

    let renderAddOrEditPosition (state: State) (user: UserDetail) dispatch =
        match state.EditPosition with
        | Hidden -> Html.none
        | Edit position ->
            EditUserPosition.Component.editUserPosition
                {
                    Position = { Id = position.Id; Place = position.Place; Address = position.Address }
                    OnSave = fun x -> dispatch (PositionChanged { Id = x.Id; Address = x.Address; Place = x.Place })
                    OnDelete = fun _ -> dispatch (PositionDeleted position.Id)
                    OnClose = fun _ -> dispatch (PositionClosed)
                }
        | New ->
            AddUserPosition.Component.addUserPosition
                {
                    UserId = user.Id
                    OnSave = fun x -> dispatch (PositionAdded x)
                }

    let render (state: State) (dispatch: Msg -> unit) =
        match state.User with
        | HasNotStartedYet
        | InProgress ->
            Bulma.hero [
                Bulma.hero.isFullHeightWithNavbar
                prop.children [
                    Bulma.heroBody [
                        Components.Loader.medium (t "user.loading")
                    ]
                ]
            ]
        | Resolved (Ok user) ->
            Html.div [
                Html.div [
                    prop.classes[AppCss.Toolbar]
                    prop.children[renderToolbar user state dispatch]
                ]
                renderInfo user dispatch
                renderPositions state user dispatch
                renderAddOrEditPosition state user dispatch
                if state.DeleteUserOperation=Requested then
                    let confirmedDelUser() = dispatch (DeleteUser Confirmed)
                    let notConfirmedDelUser() = dispatch (DeleteUser Canceled)
                    Components.Common.renderDeleteWindow
                        {| message = t "delete.user.confirmation.question"
                           confirmed=confirmedDelUser
                           notConfirmed=notConfirmedDelUser |}
            ]
        | Resolved (Error e) ->
            Bulma.section [
                Bulma.container [
                    Bulma.notification [
                        Bulma.color.isDanger
                        prop.text e
                    ]
                ]
            ]

module Component =
    open Feliz
    open Feliz.ElmishComponents
    open State
    open View
    open Shared.PrimitiveTypes

    type UserDetailsProps = {
        userId: UserId
    }

    let userDetails (props: UserDetailsProps) =
        React.elmishComponent("UserDetails", init props.userId, update, render)