﻿module Sms

open Common
open System
open Shared
open Shared.Errors
open Shared.SendingSms
open Communication
open SignIn

module SmsWindow =
    let t = Localization.ns ("sendingSms")

    open Elmish

    module Types =

        type State ={
            IsVisible: bool
            Phone: string
            SmsInfo: Deferred<Result<SmsResponseInfo, Errors.ServerError>>
            FormValidation: bool
            FormErrors: Map<string, string list>
        }

        type Msg =
            | Close
            | PhoneChanged of string
            | SendSms of string*AsyncOperationStatus<Result<SmsResponseInfo, Errors.ServerError>>
    module Cmd =
        open Types

        let sendSms phone =
            async {
                try
                    match! sendingSmsApi().sendSms phone with
                    | Ok result ->
                        return SendSms (phone, Finished (Ok result))
                    | Error er -> return SendSms (phone, Finished (Error er))
                with ex ->
                    return SendSms (phone,Finished (Error (Errors.Exception ex.Message)))
            }
    module State =
        open Types
        let init () = {IsVisible=true; Phone = ""; FormValidation = false; FormErrors = Map.empty; SmsInfo = HasNotStartedYet }, Cmd.none

        let update (hiddenSms: unit-> unit)(msg: Msg)(state: State) =
            match msg with
            | Close ->
                hiddenSms()
                {state with IsVisible = false}, Cmd.none
            | PhoneChanged x ->
                let x = sprintf "+46%s" x
                let errors = Form.validationErrors x state.FormValidation
                { state with Phone = x; FormErrors = errors.Value }, Cmd.none
            | SendSms (phone, Started) ->
                let newState = {state with FormValidation = true}
                let errors = Form.validationErrors state.Phone newState.FormValidation
                if errors.Value=Map.empty then
                    { state with SmsInfo = InProgress; FormErrors = errors.Value; FormValidation = true }, Cmd.fromAsync (Cmd.sendSms phone)
                else
                    { state with SmsInfo = HasNotStartedYet; FormErrors = errors.Value ; FormValidation = true}, Cmd.none
            | SendSms (phone, Finished (Ok result)) -> {state with SmsInfo = Resolved(Ok result)}, Cmd.none
            | SendSms (phone, Finished (Error er)) -> {state with SmsInfo = Resolved(Error er)}, Cmd.none

    module View  =
        open Feliz
        open Feliz.ElmishComponents
        open Feliz.Bulma
        open State
        open Types

        let renderSmsForm state dispatch =
            React.fragment [
                match state.SmsInfo with
                | Resolved (Ok smsInfo) ->
                    Html.p [
                        text.hasTextWeightBold
                        prop.style [style.marginBottom 10]
                        prop.text (t "successfull")
                    ]
                    Bulma.field.div [
                        Bulma.control.div [
                            prop.style [
                                style.textAlign.center
                                style.position.absolute
                                style.bottom 20
                                style.left 121
                            ]
                            prop.children [
                                Bulma.button.button [
                                    color.isPrimary
                                    prop.text "OK"
                                    prop.onClick (fun x -> x.preventDefault(); dispatch Close)
                                ]
                            ]
                        ]
                    ]
                | _ ->
                    Html.fieldSet [
                        prop.disabled (state.SmsInfo = InProgress)
                        prop.children [
                            Bulma.field.div [
                                field.hasAddons
                                prop.children [
                                    Bulma.control.p [
                                        prop.children [
                                            Bulma.button.span [
                                                button.isStatic
                                                prop.children [
                                                    Bulma.icon [
                                                        prop.className [MdiCss.Mdi; MdiCss.Mdi18Px; MdiCss.MdiPhone;]
                                                    ]
                                                    Bulma.text.span "+46"
                                                ]
                                            ]

                                        ]
                                    ]
                                    Bulma.control.p [
                                        prop.children [
                                            let errors =
                                                match state.SmsInfo with
                                                | Resolved (Error er) ->
                                                    [sprintf "%s%s" (t "error") (ServerError.explain er)]
                                                | _ ->
                                                    Form.getFieldError state.FormErrors "Phone"
                                            Html.input [
                                                prop.className [BulmaCss.input]
                                                if errors<>[] then
                                                    prop.style [
                                                        style.borderColor.red
                                                        style.boxShadow.none
                                                    ]
                                                prop.type'.text
                                                prop.placeholder (t "phone.number")
                                                prop.onChange (fun x -> dispatch (PhoneChanged x))
                                            ]
                                            Bulma.help [
                                                prop.style [style.color.red]
                                                prop.text (errors |> List.fold (fun s x -> sprintf "%s %s" s x) String.Empty)
                                            ]

                                        ]
                                    ]
                                ]

                            ]
                            Bulma.field.div [
                                Bulma.control.div [
                                    prop.style [
                                        style.textAlign.center
                                        style.position.absolute
                                        style.bottom 20
                                        style.left 115
                                    ]
                                    prop.children [
                                        Bulma.button.button [
                                            color.isPrimary
                                            prop.text (t "button.send")
                                            prop.onClick (fun x -> x.preventDefault(); dispatch (SendSms (state.Phone, Started)))
                                        ]
                                    ]
                                ]
                            ]
                        ]
                    ]
            ]

        let render (state: State) dispatch =
            if state.IsVisible then
                Bulma.card[
                    prop.classes [AppCss.ButtonShadow]
                    prop.style[
                        style.right 120
                        style.bottom 160
                        style.position.relative
                        style.zIndex 35
                        style.borderRadius 10
                        style.display.flex
                    ]
                    prop.children[
                        Bulma.cardContent[
                            prop.style [
                                style.position.relative
                                style.margin.auto
                                style.width 300
                                style.height 230
                            ]
                            prop.children[
                                renderSmsForm state dispatch
                            ]
                        ]

                    ]
                ]
            else
                Html.none

        type HiddenSmsFormProps={
            MakeHiddenSmsForm: unit -> unit
        }

        let showSmsForm (props: HiddenSmsFormProps)=
            React.elmishComponent("ShowSmsForm", init(), update props.MakeHiddenSmsForm, render)

module SmsButton =
    open Elmish

    type State = Hidden | Shown
    type Msg = Show | Hide

    module State =
        let init () =
            Hidden, Cmd.none

        let update (msg: Msg) (state: State): State * Cmd<Msg> =
            match msg with
            | Show -> Shown, Cmd.none
            | Hide -> Hidden, Cmd.none

    module View =
        open Feliz
        open Feliz.ElmishComponents
        open Feliz.Bulma
        open State

        let renderSmsButton (state:State) (dispatch: Msg -> unit) =
            Bulma.button.a[
                prop.className "button-shadow"
                prop.style [style.zIndex 35]
                color.isBlack
                prop.children[
                    Bulma.icon[
                        Html.i[
                            if state = Shown then
                                prop.classes[MdiCss.Mdi; MdiCss.Mdi24Px; MdiCss.MdiClose]
                            else
                                prop.classes[MdiCss.Mdi; MdiCss.Mdi18Px; MdiCss.MdiMessageTextOutline]
                        ]
                    ]
                ]
                if state = Shown then prop.onClick (fun x-> x.preventDefault(); dispatch Hide)
                else prop.onClick (fun x-> x.preventDefault(); dispatch Show)
            ]

        let renderSmsWindow (state: State) (dispatch: Msg -> unit) =
            match state with
            | Hidden -> Html.none
            | Shown ->
                Html.div [
                    prop.style[style.position.inheritFromParent]
                    prop.children [
                        SmsWindow.View.showSmsForm {
                            MakeHiddenSmsForm= fun _ -> dispatch Hide
                        }
                    ]
                ]

        let render (state: State) (dispatch: Msg -> unit)=
            React.fragment [
                renderSmsButton state dispatch
                renderSmsWindow state dispatch
            ]

        let showSmsButton() =
            React.elmishComponent("ShowSmsButton", init(), update, render)